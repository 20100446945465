import React from 'react';

import {
  RouteComponentProps,
  Switch,
} from 'react-router';

import { CanRoute } from '../components';

import {
  UnitesOpListPage,
  UnitesOpSinglePage,
} from '../pages';

export default function ({match}: RouteComponentProps) {
  return (
    <Switch>
      <CanRoute
        I="view"
        an="OU"
        exact
        path={match.url}
        component={UnitesOpListPage}
      />

      <CanRoute
        I="view"
        an="OU"
        exact
        path={`${match.url}/:uniteOpId`}
        render={props => {
          // contains uniteOpId param as an integer
          if (
            'uniteOpId' in props.match.params
            && (new RegExp(/^\d+$/)).test(props.match.params.uniteOpId)
          ) {
            return <UnitesOpSinglePage {...props} />
          }

          return "Not Found.";
        }}
      />
    </Switch>
  );
}
