import React, { useEffect } from 'react';

import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  Form,
  FormikProps,
  withFormik,
  getIn,
} from 'formik';

import {
  createStyles,
  List as MuiList,
  ListItem,
  ListItemText,
  MenuItem,
  withStyles,
  Button,
  CircularProgress,
} from '@material-ui/core';

import {
  Applications,
  UserWithGroups,
} from '../../api';

import { FormikSelect, Can } from '..';
import { RootState, UsersAddGroupsState, UserSingle } from '../../store/reducers';
import { Action, USERS_ADD_GROUPS_INIT_ACTION } from '../../store/actions';
import { WithPropsFromState } from '../../shared/types';
import { useSnackbar } from 'notistack';

const groupReducer = [{id: -1, name: 'Aucun accès'}] as Array<{id: number, name: string}>;

const List = withStyles(({palette, shape}) => createStyles({
  root: {
    '& > *': {
      border: `1px solid ${palette.divider}`,
    },
    '& > * + *': {
      borderTop: 'none',
    },
    '& > *:first-child': {
      borderTopLeftRadius: shape.borderRadius,
      borderTopRightRadius: shape.borderRadius,
    },
    '& > *:last-child': {
      borderBottomLeftRadius: shape.borderRadius,
      borderBottomRightRadius: shape.borderRadius,
    },
  },
}), {name: 'List'})(MuiList);

export type ApplicationsFormValues = {[index: string]: number}

export interface ApplicationsFormBaseProps {
  addState     : WithPropsFromState<UsersAddGroupsState>;
  applications : Applications;
  user         : UserSingle;
  setUserGroups: typeof USERS_ADD_GROUPS_INIT_ACTION;
}

export type ApplicationsFormProps =
  & ApplicationsFormBaseProps
  & FormikProps<ApplicationsFormValues>;

export function PureApplicationsForm({
  applications,
  addState,
  dirty,
  isSubmitting,
  values,
}: ApplicationsFormProps) {
  const {error} = addState;
  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    if (error) {
      enqueueSnackbar('Impossible de modifier les accès de cet utilisateur', {
        key             : 'USERS_ADD_GROUPS_FAILURE',
        persist         : false,
        preventDuplicate: true,
        variant         : 'error',
      });
    }
  }, [error]);

  return (
    <Form noValidate>
      <List>
        {applications.map(({application_group, description, name}, i) => {
          const groups = application_group
            .map(({group}) => ({...group}))
            .reduce((pv, cv) => [...pv, cv], [...groupReducer]);

          const textValue = groups.find(({id}) => id === getIn(values, i.toString()));

          return (
            <ListItem key={i}>
              <ListItemText
                primary={name}
                secondary={description}
              />
              <Can
                I="change"
                a="User"
                passThrough={true}
              >
                {(can: boolean) => can ? (
                  <FormikSelect
                    defaultValue={-1}
                    items={() => groups.map(({id, name}) => (
                      <MenuItem key={id} value={id}>{name}</MenuItem>
                    ))}
                    name={i.toString()}
                  />
                ) : (
                  <>
                    {!!textValue ? textValue.name : "Aucun accès"}
                  </>
                )}
              </Can>
            </ListItem>
          );
        })}
      </List>

      <Can I="change" a="User">
        <div className="d-flex align-items-center mt-3">
          <Button
            color="primary"
            disabled={isSubmitting || !dirty}
            type="submit"
            variant="contained"
          >Enregistrer</Button>

          {isSubmitting && (<CircularProgress className="ml-2" size={24} />)}
        </div>
      </Can>
    </Form>
  );
}

export const ApplicationsForm = withFormik<ApplicationsFormBaseProps, ApplicationsFormValues>({
  mapPropsToValues: ({user, applications}) => {
    // groupes de l'utilisateur
    const {groups} = user;
    // groupes de chaque application
    const applicationsIds = applications
      .map(({application_group: groups}) => groups.map(({group}) => group.id));

    // groupes dans lequel est l'utilisateur pour une application
    const values = applicationsIds
      .map((ids, i) => ({
        [i]: ids
          .filter(id => groups.includes(id))
          .shift() || -1,
      })).reduce((pv, cv) => ({...pv, ...cv}), {});

    return values;
  },
  handleSubmit: (values, {props, ...actions}) => {
    const {user, setUserGroups} = props;

    const payload = Object.values(values)
      .filter(value => value !== -1);

    setUserGroups(payload, {actions, values, user});
  },
})(PureApplicationsForm);

const mapStateToProps = (state: RootState) => ({
  addState: {...state.users.addGroups},
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
  setUserGroups: USERS_ADD_GROUPS_INIT_ACTION,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationsForm);
