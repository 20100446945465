import { Epic, ofType } from 'redux-observable';

import { ReplaySubject, of } from 'rxjs';
import { catchError, concatMap, switchMap, tap } from 'rxjs/operators';

import Api from '../../configureApi';

import {
  Action,
  ServicesEditInitActionType,
  SERVICES_EDIT_SUCCESS_ACTION,
  SERVICES_EDIT_RESET_ACTION,
  SERVICES_EDIT_FAILURE_ACTION,
  UNITES_OP_LIST_IAT_RESET_ACTION,
  UNITES_OP_SINGLE_SUCCESS_ACTION,
  UNITES_OP_SINGLE_FAILURE_ACTION,
} from '../actions';

import { SERVICES_EDIT_INIT } from '../constants';
import { catchResponse } from '../../shared/helpers';

export const servicesEditEpic: Epic<ServicesEditInitActionType, any> = action$ => action$
  .pipe(
    ofType(SERVICES_EDIT_INIT),
    switchMap(({meta, payload, type}: ServicesEditInitActionType) => {
      const stack$      = new ReplaySubject<Action>();

      const {actions, previous} = meta;
      const {id: serviceId}     = previous;

      // name is the same, no change
      if ('name' in payload && payload.name === meta.previous.name) {
        return of(
          SERVICES_EDIT_SUCCESS_ACTION(meta.previous, {diff: false}),
          SERVICES_EDIT_RESET_ACTION(),
        );
      }

      return Api.Services.updateService(serviceId, payload).pipe(
        catchError((response: Response) => catchResponse(response, {actions, type})),
        tap(item => {
          stack$.next(SERVICES_EDIT_SUCCESS_ACTION(item, {diff: true}));
          stack$.next(UNITES_OP_LIST_IAT_RESET_ACTION());
          stack$.next(SERVICES_EDIT_RESET_ACTION());
        }, () => {
          stack$.next(SERVICES_EDIT_FAILURE_ACTION());
          stack$.next(SERVICES_EDIT_RESET_ACTION());
          stack$.complete();
        }),
        concatMap(() => Api.Services.getUniteOp(meta.previous.operational_unit).pipe(
          tap(item => {
            stack$.next(UNITES_OP_SINGLE_SUCCESS_ACTION(item));
            stack$.complete();
          }, () => {
            stack$.next(UNITES_OP_SINGLE_FAILURE_ACTION());
            stack$.complete();
          }),
        )),
        concatMap(() => stack$),
        catchError(() => {
          const {setSubmitting} = actions;
          setSubmitting(false);
          return stack$;
        }),
      );
    }),
  );

export default [
  servicesEditEpic,
];
