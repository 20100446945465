import {
  ActionType,
  createStandardAction,
} from 'typesafe-actions';

import { FormikActions } from 'formik';

import {
  UNITES_OP_EDIT_INIT,
  UNITES_OP_EDIT_SUCCESS,
  UNITES_OP_EDIT_FAILURE,
  UNITES_OP_EDIT_RESET,
} from '../constants';

import {
  UniteOpDetails,
  UniteOpUpdateRequest,
  UniteOpUpdateResponse,
} from '../../api/services';

import { GenericNameFieldFormValues } from '../../components/Form/GenericNameFieldForm';

export const UNITES_OP_EDIT_INIT_ACTION = createStandardAction(UNITES_OP_EDIT_INIT)<UniteOpUpdateRequest, {
  actions : FormikActions<GenericNameFieldFormValues>,
  previous: UniteOpDetails,
}>();
export type UnitesOpEditInitActionType = ActionType<typeof UNITES_OP_EDIT_INIT_ACTION>;

export const UNITES_OP_EDIT_SUCCESS_ACTION = createStandardAction(UNITES_OP_EDIT_SUCCESS)<UniteOpUpdateResponse, {
  diff: boolean;
}>();
export type UnitesOpEditSuccessActionType  = ActionType<typeof UNITES_OP_EDIT_SUCCESS_ACTION>;

export const UNITES_OP_EDIT_FAILURE_ACTION = createStandardAction(UNITES_OP_EDIT_FAILURE)();
export type UnitesOpEditFailureActionType  = ActionType<typeof UNITES_OP_EDIT_FAILURE_ACTION>;

export const UNITES_OP_EDIT_RESET_ACTION = createStandardAction(UNITES_OP_EDIT_RESET)();
export type UnitesOpEditResetActionType  = ActionType<typeof UNITES_OP_EDIT_RESET_ACTION>;

export type UnitesOpEditAction =
  | UnitesOpEditInitActionType
  | UnitesOpEditSuccessActionType
  | UnitesOpEditFailureActionType
  | UnitesOpEditResetActionType;

export default {
  UNITES_OP_EDIT_INIT_ACTION,
  UNITES_OP_EDIT_SUCCESS_ACTION,
  UNITES_OP_EDIT_FAILURE_ACTION,
  UNITES_OP_EDIT_RESET_ACTION,
};
