import { Epic, ofType } from 'redux-observable';

import { ReplaySubject } from 'rxjs';
import { catchError, concatMap, switchMap, tap } from 'rxjs/operators';

import Api from '../../configureApi';

import {
  Action,
  UnitesOpAddInitActionType,
  UNITES_OP_ADD_SUCCESS_ACTION,
  UNITES_OP_ADD_RESET_ACTION,
  UNITES_OP_LIST_SUCCESS_ACTION,
  UNITES_OP_ADD_FAILURE_ACTION,
  UNITES_OP_LIST_IAT_ACTION,
  UNITES_OP_LIST_FAILURE_ACTION,
  UNITES_OP_LIST_IAT_RESET_ACTION,
} from '../actions';

import { UNITES_OP_ADD_INIT } from '../constants';
import { catchResponse } from '../../shared/helpers';

export const unitesOpAddEpic: Epic<UnitesOpAddInitActionType, any> = action$ => action$
  .pipe(
    ofType(UNITES_OP_ADD_INIT),
    switchMap(({meta, payload, type}) => {
      const stack$    = new ReplaySubject<Action>();
      const {actions} = meta;

      return Api.Services.createUniteOp(payload).pipe(
        catchError((response: Response) => catchResponse(response, {actions, type})),
        tap(item => {
          stack$.next(UNITES_OP_ADD_SUCCESS_ACTION(item));
          stack$.next(UNITES_OP_ADD_RESET_ACTION());
        }, () => {
          stack$.next(UNITES_OP_ADD_FAILURE_ACTION());
          stack$.next(UNITES_OP_ADD_RESET_ACTION());
          stack$.complete();
        }),
        concatMap(() => Api.Services.getUnitesOp().pipe(
          tap(items => {
            stack$.next(UNITES_OP_LIST_SUCCESS_ACTION(items));
            stack$.next(UNITES_OP_LIST_IAT_ACTION());
            stack$.complete();
          }, () => {
            stack$.next(UNITES_OP_LIST_FAILURE_ACTION());
            stack$.next(UNITES_OP_LIST_IAT_RESET_ACTION());
            stack$.complete();
          }),
        )),
        concatMap(() => stack$),
        catchError(() => {
          const {setSubmitting} = actions;
          setSubmitting(false);
          return stack$;
        }),
      );
    }),
  );

export default [
  unitesOpAddEpic,
];
