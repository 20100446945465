import {
  ActionType,
  createStandardAction,
} from 'typesafe-actions';

import { FormikActions } from 'formik';

import {
  ROLES_ADD_INIT,
  ROLES_ADD_SUCCESS,
  ROLES_ADD_FAILURE,
  ROLES_ADD_RESET,
} from '../constants';

import {
  RolesCreateRequest,
  RolesCreateResponse,
} from '../../api/services';

import { RoleFormValues } from '../../components/Form/RoleForm';

export const ROLES_ADD_INIT_ACTION = createStandardAction(ROLES_ADD_INIT)<RolesCreateRequest, {
  actions: FormikActions<RoleFormValues>,
  values : RoleFormValues,
}>();
export type RolesAddInitActionType = ActionType<typeof ROLES_ADD_INIT_ACTION>;

export const ROLES_ADD_SUCCESS_ACTION = createStandardAction(ROLES_ADD_SUCCESS)<RolesCreateResponse>();
export type RolesAddSuccessActionType  = ActionType<typeof ROLES_ADD_SUCCESS_ACTION>;

export const ROLES_ADD_FAILURE_ACTION = createStandardAction(ROLES_ADD_FAILURE)();
export type RolesAddFailureActionType  = ActionType<typeof ROLES_ADD_FAILURE_ACTION>;

export const ROLES_ADD_RESET_ACTION = createStandardAction(ROLES_ADD_RESET)();
export type RolesAddResetActionType  = ActionType<typeof ROLES_ADD_RESET_ACTION>;

export type RolesAddAction =
  | RolesAddInitActionType
  | RolesAddSuccessActionType
  | RolesAddFailureActionType
  | RolesAddResetActionType;

export default {
  ROLES_ADD_INIT_ACTION,
  ROLES_ADD_SUCCESS_ACTION,
  ROLES_ADD_FAILURE_ACTION,
  ROLES_ADD_RESET_ACTION,
};
