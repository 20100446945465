import React from 'react';
import classNames from 'classnames';

import {
  createStyles,
  Paper,
  WithStyles,
  withStyles,
} from '@material-ui/core';

import { PaperProps } from '@material-ui/core/Paper';

const styles = createStyles({
  root: {
    position: 'relative',
  },
});

export function FullWidthContainer({
  classes,
  className,
  ...other
}: PaperProps & WithStyles<typeof styles>) {
  return <Paper
    className={classNames(classes.root, className, 'p-4')}
    {...other}
  />
}

export default withStyles(styles, {name: 'FullWidthContainer'})(FullWidthContainer);
