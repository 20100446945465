import { useEffect, useRef } from 'react';

/**
 * Store the previous value for a variable.
 *
 * @link https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 * @link https://usehooks.com/usePrevious/
 *
 * @param {T} value The value to store as previous value
 * @returns {undefined | T}
 */
export function usePrevious<T>(value: T): undefined | T {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T>();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}
