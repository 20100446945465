import {
  ActionType,
  createStandardAction,
} from 'typesafe-actions';

import {
  USERS_SINGLE_INIT,
  USERS_SINGLE_SUCCESS,
  USERS_SINGLE_FAILURE,
  USERS_SINGLE_RESET,
} from '../constants';

import { UserSingle } from '../reducers';

export const USERS_SINGLE_INIT_ACTION = createStandardAction(USERS_SINGLE_INIT)<number>();
export type UsersSingleInitActionType = ActionType<typeof USERS_SINGLE_INIT_ACTION>;

export const USERS_SINGLE_SUCCESS_ACTION = createStandardAction(USERS_SINGLE_SUCCESS)<UserSingle>();
export type UsersSingleSuccessActionType = ActionType<typeof USERS_SINGLE_SUCCESS_ACTION>;

export const USERS_SINGLE_FAILURE_ACTION = createStandardAction(USERS_SINGLE_FAILURE)();
export type UsersSingleFailureActionType = ActionType<typeof USERS_SINGLE_FAILURE_ACTION>;

export const USERS_SINGLE_RESET_ACTION = createStandardAction(USERS_SINGLE_RESET)();
export type UsersSingleResetActionType = ActionType<typeof USERS_SINGLE_RESET_ACTION>;

export type UsersSingleAction =
  | UsersSingleInitActionType
  | UsersSingleSuccessActionType
  | UsersSingleFailureActionType
  | UsersSingleResetActionType;

export default {
  USERS_SINGLE_INIT_ACTION,
  USERS_SINGLE_SUCCESS_ACTION,
  USERS_SINGLE_FAILURE_ACTION,
  USERS_SINGLE_RESET_ACTION,
};
