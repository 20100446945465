import { Epic, ofType } from 'redux-observable';

import { of } from 'rxjs';
import { concatMap, switchMap, catchError, withLatestFrom } from 'rxjs/operators';

import Api from '../../configureApi';

import {
  UsersListAction,
  USERS_LIST_SUCCESS_ACTION,
  USERS_LIST_FAILURE_ACTION,
  USERS_LIST_IAT_ACTION,
} from '../actions/users-list-action';

import { USERS_LIST_INIT } from '../constants';
import { RootState } from '../reducers';

export const usersListEpic: Epic<UsersListAction, UsersListAction, RootState> = (action$, state$) => action$
  .pipe(
    ofType(USERS_LIST_INIT),
    withLatestFrom(state$),
    switchMap(([, state]) => {
      const {iat, items} = state.users.list;

      if (iat !== 0) {
        return of(USERS_LIST_SUCCESS_ACTION(items));
      }

      return Api.Services.getUsers()
        .pipe(
          concatMap(items => of(
            USERS_LIST_SUCCESS_ACTION(items),
            USERS_LIST_IAT_ACTION(),
          )),
          catchError(() => of(
            USERS_LIST_FAILURE_ACTION(),
          ))
        );
    }),
  );

export default [
  usersListEpic,
];
