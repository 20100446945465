import { combineReducers } from 'redux';

import applications, { ApplicationsState, initialApplicationsState } from './applications-reducer';
import roles, { RolesState, initialRolesState } from './roles-reducer';
import services, { ServicesState, initialServicesState } from './services-reducer';
import unites_op, { UnitesOpState, initialUnitesOpState } from './unites_op-reducer';
import user, { UserState, initialUserState } from './user-reducer';
import users, { UsersState, initialUsersState } from './users-reducer';

export * from './applications-reducer';

export * from './roles-add-reducer';
export * from './roles-edit-reducer';
export * from './roles-delete-reducer';

export * from './services-add-reducer';
export * from './services-delete-reducer';
export * from './services-edit-reducer';

export * from './unites_op-add-reducer';
export * from './unites_op-delete-reducer';
export * from './unites_op-edit-reducer';
export * from './unites_op-list-reducer';
export * from './unites_op-list_services-reducer';
export * from './unites_op-single-reducer';

export * from './user-reducer';

export * from './users-add-reducer';
export * from './users-add_groups-reducer';
export * from './users-delete-reducer';
export * from './users-edit-reducer';
export * from './users-edit_validity-reducer';
export * from './users-edit_active-reducer';
export * from './users-list-reducer';
export * from './users-mail-reducer';
export * from './users-single-reducer';

export interface RootState {
  applications: ApplicationsState;
  roles       : RolesState;
  services    : ServicesState;
  unites_op   : UnitesOpState;
  user        : UserState;
  users       : UsersState;
}

export const initialRootState = (): RootState => ({
  applications: {...initialApplicationsState},
  roles       : {...initialRolesState},
  services    : {...initialServicesState},
  unites_op   : {...initialUnitesOpState},
  user        : initialUserState,
  users       : initialUsersState,
});

export default combineReducers<RootState>({
  applications,
  roles,
  services,
  unites_op,
  user,
  users,
});
