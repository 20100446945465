import { UserWithGroups } from '../../api/services';
import { UsersAddGroupsAction } from '../actions';

import {
  USERS_ADD_GROUPS_INIT,
  USERS_ADD_GROUPS_SUCCESS,
  USERS_ADD_GROUPS_FAILURE,
  USERS_ADD_GROUPS_RESET,
} from '../constants';

export const getUsersAddGroupsStateToProps = <T extends UsersAddGroupsState>({
  error,
  item,
  loading,
}: Pick<T, keyof UsersAddGroupsState>) => ({error, item, loading});

export interface UsersAddGroupsState {
  error  : boolean;
  item   : null | UserWithGroups;
  loading: boolean;
}

export const initialUsersAddGroupsState: UsersAddGroupsState = {
  error  : false,
  item   : null,
  loading: false,
};

export default function (state: UsersAddGroupsState = initialUsersAddGroupsState, action: UsersAddGroupsAction): UsersAddGroupsState {
  switch (action.type) {
    case USERS_ADD_GROUPS_INIT:
      return {
        ...initialUsersAddGroupsState,
        loading: true,
      };
    case USERS_ADD_GROUPS_SUCCESS:
      return {
        error  : false,
        item   : {...action.payload},
        loading: false,
      };
    case USERS_ADD_GROUPS_FAILURE:
      return {
        ...initialUsersAddGroupsState,
        error: true,
      };
    case USERS_ADD_GROUPS_RESET:
      return {...initialUsersAddGroupsState};
    default:
      return state;
  }
}
