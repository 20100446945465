import {
  ActionType,
  createStandardAction,
} from "typesafe-actions";

import {
  USER_FETCH_INIT,
  USER_FETCH_SUCCESS,
  USER_FETCH_FAILURE,
} from '../constants';

export const USER_FETCH_INIT_ACTION = createStandardAction(USER_FETCH_INIT)();
export type UserFetchInitActionType = ActionType<typeof USER_FETCH_INIT_ACTION>;

export const USER_FETCH_SUCCESS_ACTION = createStandardAction(USER_FETCH_SUCCESS)();
export type UserFetchSuccessActionType = ActionType<typeof USER_FETCH_SUCCESS_ACTION>;

export const USER_FETCH_FAILURE_ACTION = createStandardAction(USER_FETCH_FAILURE)();
export type UserFetchFailureActionType = ActionType<typeof USER_FETCH_FAILURE_ACTION>;

export type UserFetchAction =
  | UserFetchInitActionType
  | UserFetchSuccessActionType
  | UserFetchFailureActionType;

export type UserAction =
  | UserFetchAction;

export default {
  USER_FETCH_INIT_ACTION,
  USER_FETCH_SUCCESS_ACTION,
  USER_FETCH_FAILURE_ACTION,
};
