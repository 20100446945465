import {
  bindActionCreators,
  Dispatch,
} from 'redux';

import { connect } from 'react-redux';

import { withFormik } from 'formik';

import {
  endOfDay,
  startOfDay,
} from 'date-fns';

import RoleForm from './RoleForm';

import {
  RoleFormValidate,
  RoleFormValues,
} from './RoleForm';

import {
  Action,
  ROLES_EDIT_INIT_ACTION,
} from '../../store/actions';

import {
  RootState,
} from '../../store/reducers';
import { RolesUpdateRequest } from '../../api';

// TODO: find the correct typing for formik, az,enjkabzeh vazgjevauzevk
type TODO_FORMIK_TYPING_DE_SES_MORTS = any;

export const EditRoleForm = withFormik<TODO_FORMIK_TYPING_DE_SES_MORTS, RoleFormValues>({
  validate: RoleFormValidate,
  mapPropsToValues: ({role}) => {
    const {period: {lower, upper}, service} = role;
    const {id: service_id}      = service;

    const no_end = (upper instanceof Date) === false;

    let values = {
      no_end,
      lower,
      service_id,
    } as RoleFormValues;

    if (!no_end) {
      values = {...values, upper};
    }

    return values;
  },
  handleSubmit: ({lower, upper, no_end, ...values}, {props, ...actions}) => {
    const {
      role: previous,
      updateRole,
    } = props;

    let payload = {
      ...values,
      period: {
        lower: startOfDay(lower).toISOString(),
        upper  : !no_end && upper instanceof Date ? endOfDay(upper).toISOString() : null,
      },
    } as RolesUpdateRequest;

    updateRole(payload, {actions, previous});
  },
})(RoleForm);

const mapStateToProps = (state: RootState) => ({
  unitesOp: {...state.unites_op.listServices},
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
  updateRole: ROLES_EDIT_INIT_ACTION,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditRoleForm);
