import { Epic, ofType } from 'redux-observable';

import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import {
  UserFetchAction,
  USER_FETCH_SUCCESS_ACTION,
} from '../actions/user-action';

import { USER_FETCH_INIT } from '../constants';

export const userFetchEpic: Epic<UserFetchAction, UserFetchAction> = action$ => action$
  .pipe(
    ofType(USER_FETCH_INIT),
    switchMap(() => of(USER_FETCH_SUCCESS_ACTION())),
  );

export default [
  userFetchEpic,
];
