import React from 'react';

import * as Yup from 'yup';

import {
  Form,
  FormikProps,
} from 'formik';

import {
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import { FormikTextField, SimpleDialogActions, FormikStatus } from '..';
import { SimpleDialogActionsProps } from '../SimpleDialogActions';

export interface GenericNameFieldFormValues {
  name: string;
}

export const GenericNameFieldFormSchema = Yup.object().shape({
  name: Yup.string()
    .label('Nom')
    .min(2)
    .max(64)
    .required()
    .trim(),
});

export interface GenericNameFieldFormBaseProps {
  title: string;
}

export type GenericNameFieldFormProps =
  & GenericNameFieldFormBaseProps
  & FormikProps<GenericNameFieldFormValues>
  & SimpleDialogActionsProps;

export function GenericNameFieldForm({
  CancelButtonProps,
  isSubmitting,
  title,
}: GenericNameFieldFormProps) {
  return (
    <>
      <Form noValidate>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent className="pt-3">
          <FormikTextField
            autoComplete="off"
            autoFocus
            disabled={isSubmitting}
            fullWidth
            label="Nom"
            name="name"
            required
            type="text"
            variant="standard"
          />
        </DialogContent>

        <SimpleDialogActions {...{CancelButtonProps, isSubmitting}} />

        <FormikStatus />
      </Form>
    </>
  );
}

export default GenericNameFieldForm;
