/// <reference path="../custom.d.ts" />

import cookie from 'js-cookie';

const headers = {
  'Accept'      : 'application/json',
  'Content-Type': 'application/json',
};

const headersWithCsrf = {
  ...headers,
  [process.env.REACT_APP_CSRFTOKEN_HEADER_NAME]: cookie.get(process.env.REACT_APP_CSRFTOKEN_COOKIE_NAME) || '',
};

export interface FetchOptions {
  baseUrl          : string;
  permissions_scope: string;
}

export default class {
  protected permissions_scope: string;
  private _baseUrl: string;

  constructor(options: FetchOptions) {
    this.permissions_scope = options.permissions_scope;

    // trim end slash if any
    this._baseUrl = options.baseUrl.replace(/\/+$/, '');
  }

  /**
   * @param {string} endpoint API endpoint to call
   * @param {RequestInit} options Request options
   */
  protected fetch(
    endpoint: string,
    options?: RequestInit,
  ): Promise<Response> {
    const url     = this._baseUrl + endpoint;
    const request = new Request(url, {...options});

    return fetch(request)
      .then(response => {
        switch (response.status) {
          case 200:
          case 201:
          case 204:
            return response;
          default:
            throw response;
        }
      });
  }

  protected get<T>(
    endpoint: string,
    options : RequestInit = {},
  ): Promise<T> {
    const getOptions = {
      method     : 'GET',
      credentials: 'include',
      headers,
      ...options,
    } as RequestInit;

    return this.fetch(endpoint, getOptions)
      .then(response => response.json<T>());
  }

  protected post<T>(
    endpoint: string,
    options : RequestInit = {},
  ): Promise<T> {
    let postOptions = {
      method     : 'POST',
      credentials: 'include',
      headers    : {...headersWithCsrf},
      ...options,
    } as RequestInit;
    console.log(headersWithCsrf);
    console.log('Cookie: ' + cookie.get(process.env.REACT_APP_CSRFTOKEN_COOKIE_NAME))

    return this.fetch(endpoint, postOptions)
      .then(response => response.json<T>());
  }

  protected patch<T>(
    endpoint: string,
    options : RequestInit = {},
  ): Promise<T> {
    const patchOptions = {
      method     : 'PATCH',
      credentials: 'include',
      headers    : {...headersWithCsrf},
      ...options,
    } as RequestInit;

    return this.fetch(endpoint, patchOptions)
      .then(response => response.json<T>());
  }

  protected delete(
    endpoint: string,
    options : RequestInit = {},
  ): Promise<null> {
    const deleteOptions = {
      method     : 'DELETE',
      credentials: 'include',
      headers    : {...headersWithCsrf},
      ...options,
    } as RequestInit;

    return this.fetch(endpoint, deleteOptions)
      .then(response => {
        if (response.status === 204) {
          return null;
        }

        throw response;
      });
  }
}
