import { UserWithGroups, UserRoles } from '../../api/services';
import { UsersSingleAction } from '../actions';

import {
  USERS_SINGLE_INIT,
  USERS_SINGLE_SUCCESS,
  USERS_SINGLE_FAILURE,
  USERS_SINGLE_RESET,
} from '../constants';

export interface UserSingle extends UserWithGroups {
  roles: UserRoles;
}

export const getUsersSingleStateToProps = <T extends UsersSingleState>({
  error,
  item,
  loading,
}: Pick<T, keyof UsersSingleState>) => ({error, item, loading});

export interface UsersSingleState {
  error  : boolean;
  item   : null | UserSingle;
  loading: boolean;
}

export const initialUsersSingleState: UsersSingleState = {
  error  : false,
  item   : null,
  loading: true,
};

export default function (state: UsersSingleState = initialUsersSingleState, action: UsersSingleAction): UsersSingleState {
  switch (action.type) {
    case USERS_SINGLE_INIT:
      return {
        ...initialUsersSingleState,
        loading: true,
      };
    case USERS_SINGLE_SUCCESS:
      const {roles, ...payload} = action.payload;
      return {
        error  : false,
        item   : {
          ...payload,
          roles: roles.sort(({period: {lower: a}}, {period: {lower: b}}) => a.localeCompare(b)),
        },
        loading: false,
      };
    case USERS_SINGLE_FAILURE:
      return {
        ...initialUsersSingleState,
        error  : true,
        loading: false,
      };
    case USERS_SINGLE_RESET:
      return {...initialUsersSingleState};
    default:
      return state;
  }
}
