import React from 'react';

import { connect, getIn, FormikValues } from 'formik';
import { InlineDatePicker, DatePickerInlineProps } from 'material-ui-pickers';
import { Omit } from 'react-redux';
import { startOfDay } from 'date-fns';

export type FormikInlineDatePickerProps =
  & {name: string}
  & {onChange?: DatePickerInlineProps['onChange']}
  & {value?: string | Date}
  & Omit<DatePickerInlineProps, 'onChange' | 'value'>;

export default connect<FormikInlineDatePickerProps, FormikValues>(({
  className,
  formik,
  ...props
}) => {
  const {
    errors,
    setFieldValue,
    setFieldTouched,
    submitCount,
    validateOnBlur,
    values,
  } = formik;

  const {
    name,
    value: defaultValue,
    onChange: handleChange,
    ...other
  } = props;

  const value = getIn(values, name);

  const errorMessage    = getIn(errors, name);
  const hasErrorMessage = !!errorMessage;
  const error           = hasErrorMessage;
  const helperText      = !!submitCount ? errorMessage : undefined;

  const handlePickerBlur: DatePickerInlineProps['onBlur'] = () => {
    setFieldTouched(name, true, validateOnBlur);
  };

  const handlePickerChange: DatePickerInlineProps['onChange'] = value => {
    const v = value ? startOfDay(value) : undefined;

    setFieldValue(name, v, true);

    if (typeof handleChange === 'function') {
      handleChange(v);
    }
  };

  const handlePickerError: DatePickerInlineProps['onError']   = value => {
    // setFieldError(name, message as string);
    setFieldValue(name, value, true);
  };

  return (
    <InlineDatePicker
      clearable
      format="dd/MM/yyyy"
      InputProps={{
        style: {
          fontSize: 'inherit',
          width   : 130,
        },
      }}
      keyboard
      mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
      onBlur={() => handlePickerBlur}
      onChange={handlePickerChange}
      onError={handlePickerError}
      variant="standard"
      value={value === undefined ? null : value}
      {...{error, helperText, name}}
      {...other}
    />
  );
});
