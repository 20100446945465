import {
  ActionType,
  createStandardAction,
} from 'typesafe-actions';

import {
  UNITES_OP_DELETE_INIT,
  UNITES_OP_DELETE_SUCCESS,
  UNITES_OP_DELETE_FAILURE,
  UNITES_OP_DELETE_RESET,
} from '../constants';

// import { UniteOpDetails } from '../../shared/models/iam';

export const UNITES_OP_DELETE_INIT_ACTION = createStandardAction(UNITES_OP_DELETE_INIT)<number>();
export type UnitesOpDeleteInitActionType = ActionType<typeof UNITES_OP_DELETE_INIT_ACTION>;

export const UNITES_OP_DELETE_SUCCESS_ACTION = createStandardAction(UNITES_OP_DELETE_SUCCESS)();
export type UnitesOpDeleteSuccessActionType  = ActionType<typeof UNITES_OP_DELETE_SUCCESS_ACTION>;

export const UNITES_OP_DELETE_FAILURE_ACTION = createStandardAction(UNITES_OP_DELETE_FAILURE)();
export type UnitesOpDeleteFailureActionType  = ActionType<typeof UNITES_OP_DELETE_FAILURE_ACTION>;

export const UNITES_OP_DELETE_RESET_ACTION = createStandardAction(UNITES_OP_DELETE_RESET)();
export type UnitesOpDeleteResetActionType  = ActionType<typeof UNITES_OP_DELETE_RESET_ACTION>;

export type UnitesOpDeleteAction =
  | UnitesOpDeleteInitActionType
  | UnitesOpDeleteSuccessActionType
  | UnitesOpDeleteFailureActionType
  | UnitesOpDeleteResetActionType;

export default {
  UNITES_OP_DELETE_INIT_ACTION,
  UNITES_OP_DELETE_SUCCESS_ACTION,
  UNITES_OP_DELETE_FAILURE_ACTION,
  UNITES_OP_DELETE_RESET_ACTION,
};
