import { Epic, ofType } from 'redux-observable';

import { ReplaySubject, forkJoin } from 'rxjs';
import { catchError, concatMap, switchMap, tap } from 'rxjs/operators';

import Api from '../../configureApi';

import {
  Action,
  RolesAddInitActionType,
  ROLES_ADD_SUCCESS_ACTION,
  ROLES_ADD_RESET_ACTION,
  ROLES_ADD_FAILURE_ACTION,
  USERS_SINGLE_SUCCESS_ACTION,
  USERS_SINGLE_FAILURE_ACTION,
  USERS_LIST_IAT_RESET_ACTION,
} from '../actions';

import { ROLES_ADD_INIT } from '../constants';
import { catchResponse } from '../../shared/helpers';

export const rolesAddEpic: Epic<RolesAddInitActionType, any> = action$ => action$
  .pipe(
    ofType(ROLES_ADD_INIT),
    switchMap(({meta, payload, type}) => {
      const stack$ = new ReplaySubject<Action>();

      const {user_id} = payload;
      const {actions} = meta;

      return Api.Services.createRole(payload).pipe(
        catchError((response: Response) => catchResponse(response, {actions, type})),
        tap(item => {
          stack$.next(ROLES_ADD_SUCCESS_ACTION(item));
          stack$.next(ROLES_ADD_RESET_ACTION());
        }, () => {
          stack$.next(ROLES_ADD_FAILURE_ACTION());
          stack$.next(ROLES_ADD_RESET_ACTION());
          stack$.complete();
        }),
        concatMap(() => (
          forkJoin(
            Api.Services.getUser(user_id),
            Api.Services.getUserRoles(user_id),
          ).pipe(
            tap(([user, roles]) => {
              stack$.next(USERS_SINGLE_SUCCESS_ACTION({...user, roles}));
              stack$.complete();
            }, () => {
              stack$.next(USERS_SINGLE_FAILURE_ACTION());
              stack$.next(USERS_LIST_IAT_RESET_ACTION());

              stack$.complete();
            }),
          )
        )),
        concatMap(() => {
          const {resetForm} = actions;
          const {values}    = meta;

          resetForm({...values});

          return stack$;
        }),
        catchError(() => {
          const {setSubmitting} = actions;
          setSubmitting(false);
          return stack$;
        }),
      );
    }),
  );

export default [
  rolesAddEpic,
];
