import { UsersMailAction } from '../actions';

import {
  USERS_MAIL_INIT,
  USERS_MAIL_SUCCESS,
  USERS_MAIL_FAILURE,
  USERS_MAIL_RESET,
} from '../constants';

export const getUsersMailStateToProps = <T extends UsersMailState>({
  error,
  loading,
  success,
}: Pick<T, keyof UsersMailState>) => ({error, loading, success});

export interface UsersMailState {
  error  : boolean;
  loading: boolean;
  success: boolean;
}

export const initialUsersMailState: UsersMailState = {
  error  : false,
  loading: false,
  success: false,
};

export default function (state: UsersMailState = initialUsersMailState, action: UsersMailAction): UsersMailState {
  switch (action.type) {
    case USERS_MAIL_INIT:
      return {
        ...initialUsersMailState,
        loading: true,
      };
    case USERS_MAIL_SUCCESS:
      return {
        ...initialUsersMailState,
        success: true,
      };
    case USERS_MAIL_FAILURE:
      return {
        ...initialUsersMailState,
        error: true,
      };
    case USERS_MAIL_RESET:
      return {...initialUsersMailState};
    default:
      return state;
  }
}
