import { Epic, ofType } from 'redux-observable';

import { ReplaySubject } from 'rxjs';
import { catchError, concatMap, switchMap, tap } from 'rxjs/operators';

import Api from '../../configureApi';

import {
  Action,
  UsersAddInitActionType,
  USERS_ADD_SUCCESS_ACTION,
  USERS_ADD_RESET_ACTION,
  USERS_ADD_FAILURE_ACTION,
  USERS_LIST_SUCCESS_ACTION,
  USERS_LIST_IAT_ACTION,
  USERS_LIST_FAILURE_ACTION,
  USERS_LIST_IAT_RESET_ACTION,
  UsersAddGroupsInitActionType,
  USERS_ADD_GROUPS_SUCCESS_ACTION,
  USERS_ADD_GROUPS_RESET_ACTION,
  USERS_ADD_GROUPS_FAILURE_ACTION,
  USERS_SINGLE_SUCCESS_ACTION,
} from '../actions';

import { USERS_ADD_INIT, USERS_ADD_GROUPS_INIT } from '../constants';
import { catchResponse } from '../../shared/helpers';

export const UsersAddEpic: Epic<UsersAddInitActionType, any> = action$ => action$
  .pipe(
    ofType(USERS_ADD_INIT),
    switchMap(({meta, payload, type}) => {
      const stack$ = new ReplaySubject<Action>();

      const {actions} = meta;

      return Api.Services.createUser(payload).pipe(
        catchError((response: Response) => catchResponse(response, {actions, type})),
        tap(item => {
          stack$.next(USERS_ADD_SUCCESS_ACTION(item));
          stack$.next(USERS_ADD_RESET_ACTION());
        }, () => {
          stack$.next(USERS_ADD_FAILURE_ACTION());
          stack$.next(USERS_ADD_RESET_ACTION());
          stack$.complete();
        }),
        concatMap(() => Api.Services.getUsers().pipe(
          tap(items => {
            stack$.next(USERS_LIST_SUCCESS_ACTION(items));
            stack$.next(USERS_LIST_IAT_ACTION());
            stack$.complete();
          }, () => {
            stack$.next(USERS_LIST_FAILURE_ACTION());
            stack$.next(USERS_LIST_IAT_RESET_ACTION());
            stack$.complete();
          }),
        )),
        concatMap(() => stack$),
        catchError(() => {
          const {setSubmitting} = actions;
          setSubmitting(false);
          return stack$;
        }),
      );
    }),
  );

export const usersAddGroupsEpic: Epic<UsersAddGroupsInitActionType, any> = action$ => action$
  .pipe(
    ofType(USERS_ADD_GROUPS_INIT),
    switchMap(({meta, payload: groups}) => {
      const stack$ = new ReplaySubject<Action>();

      const {actions, user}     = meta;
      const {id: userId, roles} = user;

      return Api.Services.updateUser(userId, {groups}).pipe(
        tap(item => {
          stack$.next(USERS_ADD_GROUPS_SUCCESS_ACTION(item));
          stack$.next(USERS_SINGLE_SUCCESS_ACTION({
            ...item,
            roles,
          }));
          stack$.next(USERS_ADD_GROUPS_RESET_ACTION());
          stack$.complete();
        }, () => {
          stack$.next(USERS_ADD_GROUPS_FAILURE_ACTION());
          stack$.next(USERS_ADD_GROUPS_RESET_ACTION());
          stack$.complete();
        }),
        concatMap(() => {
          const {resetForm} = actions;
          const {values}    = meta;

          resetForm({...values});

          return stack$;
        }),
        catchError(() => {
          const {setSubmitting} = actions;
          setSubmitting(false);

          return stack$;
        }),
        // tap(a => console.log(a), e => console.error(e), () => console.log('COMPLETE')),
      );
    }),
  );

export default [
  UsersAddEpic,
  usersAddGroupsEpic,
];
