import {
  IamApi,
  ServicesApi,
} from './api';

export interface MsDigitalApi {
  Iam     : IamApi;
  Services: ServicesApi;
}

const Iam = new IamApi({
  baseUrl          : `${process.env.REACT_APP_API_BASE_URL}/iam/`,
  permissions_scope: 'iam',
});

const Services = new ServicesApi({
  baseUrl          : `${process.env.REACT_APP_API_BASE_URL}/services/`,
  permissions_scope: 'services',
});

const apis: MsDigitalApi = {
  Iam,
  Services,
};

Object.freeze(apis);

export default apis;
