import { UniteOpDetails } from '../../api/services';
import { UnitesOpSingleAction } from '../actions';

import {
  UNITES_OP_SINGLE_INIT,
  UNITES_OP_SINGLE_SUCCESS,
  UNITES_OP_SINGLE_FAILURE,
  UNITES_OP_SINGLE_RESET,
} from '../constants';

import { sortBoolean } from '../../shared/helpers';

export const getUnitesOpSingleStateToProps = <T extends UnitesOpSingleState>({
  error,
  item,
  loading,
}: Pick<T, keyof UnitesOpSingleState>) => ({error, item, loading});

export interface UnitesOpSingleState {
  error  : boolean;
  item   : null | UniteOpDetails;
  loading: boolean;
}

export const initialUnitesOpSingleState: UnitesOpSingleState = {
  error  : false,
  item   : null,
  loading: true,
};

export default function (state: UnitesOpSingleState = initialUnitesOpSingleState, action: UnitesOpSingleAction): UnitesOpSingleState {
  switch (action.type) {
    case UNITES_OP_SINGLE_INIT:
      return {
        ...initialUnitesOpSingleState,
        loading: true,
      };
    case UNITES_OP_SINGLE_SUCCESS:
      const {services, ...payload} = action.payload;

      return {
        error  : false,
        item   : {
          ...payload,
          services: services
            .sort((a, b) => a.name.localeCompare(b.name))           // sort by name, asc
            // .sort((a, b) => b.roles.length - a.roles.length)        // sort by users in service, desc
            // .sort((a, b) => sortBoolean(a.is_active, b.is_active)), // sort by active, desc
        },
        loading: false,
      };
    case UNITES_OP_SINGLE_FAILURE:
      return {
        ...initialUnitesOpSingleState,
        error  : true,
        loading: false,
      };
    case UNITES_OP_SINGLE_RESET:
      return {...initialUnitesOpSingleState};
    default:
      return state;
  }
}
