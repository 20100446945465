import React from 'react';

import {
  IconButton,
} from '@material-ui/core';

import { IconButtonProps } from '@material-ui/core/IconButton';

import RefreshIcon from '@material-ui/icons/Refresh';

export type PageErrorProps =
  & IconButtonProps;

export function PageError(props: PageErrorProps) {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <p>Une erreur est survenue, veuillez réessayer ultérieurement.</p>
      <IconButton {...props}>
        <RefreshIcon />
      </IconButton>
    </div>
  );
}

export default PageError;
