import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import {
  withFormik,
} from 'formik';

import {
  GenericNameFieldForm,
} from '../../components';

import {
  GenericNameFieldFormBaseProps,
  GenericNameFieldFormValues,
  GenericNameFieldFormSchema,
} from '../../components/Form/GenericNameFieldForm';

import { SimpleDialogActionsProps } from '../../components/SimpleDialogActions';

import {
  Action,
  SERVICES_ADD_INIT_ACTION,
} from '../../store/actions';

export interface ServicesAddDialogBaseProps {
  addService: typeof SERVICES_ADD_INIT_ACTION;
  uniteOpId : number;
}

export type ServicesAddDialogProps =
  & ServicesAddDialogBaseProps
  & GenericNameFieldFormBaseProps
  & Pick<SimpleDialogActionsProps, 'CancelButtonProps'>;

const mapDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
  addService: SERVICES_ADD_INIT_ACTION,
}, dispatch);

export default connect(null, mapDispatchToProps)(withFormik<ServicesAddDialogProps, GenericNameFieldFormValues>({
  validationSchema: GenericNameFieldFormSchema,
  mapPropsToValues: () => ({
    name: '',
  }),
  handleSubmit: (values, {props, ...actions}) => {
    const {addService, uniteOpId} = props;

    addService(values, {uniteOpId, actions});
  },
})(GenericNameFieldForm));
