import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import {
  withFormik,
} from 'formik';

import {
  GenericNameFieldForm,
} from '../../components';

import {
  GenericNameFieldFormBaseProps,
  GenericNameFieldFormValues,
  GenericNameFieldFormSchema,
} from '../../components/Form/GenericNameFieldForm';

import { SimpleDialogActionsProps } from '../../components/SimpleDialogActions';

import {
  Action,
  SERVICES_EDIT_INIT_ACTION,
} from '../../store/actions';

import { Service } from '../../api/services';

export interface ServicesEditDialogBaseProps {
  editService: typeof SERVICES_EDIT_INIT_ACTION;
  service    : Service;
}

export type ServicesEditDialogProps =
  & ServicesEditDialogBaseProps
  & GenericNameFieldFormBaseProps
  & Pick<SimpleDialogActionsProps, 'CancelButtonProps'>;

const mapDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
  editService: SERVICES_EDIT_INIT_ACTION,
}, dispatch);

export default connect(null, mapDispatchToProps)(withFormik<ServicesEditDialogProps, GenericNameFieldFormValues>({
  validationSchema: GenericNameFieldFormSchema,
  mapPropsToValues: ({service}) => ({
    name: service.name,
  }),
  handleSubmit: (values, {props, ...actions}) => {
    const {editService, service: previous} = props;

    editService(values, {previous, actions});
  },
})(GenericNameFieldForm));
