import { UserWithGroups } from '../../api/services';
import { UsersAddAction } from '../actions';

import {
  USERS_ADD_INIT,
  USERS_ADD_SUCCESS,
  USERS_ADD_FAILURE,
  USERS_ADD_RESET,
} from '../constants';

export const getUsersAddStateToProps = <T extends UsersAddState>({
  error,
  item,
  loading,
}: Pick<T, keyof UsersAddState>) => ({error, item, loading});

export interface UsersAddState {
  error  : boolean;
  item   : null | UserWithGroups;
  loading: boolean;
}

export const initialUsersAddState: UsersAddState = {
  error  : false,
  item   : null,
  loading: false,
};

export default function (state: UsersAddState = initialUsersAddState, action: UsersAddAction): UsersAddState {
  switch (action.type) {
    case USERS_ADD_INIT:
      return {
        ...initialUsersAddState,
        loading: true,
      };
    case USERS_ADD_SUCCESS:
      return {
        ...initialUsersAddState,
        error  : false,
        item   : {...action.payload},
        loading: false,
      };
    case USERS_ADD_FAILURE:
      return {
        ...initialUsersAddState,
        error: true,
      };
    case USERS_ADD_RESET:
      return {...initialUsersAddState};
    default:
      return state;
  }
}
