import React, { useEffect, useState } from 'react';
import { RouteComponentProps, Redirect } from 'react-router';

import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { useSnackbar } from 'notistack';

import classNames from 'classnames';
import pluralize from '../../pluralize';

import {
  Button,
  createStyles,
  Dialog,
  IconButton,
  Menu,
  MenuItem,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core';

import MoreVertIcon from '@material-ui/icons/MoreVert';

import {
  UnitesOpDeleteDialog,
  UnitesOpEditDialog,
} from '.';

import {
  ServicesAddDialog,
  ServicesDeleteDialog,
  ServicesEditDialog,
} from '../services';

import {
  Can,
  ListServices,
  PageError,
  PageTitle,
  TextLoader,
  FullWidthContainer,
} from '../../components';

import { ListServicesProps } from '../../components/List/ListServices';

import {
  Action,
  UNITES_OP_SINGLE_INIT_ACTION,
  UNITES_OP_SINGLE_RESET_ACTION,
  UNITES_OP_LIST_IAT_RESET_ACTION,
} from '../../store/actions';

import {
  RootState,
  UnitesOpDeleteState,
  UnitesOpEditState,
  UnitesOpSingleState,
  ServicesAddState,
  ServicesEditState,
  ServicesDeleteState,
} from '../../store/reducers';

import { UniteOp, Services, Service } from '../../api/services';
import { WithPropsFromState } from '../../shared/types';

const styles = ({palette, shape, shadows}: Theme) => createStyles({
  panelLoader: {
    boxShadow   : shadows[1],
    borderRadius: shape.borderRadius,
    '& > *'     : {
      borderRadius: 0,
    },
    '& > * + *': {
      borderTop: `1px solid ${palette.divider}`,
    },
    '& > :first-child': {
      borderTopLeftRadius : shape.borderRadius,
      borderTopRightRadius: shape.borderRadius,
    },
    '& > :last-child': {
      borderBottomLeftRadius : shape.borderRadius,
      borderBottomRightRadius: shape.borderRadius,
    },
  },
});

export interface UnitesOpSinglePageBaseProps {
  addServiceState   : ServicesAddState;
  clean             : typeof UNITES_OP_SINGLE_RESET_ACTION;
  cleanUnitesOpList : typeof UNITES_OP_LIST_IAT_RESET_ACTION;
  deleteState       : UnitesOpDeleteState;
  deleteServiceState: ServicesDeleteState;
  editState         : UnitesOpEditState;
  editServiceState  : ServicesEditState;
  getUniteOp        : typeof UNITES_OP_SINGLE_INIT_ACTION;
}

export type UnitesOpSinglePageProps =
  & UnitesOpSinglePageBaseProps
  & RouteComponentProps<
    {uniteOpId: string},
    {},
    undefined | UniteOp
  >
  & WithPropsFromState<UnitesOpSingleState>
  & WithStyles<typeof styles>;

export function UnitesOpSinglePage({
  addServiceState,
  classes,
  clean,
  cleanUnitesOpList,
  deleteState,
  deleteServiceState,
  editState,
  editServiceState,
  history,
  loading,
  location,
  match,
  ...other
}: UnitesOpSinglePageProps) {
  const [menuUniteOp, setMenuUniteOp] = useState<any>(null);

  const [openEditDialog, setOpenEditDialog]     = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [openAddServiceDialog, setOpenAddServiceDialog] = useState(false);
  const [editingService, setEditingService]             = useState<null | Service>(null);
  const [deletingService, setDeletingService]           = useState<null | Service>(null);

  const {enqueueSnackbar} = useSnackbar();

  let countUsers         = 0;
  let services: Services = [];

  const {error, item, getUniteOp} = other;
  const {loading: isEditing}      = editState;
  const {loading: isDeleting}     = deleteState;

  const {loading: isAddingService}   = addServiceState;
  const {loading: isEditingService}  = editServiceState;
  const {loading: isDeletingService} = deleteServiceState;

  if (!!item) {
    services   = item.services;
    countUsers = services.reduce((rolesLength, cv) => rolesLength + cv.roles.length, 0);
  }

  const {params} = match;
  const {state}  = location;

  const uniteOpId = parseInt(params.uniteOpId);

  const handleDeleteService: ListServicesProps['onDeleteClick'] = (service) => {
    setDeletingService(service);
  };

  const handleRenameService: ListServicesProps['onRenameClick'] = (service) => {
    setEditingService(service);
  };

  // get unite op on mount
  useEffect(() => {
    getUniteOp(uniteOpId);
  }, []);

  // if item change and we have data, replace history state with fresh data
  useEffect(() => {
    if (!!item) {
      history.replace(location.pathname, {
        ...item,
        services: services.length,
        users   : countUsers,
      } as UniteOp);

      cleanUnitesOpList();
    }
  }, [item]);

  // if we have an edited item, then close the dialog
  useEffect(() => {
    if (!!editState.item) {
      setOpenEditDialog(false);

      // show snackbar if item has been modified
      if (editState.diff) {
        enqueueSnackbar('Unité opérationnelle renommée avec succès', {variant: 'success', persist: false});
      }
    }
  }, [editState.item]);

  // if we have an add service, then close the dialog
  useEffect(() => {
    if (!!addServiceState.item) {
      setOpenAddServiceDialog(false);
      enqueueSnackbar('Service ajouté avec succès', {variant: 'success', persist: false});
    }
  }, [addServiceState.item]);

  // if we have an edited service, then close the dialog
  useEffect(() => {
    if (!!editServiceState.item) {
      setEditingService(null);

      if (editServiceState.diff) {
        enqueueSnackbar('Service renommé avec succès', {variant: 'success', persist: false});
      }
    }
  }, [editServiceState.item]);

  // if we have a deleted service, then close the dialog
  useEffect(() => {
    if (deleteServiceState.success) {
      setDeletingService(null);

      enqueueSnackbar(`Service supprimé avec succès`, {
        persist: false,
        variant: 'success',
      });
    }
  }, [deleteServiceState.success]);

  // open a snackbar on delete item on unmount (before redirect)
  useEffect(() => () => {
    if (deleteState.success) {
      enqueueSnackbar(`Unité Opérationnelle supprimée avec succès`, {
        persist: false,
        variant: 'success',
      });
    }
  }, [deleteState.success]);

  // clean item in redux on unmount
  useEffect(() => () => {
    clean();
  }, []);

  // loading data
  if (loading) {
    // we have state from history, render a placeholder
    if (!!state) {
      const countServices = Array(state.services || 1).fill(null);

      return (
        <>
          <div className="d-flex align-items-center" style={{minHeight: 48}}>
            <PageTitle
              hr={false}
              primary={state.name}
              secondary={pluralize('utilisateur actif', state.users, true)}
            />
          </div>

          <div className={classNames(classes.panelLoader, 'mt-4')}>
            {countServices.map((_, key) => (
              <FullWidthContainer
                className="p-3"
                elevation={0}
                {...{key}}
              >
                <TextLoader />
              </FullWidthContainer>
            ))}
          </div>
        </>
      );
    } else {
      // no state, just render a progress text
      return <TextLoader />;
    }
  }

  // api fetch error
  if (error || !item) {
    return <PageError onClick={() => getUniteOp(uniteOpId)} />;
  }

  // redirect to list if deleted
  if (deleteState.success) {
    return <Redirect to="/unites-operationnelles" push />
  }

  return (
    <>
      <div className="d-flex flex-row align-items-center">
        <PageTitle
          className="flex-1"
          hr={false}
          primary={item.name}
          secondary={pluralize('utilisateur actif', countUsers, true)}
        />

        <Can I="changeOrDelete" an="OU">
          {() => (
            <IconButton onClick={({currentTarget}) => setMenuUniteOp(currentTarget)}>
              <MoreVertIcon />
            </IconButton>
          )}
        </Can>
      </div>

      <div className="mt-4">
        {services.length >= 1 ? services.map(service => (
          <ListServices
            key={service.id}
            onDeleteClick={handleDeleteService}
            onRenameClick={handleRenameService}
            {...{service}}
          />
        )) : (
          <FullWidthContainer>
            <span>Aucun service de créé dans cette unité opérationnelle.</span>
          </FullWidthContainer>
        )}
      </div>

      <Can I="add" a="Service">
        <div className="mt-6">
          <Button
            color="primary"
            onClick={() => {
              setOpenAddServiceDialog(true);
              setMenuUniteOp(null);
            }}
            variant="contained"
          >Créer un service</Button>
        </div>
      </Can>

      <Menu
        anchorEl={menuUniteOp}
        disableAutoFocusItem
        open={Boolean(menuUniteOp)}
        onClose={() => setMenuUniteOp(null)}
      >
        <Can I="change" an="OU">
          {() => (
            <MenuItem
              button
              onClick={() => {
                setOpenEditDialog(true);
                setMenuUniteOp(null);
              }}
            >Renommer</MenuItem>
          )}
        </Can>
        <Can I="delete" an="OU">
          {() => (
            <MenuItem
              button
              disabled={services.length >= 1}
              onClick={() => {
                setOpenDeleteDialog(true);
                setMenuUniteOp(null);
              }}
            >Supprimer</MenuItem>
          )}
        </Can>
      </Menu>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={openEditDialog}
        disableBackdropClick={isEditing}
        disableEscapeKeyDown={isEditing}
        onClose={() => setOpenEditDialog(false)}
      >
        <UnitesOpEditDialog
          CancelButtonProps={{onClick: () => setOpenEditDialog(false)}}
          title={`Renommer « ${item.name} »`}
          unite_op={item}
        />
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={openDeleteDialog}
        disableBackdropClick={isDeleting}
        disableEscapeKeyDown={isDeleting}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <UnitesOpDeleteDialog
          id={item.id}
          CancelButtonProps={{onClick: () => setOpenDeleteDialog(false)}}
        />
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={openAddServiceDialog}
        disableBackdropClick={isAddingService}
        disableEscapeKeyDown={isAddingService}
        onClose={() => setOpenAddServiceDialog(false)}
      >
        <ServicesAddDialog
          CancelButtonProps={{onClick: () => setOpenAddServiceDialog(false)}}
          title={`Ajouter un service dans « ${item.name} »`}
          uniteOpId={item.id}
        />
      </Dialog>

      {editingService && (
        <Dialog
          fullWidth
          maxWidth="sm"
          open={true}
          disableBackdropClick={isEditingService}
          disableEscapeKeyDown={isEditingService}
          onClose={() => setEditingService(null)}
        >
          <ServicesEditDialog
            CancelButtonProps={{onClick: () => setEditingService(null)}}
            title={`Renommer « ${editingService.name} »`}
            service={editingService}
          />
        </Dialog>
      )}

      {deletingService && (
        <Dialog
          fullWidth
          maxWidth="sm"
          open={true}
          disableBackdropClick={isDeletingService}
          disableEscapeKeyDown={isDeletingService}
          onClose={() => setDeletingService(null)}
        >
          <ServicesDeleteDialog
            id={deletingService.id}
            uniteOpId={deletingService.operational_unit}
            CancelButtonProps={{onClick: () => setDeletingService(null)}}
          />
        </Dialog>
      )}
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  ...state.unites_op.single,
  deleteState       : {...state.unites_op.remove},
  editState         : {...state.unites_op.edit},
  addServiceState   : {...state.services.add},
  editServiceState  : {...state.services.edit},
  deleteServiceState: {...state.services.remove},
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
  clean            : UNITES_OP_SINGLE_RESET_ACTION,
  cleanUnitesOpList: UNITES_OP_LIST_IAT_RESET_ACTION,
  getUniteOp       : UNITES_OP_SINGLE_INIT_ACTION,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles, {name: 'UnitesOpSinglePage'})(UnitesOpSinglePage)
);
