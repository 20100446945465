import { createContext } from 'react';

import { AbilityBuilder, Ability } from '@casl/ability';

import * as PERMISSIONS from './api/services/permissions';
import { Permissions } from './api/services/';
import { CanAfficher } from './components/Table/TableUsersList';

/**
 * Defines how to detect object's type:
 * https://stalniy.github.io/casl/abilities/2017/07/20/define-abilities.html
 */
function subjectName(item: any) {
  if (!item || typeof item === 'string') {
    return item;
  }

  return item.__type;
}

/**
 * Create Ability from user's permissions.
 * @param {Permissions} permissions User's permissions
 */
export function createAbility(permissions: Permissions) {
  const {can, rules}       = AbilityBuilder.extract();
  let   canViewUsersSingle = false;

  // console.log(permissions);

  // Unités Opérationnelles
  if (permissions.includes(PERMISSIONS.VIEW_OU)) {
    can('view', 'OU');
  }

  if (permissions.includes(PERMISSIONS.ADD_OU)) {
    can('add', 'OU');
    can('add', 'Service');
  }

  if (permissions.includes(PERMISSIONS.CHANGE_OU)) {
    can('change', 'OU');
    can('change', 'Service');
  }

  if (permissions.includes(PERMISSIONS.DELETE_OU)) {
    can('delete', 'OU');
    can('delete', 'Service');
  }

  // pour afficher le menu kebab qui regroupe le rename et le delete
  if (
    permissions.includes(PERMISSIONS.CHANGE_OU)
    || permissions.includes(PERMISSIONS.DELETE_OU)
  ) {
    can('changeOrDelete', 'OU');
    can('changeOrDelete', 'Service');
  }

  // Services
  // if (permissions.includes(PERMISSIONS.VIEW_SERVICE)) {
  //   can('view', 'Service');
  // }

  // if (permissions.includes(PERMISSIONS.ADD_SERVICE)) {
  //   can('add', 'Service');
  // }

  // if (permissions.includes(PERMISSIONS.CHANGE_SERVICE)) {
  //   can('change', 'Service');
  // }

  // if (permissions.includes(PERMISSIONS.DELETE_SERVICE)) {
  //   can('delete', 'Service');
  // }

  // pour afficher le menu kebab qui regroupe le rename et le delete
  // if (
  //   permissions.includes(PERMISSIONS.CHANGE_OU)
  //   || permissions.includes(PERMISSIONS.DELETE_SERVICE)
  // ) {
  //   can('changeOrDelete', 'Service');
  // }

  // Role
  if (permissions.includes(PERMISSIONS.VIEW_ROLE)) {
    can('view', 'Role');
    canViewUsersSingle = true;
  }

  if (permissions.includes(PERMISSIONS.ADD_ROLE)) {
    can('add', 'Role');
    canViewUsersSingle = true;
  }

  if (permissions.includes(PERMISSIONS.CHANGE_ROLE)) {
    can('change', 'Role');
    canViewUsersSingle = true;
  }

  if (permissions.includes(PERMISSIONS.DELETE_ROLE)) {
    can('delete', 'Role');
    canViewUsersSingle = true;
  }

  // Utilisateurs
  if (permissions.includes(PERMISSIONS.VIEW_USER)) {
    can('view', 'User');
    canViewUsersSingle = true;
  }

  if (permissions.includes(PERMISSIONS.ADD_USER)) {
    can('add', 'User');
    canViewUsersSingle = true;
  }

  if (permissions.includes(PERMISSIONS.CHANGE_USER)) {
    can('change', 'User');
    canViewUsersSingle = true;
  }

  if (permissions.includes(PERMISSIONS.DELETE_USER)) {
    can('delete', 'User');
  }

  // je peux afficher la page d'un utilisateur
  if (canViewUsersSingle) {
    can('view', 'UsersSingle');
  }

  return new Ability(rules, {subjectName});
}

/**
 * Create a React context for `Can` component.
 */
export const AbilityContext = createContext(new Ability());
