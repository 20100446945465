import { RolesDeleteAction } from '../actions';

import {
  ROLES_DELETE_INIT,
  ROLES_DELETE_SUCCESS,
  ROLES_DELETE_FAILURE,
  ROLES_DELETE_RESET,
} from '../constants';

export const getRolesDeleteStateToProps = <T extends RolesDeleteState>({
  error,
  loading,
  success,
}: Pick<T, keyof RolesDeleteState>) => ({error, loading, success});

export interface RolesDeleteState {
  error  : boolean;
  loading: boolean;
  success: boolean;
}

export const initialRolesDeleteState: RolesDeleteState = {
  error  : false,
  loading: false,
  success: false,
};

export default function (state: RolesDeleteState = initialRolesDeleteState, action: RolesDeleteAction): RolesDeleteState {
  switch (action.type) {
    case ROLES_DELETE_INIT:
      return {
        ...initialRolesDeleteState,
        loading: true,
      };
    case ROLES_DELETE_SUCCESS:
      return {
        error  : false,
        loading: false,
        success: true,
      };
    case ROLES_DELETE_FAILURE:
      return {
        ...initialRolesDeleteState,
        error: true,
      };
    case ROLES_DELETE_RESET:
      return {...initialRolesDeleteState};
    default:
      return state;
  }
}
