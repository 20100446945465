import React from 'react';

import { getIn, Field, FieldProps } from 'formik';

import { InputAdornment, TextField } from '@material-ui/core';

import { TextFieldProps as MuiTextFieldProps } from '@material-ui/core/TextField';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

import CheckIcon from '@material-ui/icons/Check';

type FormikTextFieldProps =
  & {name: string}
  & MuiTextFieldProps;

const FormikTextFieldFactory = (
  form      : FieldProps<any>['form'],
  field     : FieldProps<any>['field'],
  outerProps: FormikTextFieldProps,
) => {
  const {
    errors,
    initialValues,
    isSubmitting,
    submitCount,
    touched,
    values,
  } = form;

  const {name, ...other} = outerProps;

  const isDefaultValue  = getIn(values, name) === getIn(initialValues, name);
  const isTouched       = getIn(touched, name);
  const errorMessage    = getIn(errors, name);
  const hasErrorMessage = !!errorMessage;
  const error           = hasErrorMessage && isTouched;
  const helperText      = !!submitCount ? errorMessage : undefined;

  let checkIconProps: SvgIconProps;

  // with error
  // or never touched
  if (
    isSubmitting
    || (hasErrorMessage || isDefaultValue)
  ) {
    checkIconProps = {color: 'disabled'};
  } else {
    checkIconProps = {nativeColor: 'green'};
  }

  const endAdornment = (
    <InputAdornment position="end">
      <CheckIcon {...checkIconProps} />
    </InputAdornment>
  );

  return (
    <TextField
      InputProps={{endAdornment}}
      {...{error, helperText}}
      {...field}
      {...other}
    />
  );
};

export default (props: FormikTextFieldProps) => <Field
  name={props.name}
  render={({form, field}: FieldProps) => FormikTextFieldFactory(form, field, props)}
/>;

// export default FormikTextField2;
