import React from 'react';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import {
  CssBaseline,
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core';

import { brandTheme } from '@ms-digital/react-design-system/lib/themes';

import { AbilityContext, createAbility } from './configureAbility';
import configureStore from './configureStore';

import { AppRouter } from './routers';
import { RootState } from './store/reducers';

import { SnackbarProvider } from './components';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';

import DateFnsUtils from '@date-io/date-fns';
import { fr } from 'date-fns/locale';

export interface AppProps {
  initialState: RootState;
}

const theme = createMuiTheme({
  ...brandTheme,
  palette: {
    ...brandTheme.palette,
    primary: {
      light: '#003462',
      main : '#005284',
      dark : '#39AE8A',
    },
    secondary: {
      main: '#39AE8A',
    },
  },
  overrides: {
    ...brandTheme.overrides,
    MuiTabs: {
      root: {
        borderBottom: `1px solid ${brandTheme.palette.divider}`,
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: brandTheme.typography.pxToRem(13),
      },
      gutters: {
        padding : '8px 4px',
      },
    },
    MuiTableCell: {
      root: {
        padding: '4px 16px',
        '&:last-child': {
          paddingRight: 16,
        },
      },
      head: {
        fontSize: brandTheme.typography.pxToRem(13),
      },
      paddingDense: {
        paddingRight: 16,
      },
    },
    MuiTableRow: {
      head: {
        height: 48,
      },
    },
  },
  typography: {
    ...brandTheme.typography,
    body2: {
      ...brandTheme.typography.body2,
      fontSize: 'inherit',
    },
  },
});

export function App({initialState}: AppProps) {
  const store  = configureStore(initialState);
  const {user} = store.getState();

  const ability = createAbility(user ? user.permissions : []);

  return (
    <Provider store={store}>
      <AbilityContext.Provider value={ability}>
        <BrowserRouter
          basename={process.env.PUBLIC_URL}
        >
          <MuiThemeProvider
            theme={theme}
          >
            <SnackbarProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
                <CssBaseline />
                <AppRouter />
              </MuiPickersUtilsProvider>
            </SnackbarProvider>
          </MuiThemeProvider>
        </BrowserRouter>
      </AbilityContext.Provider>
    </Provider>
  );
}

export default App;
