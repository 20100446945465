import React, { useEffect } from 'react';

import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { useSnackbar } from 'notistack';

import { QuestionDialog } from '../../components';
import { QuestionDialogProps } from '../../components/Dialog/QuestionDialog';

import { SERVICES_DELETE_INIT_ACTION, Action } from '../../store/actions';
import { RootState, ServicesDeleteState } from '../../store/reducers';

import { WithPropsFromState } from '../../shared/types';

export interface ServicesDeleteDialogBaseProps {
  deleteService: typeof SERVICES_DELETE_INIT_ACTION;
  id           : number;
  uniteOpId    : number;
}

export type ServicesDeleteDialogProps =
  & ServicesDeleteDialogBaseProps
  & QuestionDialogProps
  & WithPropsFromState<ServicesDeleteState>;

export function ServicesDeleteDialog({
  deleteService,
  error,
  id,
  success,
  uniteOpId,
  ...other
}: ServicesDeleteDialogProps) {
  const ConfirmButtonProps: QuestionDialogProps['ConfirmButtonProps'] = {
    onClick: () => deleteService(id, {uniteOpId}),
  };

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  useEffect(() => {
    if (error) {
      enqueueSnackbar(`Impossible de supprimer le service`, {
        key             : 'SERVICES_DELETE_FAILURE',
        preventDuplicate: true,
        variant         : 'error',
      });
    }
  }, [error]);

  useEffect(() => () => {
    closeSnackbar('SERVICES_DELETE_FAILURE');
  }, []);

  return (
    <QuestionDialog
      {...{ConfirmButtonProps}}
      {...other}
    >
      <p>Voulez-vous supprimer ce service ?</p>
    </QuestionDialog>
  );
}

const mapStateToProps = (state: RootState) => ({
  ...state.services.remove,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
  deleteService: SERVICES_DELETE_INIT_ACTION,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ServicesDeleteDialog);
