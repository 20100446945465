import {
  ActionType,
  createStandardAction,
} from 'typesafe-actions';

import {
  SERVICES_DELETE_INIT,
  SERVICES_DELETE_SUCCESS,
  SERVICES_DELETE_FAILURE,
  SERVICES_DELETE_RESET,
} from '../constants';

export const SERVICES_DELETE_INIT_ACTION = createStandardAction(SERVICES_DELETE_INIT)<number, {
  uniteOpId: number,
}>();
export type ServicesDeleteInitActionType = ActionType<typeof SERVICES_DELETE_INIT_ACTION>;

export const SERVICES_DELETE_SUCCESS_ACTION = createStandardAction(SERVICES_DELETE_SUCCESS)();
export type ServicesDeleteSuccessActionType  = ActionType<typeof SERVICES_DELETE_SUCCESS_ACTION>;

export const SERVICES_DELETE_FAILURE_ACTION = createStandardAction(SERVICES_DELETE_FAILURE)();
export type ServicesDeleteFailureActionType  = ActionType<typeof SERVICES_DELETE_FAILURE_ACTION>;

export const SERVICES_DELETE_RESET_ACTION = createStandardAction(SERVICES_DELETE_RESET)();
export type ServicesDeleteResetActionType  = ActionType<typeof SERVICES_DELETE_RESET_ACTION>;

export type ServicesDeleteAction =
  | ServicesDeleteInitActionType
  | ServicesDeleteSuccessActionType
  | ServicesDeleteFailureActionType
  | ServicesDeleteResetActionType;

export default {
  SERVICES_DELETE_INIT_ACTION,
  SERVICES_DELETE_SUCCESS_ACTION,
  SERVICES_DELETE_FAILURE_ACTION,
  SERVICES_DELETE_RESET_ACTION,
};
