import { UnitesOp } from '../../api/services';
import { UnitesOpListAction } from '../actions/unites_op-list-action';

import {
  UNITES_OP_LIST_INIT,
  UNITES_OP_LIST_SUCCESS,
  UNITES_OP_LIST_FAILURE,
  UNITES_OP_LIST_IAT,
  UNITES_OP_LIST_IAT_RESET,
} from '../constants';

import { sortBoolean } from '../../shared/helpers';

export const getUnitesOpListStateToProps = <T extends UnitesOpListState>({
  error,
  iat,
  items,
  loading,
}: Pick<T, keyof UnitesOpListState>) => ({error, iat, items, loading});

export interface UnitesOpListState {
  error  : boolean;
  iat    : number;
  items  : UnitesOp;
  loading: boolean;
}

export const initialUnitesOpListState: UnitesOpListState = {
  error  : false,
  iat    : 0,
  items  : [],
  loading: true,
};

export default function (state: UnitesOpListState = initialUnitesOpListState, action: UnitesOpListAction): UnitesOpListState {
  switch (action.type) {
    case UNITES_OP_LIST_INIT:
      return {
        ...state,
        error  : false,
        loading: true,
      };
    case UNITES_OP_LIST_SUCCESS:
      return {
        ...state,
        error: false,
        iat  : 0,
        items: [...action.payload]
          .sort((a, b) => a.name.localeCompare(b.name))
          .sort((a, b) => sortBoolean(a.is_active, b.is_active)),
        loading: false,
      };
    case UNITES_OP_LIST_FAILURE:
      return {
        ...state,
        error  : true,
        loading: false,
      };
    case UNITES_OP_LIST_IAT:
      return {
        ...state,
        iat: (new Date()).getTime(),
      };
    case UNITES_OP_LIST_IAT_RESET:
      return {
        ...state,
        iat: 0,
      };
    default:
      return state;
  }
}
