import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import {
  withFormik,
} from 'formik';

import {
  GenericNameFieldForm,
} from '../../components';

import {
  GenericNameFieldFormBaseProps,
  GenericNameFieldFormValues,
  GenericNameFieldFormSchema,
} from '../../components/Form/GenericNameFieldForm';

import { SimpleDialogActionsProps } from '../../components/SimpleDialogActions';

import { Action, UNITES_OP_EDIT_INIT_ACTION } from '../../store/actions';
import { UniteOpDetails } from '../../api/services';

export interface UnitesOpEditDialogBaseProps {
  editUniteOp: typeof UNITES_OP_EDIT_INIT_ACTION;
  unite_op   : UniteOpDetails;
}

export type UnitesOpEditDialogProps =
  & UnitesOpEditDialogBaseProps
  & GenericNameFieldFormBaseProps
  & Pick<SimpleDialogActionsProps, 'CancelButtonProps'>;

const mapDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
  editUniteOp: UNITES_OP_EDIT_INIT_ACTION,
}, dispatch);

export default connect(null, mapDispatchToProps)(withFormik<UnitesOpEditDialogProps, GenericNameFieldFormValues>({
  validationSchema: GenericNameFieldFormSchema,
  mapPropsToValues: ({unite_op}) => ({
    name: unite_op.name,
  }),
  handleSubmit: (values, {props, ...actions}) => {
    const {unite_op: previous} = props;

    props.editUniteOp(values, {actions, previous});
  },
})(GenericNameFieldForm));
