import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import {
  withFormik,
} from 'formik';

import { Action, UNITES_OP_ADD_INIT_ACTION } from '../../store/actions';

import {
  GenericNameFieldForm,
} from '../../components';

import {
  GenericNameFieldFormBaseProps,
  GenericNameFieldFormValues,
  GenericNameFieldFormSchema,
} from '../../components/Form/GenericNameFieldForm';

import { SimpleDialogActionsProps } from '../../components/SimpleDialogActions';

export interface UnitesOpAddDialogBaseProps {
  addUniteOp: typeof UNITES_OP_ADD_INIT_ACTION;
}

export type UnitesOpAddDialogProps =
  & UnitesOpAddDialogBaseProps
  & GenericNameFieldFormBaseProps
  & Pick<SimpleDialogActionsProps, 'CancelButtonProps'>;

const mapDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
  addUniteOp: UNITES_OP_ADD_INIT_ACTION,
}, dispatch);

export default connect(null, mapDispatchToProps)(withFormik<UnitesOpAddDialogProps, GenericNameFieldFormValues>({
  validationSchema: GenericNameFieldFormSchema,
  mapPropsToValues: () => ({
    name: '',
  }),
  handleSubmit: (values, {props, ...actions}) => {
    props.addUniteOp(values, {actions});
  },
})(GenericNameFieldForm));
