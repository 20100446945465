import {
  applyMiddleware,
  compose,
  createStore,
} from 'redux';

import {
  createEpicMiddleware,
} from 'redux-observable';

import { Action } from './store/actions';

import epics from './store/epics';
import reducers, { RootState } from './store/reducers';

/**
 * Create store with middlewares and initial state.
 *
 * @param {T} initialState Initialize state
 */
export default function<T>(initialState?: T) {
  // middleware for redux-oversable.
  const epicMiddleware = createEpicMiddleware<Action, Action, RootState>();

  // configure middlewares
  const middlewares = [
    epicMiddleware,
  ];

  // compose enhancers
  const enhancer = compose(
    applyMiddleware(...middlewares),
  );

  // create store
  const store = createStore(
    reducers,
    initialState,
    enhancer,
  );

  epicMiddleware.run(epics);

  return store;
}
