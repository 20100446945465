import { combineReducers } from 'redux';

import add, { initialRolesAddState, RolesAddState } from './roles-add-reducer';
import remove, { initialRolesDeleteState, RolesDeleteState } from './roles-delete-reducer';
import edit, { initialRolesEditState, RolesEditState } from './roles-edit-reducer';

export type RolesState =
  & {add: RolesAddState}
  & {remove: RolesDeleteState}
  & {edit: RolesEditState};

export const initialRolesState: RolesState = {
  add   : {...initialRolesAddState},
  remove: {...initialRolesDeleteState},
  edit  : {...initialRolesEditState},
};

export default combineReducers<RolesState>({
  add,
  remove,
  edit,
});
