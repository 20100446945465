import {
  ActionType,
  createStandardAction,
} from 'typesafe-actions';

import { FormikActions } from 'formik';

import {
  SERVICES_EDIT_INIT,
  SERVICES_EDIT_SUCCESS,
  SERVICES_EDIT_FAILURE,
  SERVICES_EDIT_RESET,
} from '../constants';

import {
  Service,
  ServicesUpdateRequest,
  ServicesUpdateResponse,
} from '../../api/services';

import { GenericNameFieldFormValues } from '../../components/Form/GenericNameFieldForm';

export const SERVICES_EDIT_INIT_ACTION = createStandardAction(SERVICES_EDIT_INIT)<ServicesUpdateRequest, {
  actions : FormikActions<GenericNameFieldFormValues>,
  previous: Service,
}>();
export type ServicesEditInitActionType = ActionType<typeof SERVICES_EDIT_INIT_ACTION>;

export const SERVICES_EDIT_SUCCESS_ACTION = createStandardAction(SERVICES_EDIT_SUCCESS)<ServicesUpdateResponse, {
  diff: boolean;
}>();
export type ServicesEditSuccessActionType  = ActionType<typeof SERVICES_EDIT_SUCCESS_ACTION>;

export const SERVICES_EDIT_FAILURE_ACTION = createStandardAction(SERVICES_EDIT_FAILURE)();
export type ServicesEditFailureActionType  = ActionType<typeof SERVICES_EDIT_FAILURE_ACTION>;

export const SERVICES_EDIT_RESET_ACTION = createStandardAction(SERVICES_EDIT_RESET)();
export type ServicesEditResetActionType  = ActionType<typeof SERVICES_EDIT_RESET_ACTION>;

export type ServicesEditAction =
  | ServicesEditInitActionType
  | ServicesEditSuccessActionType
  | ServicesEditFailureActionType
  | ServicesEditResetActionType;

export default {
  SERVICES_EDIT_INIT_ACTION,
  SERVICES_EDIT_SUCCESS_ACTION,
  SERVICES_EDIT_FAILURE_ACTION,
  SERVICES_EDIT_RESET_ACTION,
};
