import React, { PropsWithChildren } from 'react';

import {
  createStyles,
  Paper,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core';

type CenterContainerProps = PropsWithChildren<
  & WithStyles<typeof styles>
>;

// TODO: get toolbar height from theme
const styles = ({breakpoints}: Theme) => createStyles({
  paper: {
    padding: 'var(--spacer-4)',
    [breakpoints.up('sm')]: {
      width: '75%',
      // margin: '0 auto',
    },
    [breakpoints.up('xl')]: {
      width: '50%',
      // margin: '0 auto',
    },
  },
});

export function CenterContainer({
  children,
  classes,
}: CenterContainerProps) {
  return (
    <Paper
      className={classes.paper}
      {...{children}}
    />
  );
}

export default withStyles(styles, {name: 'CenterContainer'})(CenterContainer);
