import React from 'react';

import {
  Avatar as MuiAvatar,
  createStyles,
  Theme,
  withStyles,
} from '@material-ui/core';

import { AvatarProps as MuiAvatarProps } from '@material-ui/core/Avatar';

const CustomMuiAvatar = withStyles(({palette, typography}: Theme) => createStyles({
  root: {
    width: 24,
    height: 24,
    fontSize: typography.pxToRem(10),
    lineHeight: 1,
    textTransform: 'uppercase',
  },
  colorDefault: {
    color          : palette.common.white,
    // backgroundColor: palette.action.active,
    backgroundColor: '#757575',
  },
}), {name: 'CustomMuiAvatar'})(MuiAvatar);

export type AvatarProps =
  & MuiAvatarProps
  & {alt: string};

export function Avatar({children, ...other}: AvatarProps) {
  const initials = children
    ? children.toString()
      .split(' ', 2)
      .map(value => value.charAt(0))
      .join('')
    : undefined;

  return (<CustomMuiAvatar children={initials} {...other} />);
}

export default Avatar;
