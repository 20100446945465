import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import {
  withFormik,
} from 'formik';

import { UserWithGroups } from '../../api';
import { Action, USERS_EDIT_INIT_ACTION } from '../../store/actions';

import {
  UserForm,
} from '../../components';

import {
  UserFormBaseProps,
  UserFormValues,
  UserFormSchema,
} from '../../components/Form/UserForm';

import { SimpleDialogActionsProps } from '../../components/SimpleDialogActions';

export interface UsersEditDialogBaseProps {
  refresh   : boolean;
  user      : UserWithGroups;
  updateUser: typeof USERS_EDIT_INIT_ACTION;
}

export type UsersEditDialogProps =
  & UsersEditDialogBaseProps
  & UserFormBaseProps
  & Pick<SimpleDialogActionsProps, 'CancelButtonProps'>;

const mapDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
  updateUser: USERS_EDIT_INIT_ACTION,
}, dispatch);

export default connect(null, mapDispatchToProps)(withFormik<UsersEditDialogProps, UserFormValues>({
  validationSchema: UserFormSchema,
  mapPropsToValues: ({user}) => {
    const {email, first_name, last_name, phone} = user;

    return {
      email,
      first_name,
      last_name,
      phone: phone !== null ? phone : '',
    };
  },
  handleSubmit: (values, {props, ...actions}) => {
    const {refresh, user: previous} = props;

    props.updateUser({...values}, {actions, previous, refresh});
  },
})(UserForm));
