import React from 'react';

import { connect, getIn, FormikValues } from 'formik';

import { Input as MuiInput, Select, MenuItem, FormHelperText, createStyles, withStyles } from '@material-ui/core';
import { SelectProps } from '@material-ui/core/Select';

const styles = createStyles({
  root: {
    fontSize: 'inherit',
  },
});

const Input    = withStyles(styles, {name: 'Input'})(MuiInput);

export interface FormikSelectBaseProps {
  defaultValue?: any;
  items        : ((value: any) => React.ReactNodeArray) | any[];
  name         : string;
  onChange?    : (value: any) => void;
  renderItem?  : (value: any) => React.ReactNode;
  value?       : any;
}

export type FormikSelectProps =
  & FormikSelectBaseProps
  & SelectProps;

export const FormikSelect = connect<FormikSelectProps, FormikValues>(({
  formik,
  ...props
}) => {
  const {
    errors,
    setFieldValue,
    setFieldTouched,
    submitCount,
    validateOnBlur,
    values,
  } = formik;

  const {
    defaultValue,
    items,
    name,
    onBlur: handleBlur,
    onChange: handleChange,
    renderItem,
    ...other
  } = props;

  const value = getIn(values, name);

  const errorMessage    = getIn(errors, name);
  const hasErrorMessage = !!errorMessage;
  const error           = hasErrorMessage;
  const helperText      = !!submitCount ? errorMessage : undefined;

  const handleSelectBlur: typeof handleBlur = e => {
    setFieldTouched(name, true, validateOnBlur);

    if (typeof handleBlur === 'function') {
      handleBlur(e);
    }
  };

  const handleSelectChange = (value: any) => {
    setFieldValue(name, value, true);

    if (typeof handleChange === 'function') {
      handleChange(value);
    }
  };

  return (
    <div className="d-flex flex-column">
      <Select
        input={<Input {...{name}} />}
        onBlur={handleSelectBlur}
        onChange={({target}) => handleSelectChange(target.value)}
        value={value === undefined ? defaultValue : value}
        {...{error}}
        {...other}
      >
        {typeof items === 'function' ? items(value) : items.map((item, i) => (
          <MenuItem
            key={i}
            value={item}
          >{renderItem ? renderItem(item) : item}</MenuItem>
        ))}
      </Select>
      {helperText ? <FormHelperText {...{error}}>{helperText}</FormHelperText> : null}
    </div>
  );
});

FormikSelect.defaultProps = {
  defaultValue: '',
};

export default FormikSelect;
