import React from 'react';

import { Route, RouteProps, RouteComponentProps } from 'react-router';

import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core';

import {
  AppLayoutHeader,
  AppLayoutMenu,
} from '.';

const styles = ({breakpoints}: Theme) => createStyles({
  root: {},
  container: {
    height                : '100vh',
    paddingTop            : 57,
    paddingLeft           : 104,
    [breakpoints.up('sm')]: {
      paddingTop: 65,
    },
  },
  content: {
    minHeight    : '100%',
    paddingTop   : 'var(--spacer-4)',
    paddingRight : 'var(--spacer-4)',
    paddingBottom: 'var(--spacer-4)',
    paddingLeft  : 40,
  },
});

export type AppLayoutProps =
  & RouteProps
  & WithStyles<typeof styles>;

export function AppLayout({
  classes,
  component: MatchedComponent,
  ...other
}: AppLayoutProps) {
  const renderMatchedComponent = (matchedProps: RouteComponentProps<any>) => (
    <>
      <AppLayoutHeader />
      <AppLayoutMenu />

      <div className={classes.container}>
        <div className={classes.content}>
          {MatchedComponent ? <MatchedComponent {...matchedProps} /> : null}
        </div>
      </div>
    </>
  );

  return (
    <Route
      render={renderMatchedComponent}
      {...other}
    />
  );
}

export default withStyles(styles, {name: 'AppLayout'})(AppLayout);
