export const ADD_OU    = 'services.add_operationalunit';
export const CHANGE_OU = 'services.change_operationalunit';
export const DELETE_OU = 'services.delete_operationalunit';
export const VIEW_OU   = 'services.view_operationalunit';

export const ADD_ROLE   = 'services.add_role';
export const CHANGE_ROLE = 'services.change_role';
export const DELETE_ROLE = 'services.delete_role';
export const VIEW_ROLE   = 'services.view_role';

export const ADD_SERVICE    = 'services.add_service';
export const CHANGE_SERVICE = 'services.change_service';
export const DELETE_SERVICE = 'services.delete_service';
export const VIEW_SERVICE   = 'services.view_service';

export const ADD_USER    = 'services.add_iamuser';
export const CHANGE_USER = 'services.change_iamuser';
export const DELETE_USER = 'services.delete_iamuser';
export const VIEW_USER   = 'services.view_iamuser';
