import api from '../api';

import {
  APPLICATIONS_LIST,
  SELF_APPLICATIONS_LIST,
  SELF_DETAILS_LIST,
} from './endpoints';

import {
  ApplicationsListResponse,
  SelfApplicationsListResponse,
  SelfDetailsListResponse,
} from './responses';

export default class extends api {
  getApplications() {
    return this.get<ApplicationsListResponse>(APPLICATIONS_LIST);
  }

  /**
   * Get applications allowed for the current user.
   */
  getSelfApplications() {
    return this.get<SelfApplicationsListResponse>(SELF_APPLICATIONS_LIST);
  }

  /**
   * Get informations about the current user.
   */
  getSelfDetails() {
    return this.get<SelfDetailsListResponse>(SELF_DETAILS_LIST);
  }
}
