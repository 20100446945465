export const ROLES_ADD_INIT    = '@@ROLES_ADD/INIT';
export const ROLES_ADD_SUCCESS = '@@ROLES_ADD/SUCCESS';
export const ROLES_ADD_FAILURE = '@@ROLES_ADD/FAILURE';
export const ROLES_ADD_RESET   = '@@ROLES_ADD/RESET';

export const ROLES_EDIT_INIT    = '@@ROLES_EDIT/INIT';
export const ROLES_EDIT_SUCCESS = '@@ROLES_EDIT/SUCCESS';
export const ROLES_EDIT_FAILURE = '@@ROLES_EDIT/FAILURE';
export const ROLES_EDIT_RESET   = '@@ROLES_EDIT/RESET';

export const ROLES_DELETE_INIT    = '@@ROLES_DELETE/INIT';
export const ROLES_DELETE_SUCCESS = '@@ROLES_DELETE/SUCCESS';
export const ROLES_DELETE_FAILURE = '@@ROLES_DELETE/FAILURE';
export const ROLES_DELETE_RESET   = '@@ROLES_DELETE/RESET';

export const SERVICES_ADD_INIT    = '@@SERVICES_ADD/INIT';
export const SERVICES_ADD_SUCCESS = '@@SERVICES_ADD/SUCCESS';
export const SERVICES_ADD_FAILURE = '@@SERVICES_ADD/FAILURE';
export const SERVICES_ADD_RESET   = '@@SERVICES_ADD/RESET';

export const SERVICES_EDIT_INIT    = '@@SERVICES_EDIT/INIT';
export const SERVICES_EDIT_SUCCESS = '@@SERVICES_EDIT/SUCCESS';
export const SERVICES_EDIT_FAILURE = '@@SERVICES_EDIT/FAILURE';
export const SERVICES_EDIT_RESET   = '@@SERVICES_EDIT/RESET';

export const SERVICES_DELETE_INIT    = '@@SERVICES_DELETE/INIT';
export const SERVICES_DELETE_SUCCESS = '@@SERVICES_DELETE/SUCCESS';
export const SERVICES_DELETE_FAILURE = '@@SERVICES_DELETE/FAILURE';
export const SERVICES_DELETE_RESET   = '@@SERVICES_DELETE/RESET';

export const USERS_LIST_INIT      = '@@USERS_LIST/INIT';
export const USERS_LIST_SUCCESS   = '@@USERS_LIST/SUCCESS';
export const USERS_LIST_FAILURE   = '@@USERS_LIST/FAILURE';
export const USERS_LIST_IAT       = '@@USERS_LIST/IAT';
export const USERS_LIST_IAT_RESET = '@@USERS_LIST/IAT/RESET';

export const USERS_ADD_INIT    = '@@USERS_ADD/INIT';
export const USERS_ADD_SUCCESS = '@@USERS_ADD/SUCCESS';
export const USERS_ADD_FAILURE = '@@USERS_ADD/FAILURE';
export const USERS_ADD_RESET   = '@@USERS_ADD/RESET';

export const USERS_ADD_GROUPS_INIT    = '@@USERS_ADD_GROUPS/INIT';
export const USERS_ADD_GROUPS_SUCCESS = '@@USERS_ADD_GROUPS/SUCCESS';
export const USERS_ADD_GROUPS_FAILURE = '@@USERS_ADD_GROUPS/FAILURE';
export const USERS_ADD_GROUPS_RESET   = '@@USERS_ADD_GROUPS/RESET';

export const USERS_ADD_MAIL_INIT    = '@@USERS_ADD_MAIL/INIT';
export const USERS_ADD_MAIL_SUCCESS = '@@USERS_ADD_MAIL/SUCCESS';
export const USERS_ADD_MAIL_FAILURE = '@@USERS_ADD_MAIL/FAILURE';
export const USERS_ADD_MAIL_RESET   = '@@USERS_ADD_MAIL/RESET';

export const USERS_EDIT_INIT    = '@@USERS_EDIT/INIT';
export const USERS_EDIT_SUCCESS = '@@USERS_EDIT/SUCCESS';
export const USERS_EDIT_FAILURE = '@@USERS_EDIT/FAILURE';
export const USERS_EDIT_RESET   = '@@USERS_EDIT/RESET';

export const USERS_EDIT_VALIDITY_INIT    = '@@USERS_EDIT_VALIDITY/INIT';
export const USERS_EDIT_VALIDITY_SUCCESS = '@@USERS_EDIT_VALIDITY/SUCCESS';
export const USERS_EDIT_VALIDITY_FAILURE = '@@USERS_EDIT_VALIDITY/FAILURE';
export const USERS_EDIT_VALIDITY_RESET   = '@@USERS_EDIT_VALIDITY/RESET';

export const USERS_EDIT_ACTIVE_INIT    = '@@USERS_EDIT_ACTIVE/INIT';
export const USERS_EDIT_ACTIVE_SUCCESS = '@@USERS_EDIT_ACTIVE/SUCCESS';
export const USERS_EDIT_ACTIVE_FAILURE = '@@USERS_EDIT_ACTIVE/FAILURE';
export const USERS_EDIT_ACTIVE_RESET   = '@@USERS_EDIT_ACTIVE/RESET';
// export const USERS_EDIT_ACTIVE_CANCEL   = '@@USERS_EDIT_ACTIVE/CANCEL';

export const USERS_MAIL_INIT    = '@@USERS_MAIL/INIT';
export const USERS_MAIL_SUCCESS = '@@USERS_MAIL/SUCCESS';
export const USERS_MAIL_FAILURE = '@@USERS_MAIL/FAILURE';
export const USERS_MAIL_RESET   = '@@USERS_MAIL/RESET';

export const USERS_SINGLE_INIT    = '@@USERS_SINGLE/INIT';
export const USERS_SINGLE_SUCCESS = '@@USERS_SINGLE/SUCCESS';
export const USERS_SINGLE_FAILURE = '@@USERS_SINGLE/FAILURE';
export const USERS_SINGLE_RESET   = '@@USERS_SINGLE/RESET';

export const USERS_DELETE_INIT    = '@@USERS_DELETE/INIT';
export const USERS_DELETE_SUCCESS = '@@USERS_DELETE/SUCCESS';
export const USERS_DELETE_FAILURE = '@@USERS_DELETE/FAILURE';
export const USERS_DELETE_RESET   = '@@USERS_DELETE/RESET';

export const UNITES_OP_LIST_INIT      = '@@UNITES_OP_LIST/INIT';
export const UNITES_OP_LIST_SUCCESS   = '@@UNITES_OP_LIST/SUCCESS';
export const UNITES_OP_LIST_FAILURE   = '@@UNITES_OP_LIST/FAILURE';
export const UNITES_OP_LIST_IAT       = '@@UNITES_OP_LIST/IAT';
export const UNITES_OP_LIST_IAT_RESET = '@@UNITES_OP_LIST/IAT/RESET';

export const UNITES_OP_ADD_INIT    = '@@UNITES_OP_ADD/INIT';
export const UNITES_OP_ADD_SUCCESS = '@@UNITES_OP_ADD/SUCCESS';
export const UNITES_OP_ADD_FAILURE = '@@UNITES_OP_ADD/FAILURE';
export const UNITES_OP_ADD_RESET   = '@@UNITES_OP_ADD/RESET';

export const UNITES_OP_EDIT_INIT    = '@@UNITES_OP_EDIT/INIT';
export const UNITES_OP_EDIT_SUCCESS = '@@UNITES_OP_EDIT/SUCCESS';
export const UNITES_OP_EDIT_FAILURE = '@@UNITES_OP_EDIT/FAILURE';
export const UNITES_OP_EDIT_RESET   = '@@UNITES_OP_EDIT/RESET';

export const UNITES_OP_DELETE_INIT    = '@@UNITES_OP_DELETE/INIT';
export const UNITES_OP_DELETE_SUCCESS = '@@UNITES_OP_DELETE/SUCCESS';
export const UNITES_OP_DELETE_FAILURE = '@@UNITES_OP_DELETE/FAILURE';
export const UNITES_OP_DELETE_RESET   = '@@UNITES_OP_DELETE/RESET';

export const UNITES_OP_SINGLE_INIT    = '@@UNITES_OP_SINGLE/INIT';
export const UNITES_OP_SINGLE_SUCCESS = '@@UNITES_OP_SINGLE/SUCCESS';
export const UNITES_OP_SINGLE_FAILURE = '@@UNITES_OP_SINGLE/FAILURE';
export const UNITES_OP_SINGLE_RESET   = '@@UNITES_OP_SINGLE/RESET';

export const UNITES_OP_LIST_SERVICES_INIT      = '@@UNITES_OP_LIST_SERVICES/INIT';
export const UNITES_OP_LIST_SERVICES_SUCCESS   = '@@UNITES_OP_LIST_SERVICES/SUCCESS';
export const UNITES_OP_LIST_SERVICES_FAILURE   = '@@UNITES_OP_LIST_SERVICES/FAILURE';

export const USER_FETCH_INIT    = '@@USER_FETCH/INIT';
export const USER_FETCH_SUCCESS = '@@USER_FETCH/SUCCESS';
export const USER_FETCH_FAILURE = '@@USER_FETCH/FAILURE';
