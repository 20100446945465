import {
  ActionType,
  createStandardAction,
} from 'typesafe-actions';

import { FormikActions } from 'formik';

import {
  USERS_ADD_INIT,
  USERS_ADD_SUCCESS,
  USERS_ADD_FAILURE,
  USERS_ADD_RESET,
} from '../constants';

import { UserFormValues } from '../../components/Form/UserForm';

import {
  UsersCreateRequest,
  UsersCreateResponse,
} from '../../api/services';

export const USERS_ADD_INIT_ACTION = createStandardAction(USERS_ADD_INIT)<UsersCreateRequest, {
  actions: FormikActions<UserFormValues>,
}>();
export type UsersAddInitActionType = ActionType<typeof USERS_ADD_INIT_ACTION>;

export const USERS_ADD_SUCCESS_ACTION = createStandardAction(USERS_ADD_SUCCESS)<UsersCreateResponse>();
export type UsersAddSuccessActionType  = ActionType<typeof USERS_ADD_SUCCESS_ACTION>;

export const USERS_ADD_FAILURE_ACTION = createStandardAction(USERS_ADD_FAILURE)();
export type UsersAddFailureActionType  = ActionType<typeof USERS_ADD_FAILURE_ACTION>;

export const USERS_ADD_RESET_ACTION = createStandardAction(USERS_ADD_RESET)();
export type UsersAddResetActionType  = ActionType<typeof USERS_ADD_RESET_ACTION>;

export type UsersAddAction =
  | UsersAddInitActionType
  | UsersAddSuccessActionType
  | UsersAddFailureActionType
  | UsersAddResetActionType;

export default {
  USERS_ADD_INIT_ACTION,
  USERS_ADD_SUCCESS_ACTION,
  USERS_ADD_FAILURE_ACTION,
  USERS_ADD_RESET_ACTION,
};
