import { combineEpics } from 'redux-observable';

import rolesAddEpics from './roles-add-epic';
import rolesEditEpics from './roles-edit-epic';
import rolesDeleteEpics from './roles-delete-epic';

import servicesAddEpics from './services-add-epic';
import servicesDeleteEpics from './services-delete-epic';
import servicesEditEpics from './services-edit-epic';

import unitesOpAddEpics from './unites_op-add-epic';
import unitesOpDeleteEpics from './unites_op-delete-epic';
import unitesOpEditEpics from './unites_op-edit-epic';
import unitesOpListEpics from './unites_op-list-epic';
import unitesOpSingleEpics from './unites_op-single-epic';

import userEpics from './user-epic';

import usersAddEpics from './users-add-epic';
import usersDeleteEpics from './users-delete-epic';
import usersEditEpics from './users-edit-epic';
import usersListEpics from './users-list-epic';
import usersMailEpics from './users-mail-epic';
import usersSingleEpics from './users-single-epic';

export default combineEpics(
  ...rolesAddEpics,
  ...rolesEditEpics,
  ...rolesDeleteEpics,
  ...servicesAddEpics,
  ...servicesDeleteEpics,
  ...servicesEditEpics,
  ...unitesOpAddEpics,
  ...unitesOpDeleteEpics,
  ...unitesOpEditEpics,
  ...unitesOpListEpics,
  ...unitesOpSingleEpics,
  ...userEpics,
  ...usersAddEpics,
  ...usersDeleteEpics,
  ...usersEditEpics,
  ...usersListEpics,
  ...usersMailEpics,
  ...usersSingleEpics,
);
