import React from 'react';
import classNames from 'classnames';

import {
  createStyles,
  WithStyles,
  withStyles,
} from '@material-ui/core';

const styles = createStyles({
  root: {
    position: 'absolute',
    top     : 0,
    bottom  : 0,
  },
});

export interface StickyColumnBaseProps {
  top?   : number;
  right? : number;
  left?  : number;
}

export type StickyColumnProps =
  & StickyColumnBaseProps
  & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  & WithStyles<typeof styles>;

export function StickyColumn({
  children,
  classes,
  className: clsn,
  top,
  right,
  left,
  ...other
}: StickyColumnProps) {
  const style     = {top, right, left};
  const className = classNames(classes.root, clsn);

  return (
    <div
      {...{style, className}}
      {...other}
    >
      {children}
    </div>
  );
}

export default withStyles(styles, {name: 'StickyColumn'})(StickyColumn);
