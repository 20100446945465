import React from 'react';

import { Link } from 'react-router-dom';
import { LocationDescriptor } from 'history';

import pluralize from '../../pluralize';

import {
  List as MuiList,
  ListItem as MuiListItem,
  ListItemIcon,
  ListItemText as MuiListItemText,
  Theme,
  withStyles,
} from '@material-ui/core';

import FolderIcon from '@material-ui/icons/Folder';

import { TextLoader } from '..';
import { UnitesOpListState } from '../../store/reducers';

import { UniteOp } from '../../api/services';
import { WithPropsFromState } from '../../shared/types';

const List = withStyles(({palette, shape}: Theme) => ({
  root: {
    '& > *': {
      border: `1px solid ${palette.divider}`,
    },
    '& > * + *': {
      borderTop: 'none',
    },
    '& > *:first-child': {
      borderTopLeftRadius: shape.borderRadius,
      borderTopRightRadius: shape.borderRadius,
    },
    '& > *:last-child': {
      borderBottomLeftRadius: shape.borderRadius,
      borderBottomRightRadius: shape.borderRadius,
    },
  },
}), {name: 'ListUnitesOp'})(MuiList);

const ListItem = withStyles(({palette}: Theme) => ({
  root: {
    color : `${palette.action.active} !important`,
  },
}), {name: 'ListItem'})(MuiListItem);

const ListItemText = withStyles({
  primary: {
    fontWeight: 700,
  },
}, {name: 'ListItemText'})(MuiListItemText);

export interface ListUnitesOpBaseProps {
  to: (item: UniteOp) => LocationDescriptor<UniteOp>;
}

export type ListUnitesOpProps =
  & ListUnitesOpBaseProps
  & WithPropsFromState<UnitesOpListState>;

export function ListUnitesOp({items, loading, to}: ListUnitesOpProps) {
  if (loading) {
    return (<TextLoader />);
  }

  if (items.length === 0) {
    return (<span className="py-2">Aucune unité opérationnelle de créée.</span>)
  }

  return (
    <List>
      {items.map(item => {
        const {name: primary, services, users} = item;

        const countServices     = pluralize('service', services, true);
        const countUtilisateurs = pluralize('utilisateur', users, true);

        const secondary = `${countServices} | ${countUtilisateurs}`;

        return (
          <ListItem
            button
            component={props => (<Link to={to(item)} {...props} />)}
            key={item.id}
          >
            <ListItemIcon>
              <FolderIcon />
            </ListItemIcon>
            <ListItemText {...{primary, secondary}} />
          </ListItem>
        );
      })}
    </List>
  );
}

export default ListUnitesOp;
