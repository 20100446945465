import React from 'react';

import classNames from 'classnames';

import {
  Form,
  FormikProps,
  Field,
  FieldProps,
  getIn,
  WithFormikConfig,
} from 'formik';

import { isValid, isBefore } from 'date-fns';

import {
  createStyles,
  MenuItem as MuiMenuItem,
  withStyles,
  Button,
  FormControlLabel,
  Switch,
  WithStyles,
  CircularProgress,
} from '@material-ui/core';

import { MenuItemProps } from '@material-ui/core/MenuItem';

import {
  FormikInlineDatePicker,
  FormikSelect,
  TextLoader,
  FormikStatus,
  PageError,
} from '..';

import { UnitesOpListServicesState } from '../../store/reducers';
import { ButtonProps } from '@material-ui/core/Button';

const styles = createStyles({
  root: {
    '& > *': {
      whiteSpace: 'nowrap',
    },
  },
  picker: {
    flex: '0 0 150px',
  },
  noFlex: {
    flex: '0 0 auto',
  }
});

const MenuItem = withStyles(createStyles({
  root: {
    fontSize: 'inherit',
  },
  gutters: {
    padding: '4px 8px',
  },
}), {name: 'MenuItem'})(MuiMenuItem);

const GroupMenuItem = withStyles(({palette}) => createStyles({
  root: {
    opacity        : 1,
    fontWeight     : 700,
    fontSize       : 'inherit',
    backgroundColor: `${palette.common.white} !important`,
  },
}), {name: 'GroupMenuItem'})((props: MenuItemProps) => <MenuItem {...props} />);

GroupMenuItem.defaultProps = {
  disabled          : true,
  disableRipple     : true,
  disableTouchRipple: true,
}

const ChildMenuItem = withStyles(({typography}) => createStyles({
  root: {
    paddingLeft: typography.pxToRem(24),
    fontSize   : 'inherit',
  },
}), {name: 'ChildMenuItem'})(MenuItem);

type IdName = {id: number, name: string};

export const RoleFormValidate: WithFormikConfig<any, RoleFormValues>['validate'] = ({
  upper,
  no_end,
  service_id,
  lower,
}) => {
  let errors = {};

  // no service selected
  if (!service_id || service_id === -1) {
    errors = {...errors, service_id: 'Requis'};
  }

  // start date validation
  if (!lower) {
    errors = {...errors, lower: 'Requis'};
  } else if (!isValid(lower)) {
    errors = {...errors, lower: 'Date invalide'};
  }

  // end date required
  if (!no_end) {
    if (!upper) {
      errors = {...errors, upper: 'Requis'};
    } else if (!isValid(upper)) {
      errors = {...errors, upper: 'Date invalide'};
    } else if (isBefore(upper, lower)) {
      errors = {...errors, upper: 'Pas avant la date de début'};
    }
  }

  return errors;
};

export interface RoleFormValues {
  upper?    : Date;
  no_end    : boolean;
  service_id: number;
  lower     : Date;
}

export interface RoleFormBaseProps {
  CancelButtonProps?: ButtonProps;
  unitesOp          : UnitesOpListServicesState;
}

export type RoleFormProps =
  & RoleFormBaseProps
  & FormikProps<RoleFormValues>
  & WithStyles<typeof styles>;

export function RoleForm({
  // addRoleState,
  CancelButtonProps,
  classes,
  values,
  isSubmitting,
  unitesOp,
}: RoleFormProps) {
  const {items, loading} = unitesOp;

  const lower  = getIn(values, 'lower');
  const no_end = getIn(values, 'no_end');

  const renderItems = () => {
    return [
      <MenuItem disabled selected key="default" value={-1}>Sélectionnez un service</MenuItem>,
      ...items.filter(({services}) => services.length >= 1).map(({name: uo, services}, i) => ([
        <GroupMenuItem key={i}>{uo}</GroupMenuItem>,
        ...services.map(({id, name}) => (
          <ChildMenuItem
            key={`${i}_${id}`}
            value={id}
          >{name}</ChildMenuItem>
        )),
      ])),
    ];
  };

  const getServiceName = (serviceId: number) => items
    .reduce((pv, cv) => ([
      ...pv,
      ...cv.services.map(({id, name}) => ({id, name: `${cv.name} - ${name}`})),
    ]), [] as IdName[])
    .find(({id}) => id === serviceId);

  if (loading) {
    return (
      <div className="mt-2">
        <TextLoader />
      </div>
    );
  }

  return (
    <Form
      noValidate
    >
      <div className={classNames(classes.root, 'd-flex flex-wrap align-items-first-baseline justify-content-start')}>
        <FormikSelect
          defaultValue={-1}
          items={renderItems}
          name="service_id"
          required
          renderValue={value => {
            const service = getServiceName(value as number);
            return service ? service.name : "Sélectionnez un service";
          }}
          style={{width: 180}}
        />
        <span className="d-block mr-4 ml-3">Valable du</span>
        <FormikInlineDatePicker
          className={classes.picker}
          name="lower"
          required
        />
        <span className="d-block mr-3 ml-3">au</span>
        <FormikInlineDatePicker
          className={classes.picker}
          disabled={no_end}
          minDate={lower}
          name="upper"
          required={!no_end}
        />

        <Field
          name="no_end"
          render={({field}: FieldProps<boolean>) => {
            const {name, value: checked, onChange} = field;

            return (
              <FormControlLabel
                className={classNames(classes.noFlex, 'm-0 ml-3')}
                control={<Switch color="primary" />}
                label="Illimité"
                {...{name, checked, onChange}}
              />
            );
          }}
        />

        {isSubmitting ? (
          <CircularProgress className="ml-4" size={32} />
        ) : (
          <>
            {CancelButtonProps ? (
              <Button
                className={classNames(classes.noFlex, 'ml-4')}
                color="primary"
                type="button"
                variant="outlined"
                {...CancelButtonProps}
              >Annuler</Button>
            ) : null}
            <Button
              className={classNames(classes.noFlex, 'ml-4')}
              color="primary"
              type="submit"
              variant="contained"
            >Valider</Button>
          </>
        )}
      </div>

      <FormikStatus />
    </Form>
  );
}

export default withStyles(styles, {name: 'RoleForm'})(RoleForm);
