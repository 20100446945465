import { UsersDeleteAction } from '../actions';

import {
  USERS_DELETE_INIT,
  USERS_DELETE_SUCCESS,
  USERS_DELETE_FAILURE,
  USERS_DELETE_RESET,
} from '../constants';

export const getUsersDeleteStateToProps = <T extends UsersDeleteState>({
  error,
  loading,
  success,
}: Pick<T, keyof UsersDeleteState>) => ({error, loading, success});

export interface UsersDeleteState {
  error  : boolean;
  loading: boolean;
  success: boolean;
}

export const initialUsersDeleteState: UsersDeleteState = {
  error  : false,
  loading: false,
  success: false,
};

export default function (state: UsersDeleteState = initialUsersDeleteState, action: UsersDeleteAction): UsersDeleteState {
  switch (action.type) {
    case USERS_DELETE_INIT:
      return {
        ...initialUsersDeleteState,
        loading: true,
      };
    case USERS_DELETE_SUCCESS:
      return {
        error  : false,
        loading: false,
        success: true,
      };
    case USERS_DELETE_FAILURE:
      return {
        ...initialUsersDeleteState,
        error: true,
      };
    case USERS_DELETE_RESET:
      return {...initialUsersDeleteState};
    default:
      return state;
  }
}
