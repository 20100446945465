import React from 'react';

import {
  Button,
  CircularProgress,
  DialogActions,
} from '@material-ui/core';

import { ButtonProps } from '@material-ui/core/Button';

import { CancelButton } from '.';

export interface SimpleDialogActionsProps {
  CancelButtonProps: ButtonProps;
  isSubmitting     : boolean;
}

export function SimpleDialogActions({
  CancelButtonProps,
  isSubmitting,
}: SimpleDialogActionsProps) {
  return (
    <DialogActions>
      {isSubmitting ? (
        <CircularProgress size={32} />
      ) : (
        <>
          <CancelButton
            color="inherit"
            type="button"
            variant="text"
            {...CancelButtonProps}
          >Annuler</CancelButton>
          <Button
            color="primary"
            type="submit"
            variant="contained"
          >Valider</Button>
        </>
      )}
    </DialogActions>
  );
}

export default SimpleDialogActions;
