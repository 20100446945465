import { Epic, ofType } from 'redux-observable';

import { ReplaySubject } from 'rxjs';
import { catchError, concatMap, switchMap, tap } from 'rxjs/operators';

import Api from '../../configureApi';

import {
  Action,
  ServicesDeleteInitActionType,
  SERVICES_DELETE_SUCCESS_ACTION,
  SERVICES_DELETE_RESET_ACTION,
  SERVICES_DELETE_FAILURE_ACTION,
  UNITES_OP_LIST_IAT_RESET_ACTION,
  UNITES_OP_SINGLE_SUCCESS_ACTION,
  UNITES_OP_SINGLE_FAILURE_ACTION,
} from '../actions';

import { SERVICES_DELETE_INIT } from '../constants';

export const servicesDeleteEpic: Epic<ServicesDeleteInitActionType, any> = action$ => action$
  .pipe(
    ofType(SERVICES_DELETE_INIT),
    switchMap(({meta, payload: serviceId}: ServicesDeleteInitActionType) => {
      const stack$      = new ReplaySubject<Action>();
      const {uniteOpId} = meta;

      return Api.Services.deleteService(serviceId).pipe(
        tap(() => {
          stack$.next(SERVICES_DELETE_SUCCESS_ACTION());
          stack$.next(UNITES_OP_LIST_IAT_RESET_ACTION());
          stack$.next(SERVICES_DELETE_RESET_ACTION());
        }, () => {
          stack$.next(SERVICES_DELETE_FAILURE_ACTION());
          stack$.next(SERVICES_DELETE_RESET_ACTION());
          stack$.complete();
        }),
        concatMap(() => Api.Services.getUniteOp(uniteOpId).pipe(
          tap(item => {
            stack$.next(UNITES_OP_SINGLE_SUCCESS_ACTION(item));
            stack$.complete();
          }, () => {
            stack$.next(UNITES_OP_SINGLE_FAILURE_ACTION());
            stack$.complete();
          }),
        )),
        concatMap(() => stack$),
        catchError(() => stack$),
      );
    }),
  );

export default [
  servicesDeleteEpic,
];
