import React, { useState, useEffect } from 'react';

import {
  RouteComponentProps,
  withRouter,
} from 'react-router';

import {
  createStyles,
  List as MuiList,
  Paper as MuiPaper,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core';

import GroupWorkIcon from '@material-ui/icons/GroupWork';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';

import {
  Can,
  MenuItem,
} from '..';

/**
 * `AppLayoutMenu` styles.
 */
const styles = ({breakpoints}: Theme) => createStyles({
  root: {
    position: 'fixed',
    top: 56,
    left: 0,
    bottom: 0,
    width: 104,
    [breakpoints.up('sm')]: {
      top: 64,
    },
  },
});

/**
 * Override `MuiList` styles.
 */
const List = withStyles(createStyles({
  root: {
    width: '100%',
  }
}), {name: 'List'})(MuiList);

/**
 * Override `MuiPaper` styles.
 */
const Paper = withStyles(({palette}: Theme) => createStyles({
  root: {
    position     : 'relative',
    display      : 'flex',
    flexDirection: 'row',
    height       : '100%',
    padding      : 'var(--spacer-2) 0',
    borderRadius : 0,
    borderRight  : `1px solid ${palette.divider}`,
  }
}), {name: 'Paper'})(MuiPaper);

export interface AppLayoutMenuBaseProps {
  defaultActive: null | string;
}

export type AppLayoutMenuProps =
  & AppLayoutMenuBaseProps
  & RouteComponentProps<any>
  & WithStyles<typeof styles>;

export function AppLayoutMenu({
  classes,
  defaultActive,
  location,
}: AppLayoutMenuProps) {
  const [active, setActive] = useState(defaultActive);
  const {pathname}          = location;

  // listen for location change to update active `MenuItem`
  useEffect(() => {
    if (pathname.startsWith('/utilisateurs')) {
      setActive('utilisateurs');
    } else if (pathname.startsWith('/unites-operationnelles')) {
      setActive('unites-operationnelles');
    } else {
      setActive(null);
    }
  }, [location]);

  return (
    <div className={classes.root}>
      <Paper elevation={0}>
        <List component="nav">
          <Can I="view" a="Role">
            {() => (<MenuItem
              active={active === 'utilisateurs'}
              icon={SupervisedUserCircleIcon}
              primary="Utilisateurs"
              to="/utilisateurs"
            />)}
          </Can>

          <Can I="view" an="OU">
            {() => (<MenuItem
              active={active === 'unites-operationnelles'}
              icon={GroupWorkIcon}
              primary="Unités opérationnelles"
              to="/unites-operationnelles"
            />)}
          </Can>
        </List>
      </Paper>
    </div>
  );
}

AppLayoutMenu.defaultProps = {
  defaultActive: null,
} as AppLayoutMenuBaseProps;

const AppLayoutMenuStyled = withStyles(styles, {name: 'AppLayoutMenu'})(AppLayoutMenu);

export default withRouter(AppLayoutMenuStyled);
