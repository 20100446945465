import React from 'react';

import * as Yup from 'yup';
import classNames from 'classnames';

import {
  Form, getIn, Field, FieldProps, FormikProps,
} from 'formik';

import {
  DialogContent,
  DialogTitle,
  createStyles,
  withStyles,
  Switch,
} from '@material-ui/core';

import { TextFieldProps } from '@material-ui/core/TextField';

import {
  FormikTextField,
  SimpleDialogActions,
  FormikStatus,
  FormikInlineDatePicker,
  FormikSelect,
} from '..';

import { SimpleDialogActionsProps } from '../SimpleDialogActions';
import { LabelPickerFormControlLabel, MarginFormControlLabel, SwitchFormControlLabel } from '../FormControlLabel';
import { UserValidityFormValues, getUserValidityFormValues } from './UserValidityForm';

/**
 * Create an array of `length` items, with index as value.
 */
const fillHours = (length: number) => Array(length).fill(null).map((_, i) => i);

const FixedFormikInlineDatePicker = withStyles(createStyles({
  root: {
    maxWidth: 175,
  },
}), {name: 'FixedFormikInlineDatePicker'})(({
  classes,
  className,
  ...other
}: any) => (<FormikInlineDatePicker className={classNames(classes.root, className)} {...other} />));

export const getUserFormValues =  <T extends UserFormValues<true>>({
  email,
  first_name,
  last_name,
  phone,
  ...props
}: Pick<T, keyof UserFormValues<true>>) => ({
  email,
  first_name,
  last_name,
  phone,
  ...getUserValidityFormValues(props),
});

export const UserFormSchema = Yup.object().shape({
  first_name: Yup.string()
    .label('Prénom')
    .min(2)
    .max(64)
    .required()
    .trim(),
  last_name: Yup.string()
    .label('Nom')
    .min(2)
    .max(64)
    .required()
    .trim(),
  email: Yup.string()
    .label('Email')
    .email()
    .required()
    .trim(),
  phone: Yup.string()
    .label('Téléphone')
    .trim(),
});

export interface UserFormBaseValues {
  first_name: string;
  last_name : string;
  email     : string;
  phone     ?: string;
}

export type UserFormValues<T extends boolean = false> = T extends true
  ? UserFormBaseValues & UserValidityFormValues
  : UserFormBaseValues;

export interface UserFormBaseProps {
  title        : string;
  withValidity?: boolean;
}

export type UserFormProps =
  & UserFormBaseProps
  & SimpleDialogActionsProps
  & FormikProps<UserFormValues>;

export function UserForm({
  CancelButtonProps,
  isSubmitting,
  title,
  withValidity,
  ...other
}: UserFormProps) {
  const {values} = other;

  const startDay = getIn(values, 'start_day');
  const noLimit  = getIn(values, 'no_end');

  const textFieldProps = {
    autoComplete: 'off',
    disabled    : isSubmitting,
    fullWidth   : true,
    type        : 'text',
    variant     : 'standard',
  } as TextFieldProps;

  return (
    <>
      <Form noValidate>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent className="pt-3">
          <FormikTextField
            label="Nom"
            name="last_name"
            required={true}
            {...textFieldProps}
          />
          <div className="mt-3">
            <FormikTextField
              label="Prénom"
              name="first_name"
              required={true}
              {...textFieldProps}
            />
          </div>
          <div className="row">
            <div className="col-6 mt-3">
              <FormikTextField
                label="Email"
                name="email"
                required={true}
                {...textFieldProps}
              />
            </div>
            <div className="col-6 mt-3">
              <FormikTextField
                label="Téléphone"
                name="phone"
                placeholder="Aucun numéro de téléphone"
                {...textFieldProps}
              />
            </div>
          </div>
          {withValidity && (
            <>
              <div className="d-flex align-items-first-baseline mt-4">
                <LabelPickerFormControlLabel
                  className="m-0"
                  label="Date de début:"
                  labelPlacement="start"
                  labelPosition="left"
                  control={<FixedFormikInlineDatePicker
                    className="ml-3"
                    InputProps={{style: {maxWidth: 175}}}
                    name="start_day"
                  />}
                />

                <MarginFormControlLabel
                  className="m-0"
                  label="à partir de"
                  labelPlacement="start"
                  control={<FormikSelect
                    items={fillHours(24)}
                    name="start_hours"
                    renderItem={(value: number) => `${value.toString().padStart(2, '0')}:00`}
                    style={{width: 75}}
                  />}
                />
              </div>
              <div className="d-flex align-items-first-baseline mt-4">
                <LabelPickerFormControlLabel
                  className="m-0"
                  disabled={noLimit}
                  label="Date de fin:"
                  labelPlacement="start"
                  control={<FixedFormikInlineDatePicker
                    className="ml-3"
                    InputProps={{style: {maxWidth: 175}}}
                    minDate={!noLimit ? startDay : undefined}
                    name="end_day"
                  />}
                />

                <MarginFormControlLabel
                  className="m-0"
                  disabled={noLimit}
                  label="à partir de"
                  labelPlacement="start"
                  htmlFor="end_hours"
                  control={<FormikSelect
                    id="end_hours"
                    items={fillHours(24)}
                    name="end_hours"
                    renderItem={(value: number) => `${value.toString().padStart(2, '0')}:00`}
                    style={{width: 75}}
                  />}
                />
                <Field
                  name="no_end"
                  render={({field}: FieldProps<boolean>) => {
                    const {name, value: checked, onChange} = field;

                    return (
                      <SwitchFormControlLabel
                        className="m-0"
                        control={<Switch color="primary" />}
                        label="Illimité"
                        {...{name, checked, onChange}}
                      />
                    );
                  }}
                />
              </div>
            </>
          )}
        </DialogContent>

        <SimpleDialogActions {...{CancelButtonProps, isSubmitting}} />

        <FormikStatus />
      </Form>
    </>
  )
}

UserForm.defaultProps = {
  withValidity: false,
} as UserFormProps;

export default UserForm;
