import {
  ActionType,
  createStandardAction,
} from 'typesafe-actions';

import { FormikActions } from 'formik';

import {
  UNITES_OP_ADD_INIT,
  UNITES_OP_ADD_SUCCESS,
  UNITES_OP_ADD_FAILURE,
  UNITES_OP_ADD_RESET,
} from '../constants';

import {
  UniteOpCreateRequest,
  UniteOpCreateResponse,
} from '../../api/services';

import { GenericNameFieldFormValues } from '../../components/Form/GenericNameFieldForm';

export const UNITES_OP_ADD_INIT_ACTION = createStandardAction(UNITES_OP_ADD_INIT)<UniteOpCreateRequest, {
  actions: FormikActions<GenericNameFieldFormValues>,
}>();
export type UnitesOpAddInitActionType = ActionType<typeof UNITES_OP_ADD_INIT_ACTION>;

export const UNITES_OP_ADD_SUCCESS_ACTION = createStandardAction(UNITES_OP_ADD_SUCCESS)<UniteOpCreateResponse>();
export type UnitesOpAddSuccessActionType  = ActionType<typeof UNITES_OP_ADD_SUCCESS_ACTION>;

export const UNITES_OP_ADD_FAILURE_ACTION = createStandardAction(UNITES_OP_ADD_FAILURE)();
export type UnitesOpAddFailureActionType  = ActionType<typeof UNITES_OP_ADD_FAILURE_ACTION>;

export const UNITES_OP_ADD_RESET_ACTION = createStandardAction(UNITES_OP_ADD_RESET)();
export type UnitesOpAddResetActionType  = ActionType<typeof UNITES_OP_ADD_RESET_ACTION>;

export type UnitesOpAddAction =
  | UnitesOpAddInitActionType
  | UnitesOpAddSuccessActionType
  | UnitesOpAddFailureActionType
  | UnitesOpAddResetActionType;

export default {
  UNITES_OP_ADD_INIT_ACTION,
  UNITES_OP_ADD_SUCCESS_ACTION,
  UNITES_OP_ADD_FAILURE_ACTION,
  UNITES_OP_ADD_RESET_ACTION,
};
