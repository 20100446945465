import {
  ActionType,
  createStandardAction,
} from 'typesafe-actions';

import { FormikActions } from 'formik';

import {
  SERVICES_ADD_INIT,
  SERVICES_ADD_SUCCESS,
  SERVICES_ADD_FAILURE,
  SERVICES_ADD_RESET,
} from '../constants';

import {
  ServicesCreateRequest,
  ServicesCreateResponse,
} from '../../api/services';

import { GenericNameFieldFormValues } from '../../components/Form/GenericNameFieldForm';

export const SERVICES_ADD_INIT_ACTION = createStandardAction(SERVICES_ADD_INIT)<ServicesCreateRequest, {
  uniteOpId: number,
  actions  : FormikActions<GenericNameFieldFormValues>,
}>();
export type ServicesAddInitActionType = ActionType<typeof SERVICES_ADD_INIT_ACTION>;

export const SERVICES_ADD_SUCCESS_ACTION = createStandardAction(SERVICES_ADD_SUCCESS)<ServicesCreateResponse>();
export type ServicesAddSuccessActionType  = ActionType<typeof SERVICES_ADD_SUCCESS_ACTION>;

export const SERVICES_ADD_FAILURE_ACTION = createStandardAction(SERVICES_ADD_FAILURE)();
export type ServicesAddFailureActionType  = ActionType<typeof SERVICES_ADD_FAILURE_ACTION>;

export const SERVICES_ADD_RESET_ACTION = createStandardAction(SERVICES_ADD_RESET)();
export type ServicesAddResetActionType  = ActionType<typeof SERVICES_ADD_RESET_ACTION>;

export type ServicesAddAction =
  | ServicesAddInitActionType
  | ServicesAddSuccessActionType
  | ServicesAddFailureActionType
  | ServicesAddResetActionType;

export default {
  SERVICES_ADD_INIT_ACTION,
  SERVICES_ADD_SUCCESS_ACTION,
  SERVICES_ADD_FAILURE_ACTION,
  SERVICES_ADD_RESET_ACTION,
};
