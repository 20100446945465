import React from 'react';

import {
  AppBar as MuiAppBar,
  createStyles,
  Toolbar,
  Theme,
  withStyles,
} from '@material-ui/core';

import {
  ToolbarActions,
  ToolbarLogo,
} from '..';

const AppBar = withStyles(({palette}: Theme) => createStyles({
  root: {
    borderBottom: `1px solid ${palette.divider}`,
  },
}))(MuiAppBar);

export interface AppLayoutHeaderBaseProps {
  headerLogo : string;
  headerTitle: string;
}

export type AppLayoutHeaderProps =
  & AppLayoutHeaderBaseProps;

export function AppLayoutHeader({
  headerLogo,
  headerTitle,
}: AppLayoutHeaderProps) {
  return (
    <AppBar
      color="primary"
      elevation={0}
      position="fixed"
    >
      <Toolbar>
        <ToolbarLogo
          alt={headerTitle}
          src={headerLogo}
        />

        <div className="d-flex flex-1 justify-content-end">
          <ToolbarActions />
        </div>
      </Toolbar>
    </AppBar>
  );
}

AppLayoutHeader.defaultProps = {
  headerLogo : process.env.REACT_APP_SOFTWARE_LOGO_URL,
  headerTitle: process.env.REACT_APP_SOFTWARE_TITLE,
} as AppLayoutHeaderBaseProps;

export default AppLayoutHeader;
