import React from 'react';

import classNames from 'classnames';

import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core';

import VerticalDivider from './VerticalDivider';

interface PageTitleBaseProps {
  className?: string;
  hr        : boolean;
  primary?  : string;
  secondary?: string;
  size      : 'normal' | 'small' | 'large';
}

type PageTitleProps =
  & PageTitleBaseProps
  & WithStyles<typeof styles>;

const styles = ({typography}: Theme) => createStyles({
  root: {},
  container: {
    display      : 'flex',
    flexDirection: 'row',
    alignItems   : 'center',
  },
  divider: {
    display: 'inline-block',
    margin : `0 1em`,
    '&$normal': {
      height: typography.pxToRem(24),
    },
    '&$small': {
      height: typography.pxToRem(18),
    },
  },
  text: {
    whiteSpace  : 'nowrap',
    overflow    : 'hidden',
    textOverflow: 'ellipsis',
    '&$normal': {
      fontSize: typography.pxToRem(24),
    },
    '&$small': {
      fontSize: typography.pxToRem(18),
    },
  },
  textPrimary: {
  },
  textSecondary: {
    flex: '1',
  },
  large: {},
  normal: {},
  small: {},
});

const verticalDividerStyles = createStyles({
  root: {
    height: '100%',
  },
});

const VerticalDividerStyled = ({classes}: WithStyles<typeof verticalDividerStyles>) => <VerticalDivider className={classes.root} />;
const CustomVerticalDivider = withStyles(verticalDividerStyles, {name: 'CustomVerticalDivider'})(VerticalDividerStyled);

export const PageTitle = ({classes, className, hr, primary, secondary, size}: PageTitleProps) => (
  <div className={classNames(classes.root, className)}>
    <div className={classes.container}>
      {primary ? <h1 className={classNames(classes.text, classes.textPrimary, classes[size])}>{primary}</h1> : null}
      {primary && secondary ? <div className={classNames(classes.divider, classes[size])}><CustomVerticalDivider /></div> : null}
      {secondary ? <h2 className={classNames(classes.text, classes.textSecondary, classes[size])}>{secondary}</h2> : null}
    </div>
    {hr ? <hr/> : null}
  </div>
);

PageTitle.defaultProps = {
  hr  : true,
  size: 'normal',
} as PageTitleBaseProps;

export default withStyles(styles, {name: 'PageTitle'})(PageTitle);
