import { ServicesDeleteAction } from '../actions';

import {
  SERVICES_DELETE_INIT,
  SERVICES_DELETE_SUCCESS,
  SERVICES_DELETE_FAILURE,
  SERVICES_DELETE_RESET,
} from '../constants';

export const getServicesDeleteStateToProps = <T extends ServicesDeleteState>({
  error,
  loading,
  success,
}: Pick<T, keyof ServicesDeleteState>) => ({error, loading, success});

export interface ServicesDeleteState {
  error  : boolean;
  loading: boolean;
  success: boolean;
}

export const initialServicesDeleteState: ServicesDeleteState = {
  error  : false,
  loading: false,
  success: false,
};

export default function (state: ServicesDeleteState = initialServicesDeleteState, action: ServicesDeleteAction): ServicesDeleteState {
  switch (action.type) {
    case SERVICES_DELETE_INIT:
      return {
        ...initialServicesDeleteState,
        loading: true,
      };
    case SERVICES_DELETE_SUCCESS:
      return {
        error  : false,
        loading: false,
        success: true,
      };
    case SERVICES_DELETE_FAILURE:
      return {
        ...initialServicesDeleteState,
        error: true,
      };
    case SERVICES_DELETE_RESET:
      return {...initialServicesDeleteState};
    default:
      return state;
  }
}
