import { combineReducers } from 'redux';

import add, { initialUsersAddState, UsersAddState } from './users-add-reducer';
import addGroups, { initialUsersAddGroupsState, UsersAddGroupsState } from './users-add_groups-reducer';
import remove, { initialUsersDeleteState, UsersDeleteState } from './users-delete-reducer';
import edit, { initialUsersEditState, UsersEditState } from './users-edit-reducer';
import editValidity, { initialUsersEditValidityState, UsersEditValidityState } from './users-edit_validity-reducer';
import editActive, { initialUsersEditActiveState, UsersEditActiveState } from './users-edit_active-reducer';
import list, { initialUsersListState, UsersListState } from './users-list-reducer';
import mail, { initialUsersMailState, UsersMailState } from './users-mail-reducer';
import single, { initialUsersSingleState, UsersSingleState } from './users-single-reducer';

/**
 * To delete an Unité Opérationnelle,
 * use the key `remove` because `delete` is a reserved word.
 */
export type UsersState =
  & {add: UsersAddState}
  & {addGroups: UsersAddGroupsState}
  & {remove: UsersDeleteState}
  & {edit: UsersEditState}
  & {editValidity: UsersEditValidityState}
  & {editActive: UsersEditActiveState}
  & {list: UsersListState}
  & {mail: UsersMailState}
  & {single: UsersSingleState};

export const initialUsersState: UsersState = {
  add         : {...initialUsersAddState},
  addGroups   : {...initialUsersAddGroupsState},
  remove      : {...initialUsersDeleteState},
  edit        : {...initialUsersEditState},
  editValidity: {...initialUsersEditValidityState},
  editActive  : {...initialUsersEditActiveState},
  list        : {...initialUsersListState},
  mail        : {...initialUsersMailState},
  single      : {...initialUsersSingleState},
};

export default combineReducers<UsersState>({
  add,
  addGroups,
  remove,
  edit,
  editValidity,
  editActive,
  list,
  mail,
  single,
});
