import React from 'react';

import {
  Redirect,
  Route,
  Switch,
  RouteComponentProps,
} from 'react-router';

import {
  AppLayout,
  CanRoute,
} from '../components';

import {
  UnitesOpRouter,
  UsersRouter,
} from '.';

export type AppRouterProps =
  & RouteComponentProps<any>;

/**
 * `AppRouter` is the primary router for all sub-routes in the app.
 */
export function AppRouter(props: AppRouterProps) {
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => <Redirect to="/utilisateurs"  />}
      />

      <CanRoute
        I="view"
        a="Role"
        path="/utilisateurs"
        render={routerProps => <UsersRouter {...props} {...routerProps} />}
      />

      <CanRoute
        I="view"
        an="OU"
        path="/unites-operationnelles"
        render={routerProps => <UnitesOpRouter {...props} {...routerProps} />}
      />
    </Switch>
  );
}

export default function () {
  return <AppLayout
    path="/"
    component={AppRouter}
  />;
};
