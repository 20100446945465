import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import {
  withFormik,
} from 'formik';

import { Action, USERS_ADD_INIT_ACTION } from '../../store/actions';

import {
  UserForm,
} from '../../components';

import {
  UserFormBaseProps,
  UserFormValues,
  UserFormSchema,
} from '../../components/Form/UserForm';

import { SimpleDialogActionsProps } from '../../components/SimpleDialogActions';
import { startOfHour, getHours, startOfDay } from 'date-fns';
import { UserValidityFormValidate } from '../../components/Form/UserValidityForm';
import { addHours } from 'date-fns/esm';
import { UsersCreateRequest } from '../../api';

export interface UsersAddDialogBaseProps {
  addUser: typeof USERS_ADD_INIT_ACTION;
}

export type UsersAddDialogProps =
  & UsersAddDialogBaseProps
  & UserFormBaseProps
  & Pick<SimpleDialogActionsProps, 'CancelButtonProps'>;

const mapDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
  addUser: USERS_ADD_INIT_ACTION,
}, dispatch);

export default connect(null, mapDispatchToProps)(withFormik<UsersAddDialogProps, UserFormValues<true>>({
  validate        : UserValidityFormValidate,
  validationSchema: UserFormSchema,
  mapPropsToValues: () => {
    const now = Date.now();

    const start_day   = startOfDay(now);
    const start_hours = getHours(now);

    return {
      first_name: '',
      last_name : '',
      email     : '',
      phone     : '',
      no_end    : true,
      start_day,
      start_hours,
    };
  },
  handleSubmit: (values, {props, ...actions}) => {
    const {
      phone,
      start_day,
      start_hours,
      end_day,
      end_hours,
      no_end,
      ...other
    } = values;

    let payload = {
      ...other,
      phone: ('phone' in values && !!phone) ? phone: null,
      validation: {
        lower: addHours(start_day, start_hours).toISOString(),
        upper: null,
      },
    } as UsersCreateRequest;

    if (!no_end) {
      const validation = {
        ...payload.validation,
        upper: addHours(end_day as Date, end_hours as number).toISOString(),
      };

      payload = {...payload, validation};
    }

    props.addUser(payload, {actions});
  },
})(UserForm));
