import {
  ActionType,
  createStandardAction,
} from "typesafe-actions";

import {
  UNITES_OP_LIST_INIT,
  UNITES_OP_LIST_SUCCESS,
  UNITES_OP_LIST_FAILURE,
  UNITES_OP_LIST_IAT,
  UNITES_OP_LIST_IAT_RESET,
  UNITES_OP_LIST_SERVICES_INIT,
  UNITES_OP_LIST_SERVICES_SUCCESS,
  UNITES_OP_LIST_SERVICES_FAILURE,
} from '../constants';

import { UnitesOpListResponse, UnitesOpWithServices } from '../../api/services';

export const UNITES_OP_LIST_INIT_ACTION = createStandardAction(UNITES_OP_LIST_INIT)();
export type UnitesOpListInitActionType = ActionType<typeof UNITES_OP_LIST_INIT_ACTION>;

export const UNITES_OP_LIST_SUCCESS_ACTION = createStandardAction(UNITES_OP_LIST_SUCCESS)<UnitesOpListResponse>();
export type UnitesOpListSuccessActionType = ActionType<typeof UNITES_OP_LIST_SUCCESS_ACTION>;

export const UNITES_OP_LIST_FAILURE_ACTION = createStandardAction(UNITES_OP_LIST_FAILURE)();
export type UnitesOpListFailureActionType = ActionType<typeof UNITES_OP_LIST_FAILURE_ACTION>;

export const UNITES_OP_LIST_IAT_ACTION = createStandardAction(UNITES_OP_LIST_IAT)();
export type UnitesOpListIatActionType = ActionType<typeof UNITES_OP_LIST_IAT_ACTION>;

export const UNITES_OP_LIST_IAT_RESET_ACTION = createStandardAction(UNITES_OP_LIST_IAT_RESET)();
export type UnitesOpListIatResetActionType = ActionType<typeof UNITES_OP_LIST_IAT_RESET_ACTION>;

export type UnitesOpListAction =
  | UnitesOpListInitActionType
  | UnitesOpListSuccessActionType
  | UnitesOpListFailureActionType
  | UnitesOpListIatActionType
  | UnitesOpListIatResetActionType;


export const UNITES_OP_LIST_SERVICES_INIT_ACTION = createStandardAction(UNITES_OP_LIST_SERVICES_INIT)();
export type UnitesOpListServicesInitActionType = ActionType<typeof UNITES_OP_LIST_SERVICES_INIT_ACTION>;

export const UNITES_OP_LIST_SERVICES_SUCCESS_ACTION = createStandardAction(UNITES_OP_LIST_SERVICES_SUCCESS)<UnitesOpWithServices>();
export type UnitesOpListServicesSuccessActionType = ActionType<typeof UNITES_OP_LIST_SERVICES_SUCCESS_ACTION>;

export const UNITES_OP_LIST_SERVICES_FAILURE_ACTION = createStandardAction(UNITES_OP_LIST_SERVICES_FAILURE)();
export type UnitesOpListServicesFailureActionType = ActionType<typeof UNITES_OP_LIST_SERVICES_FAILURE_ACTION>;

export type UnitesOpListServicesAction =
  | UnitesOpListServicesInitActionType
  | UnitesOpListServicesSuccessActionType
  | UnitesOpListServicesFailureActionType;
  // | UnitesOpListIatResetActionType;

export default {
  UNITES_OP_LIST_INIT_ACTION,
  UNITES_OP_LIST_SUCCESS_ACTION,
  UNITES_OP_LIST_FAILURE_ACTION,
  UNITES_OP_LIST_IAT_ACTION,
  UNITES_OP_LIST_IAT_RESET_ACTION,
  UNITES_OP_LIST_SERVICES_INIT_ACTION,
  UNITES_OP_LIST_SERVICES_SUCCESS_ACTION,
  UNITES_OP_LIST_SERVICES_FAILURE_ACTION,
};
