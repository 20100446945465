import { Service } from '../../api/services';
import { ServicesEditAction } from '../actions';

import {
  SERVICES_EDIT_INIT,
  SERVICES_EDIT_SUCCESS,
  SERVICES_EDIT_FAILURE,
  SERVICES_EDIT_RESET,
} from '../constants';

export const getServicesEditStateToProps = <T extends ServicesEditState>({
  diff,
  error,
  item,
  loading,
}: Pick<T, keyof ServicesEditState>) => ({diff, error, item, loading});

export interface ServicesEditState {
  diff   : boolean;
  error  : boolean;
  item   : null | Service;
  loading: boolean;
}

export const initialServicesEditState: ServicesEditState = {
  diff   : false,
  error  : false,
  item   : null,
  loading: false,
};

export default function (state: ServicesEditState = initialServicesEditState, action: ServicesEditAction): ServicesEditState {
  switch (action.type) {
    case SERVICES_EDIT_INIT:
      return {
        ...initialServicesEditState,
        loading: true,
      };
    case SERVICES_EDIT_SUCCESS:
      return {
        diff   : action.meta.diff,
        error  : false,
        item   : {...action.payload},
        loading: false,
      };
    case SERVICES_EDIT_FAILURE:
      return {
        ...initialServicesEditState,
        error: true,
      };
    case SERVICES_EDIT_RESET:
      return {...initialServicesEditState};
    default:
      return state;
  }
}
