import React, { useState } from 'react';

import { connect } from 'react-redux';

import {
  ButtonBase as MuiButtonBase,
  createStyles,
  IconButton,
  ListItemText,
  Menu,
  MenuItem as MuiMenuItem,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core';

import { ListItemTextProps } from '@material-ui/core/ListItemText';

import AppsIcon from '@material-ui/icons/Apps';

import {
  Avatar,
  VerticalDivider,
} from '.';

import { AvatarProps } from './Avatar';

import { RootState } from '../store/reducers';

const verticalDividerStyles = createStyles({
  root: {
    height     : 35,
    marginRight: 8,
    marginLeft : 20,
  },
});

const VerticalDividerStyled = ({classes}: WithStyles<typeof verticalDividerStyles>) => <VerticalDivider className={classes.root} />;
const CustomVerticalDivider = withStyles(verticalDividerStyles, {name: 'CustomVerticalDivider'})(VerticalDividerStyled);

const ButtonBase = withStyles(({shape}: Theme) => createStyles({
  root: {
    padding      : 'var(--spacer-1) var(--spacer-1) var(--spacer-1) var(--spacer-2)',
    display      : 'flex',
    flexDirection: 'row',
    alignItems   : 'center',
    borderRadius : shape.borderRadius,
  },
}), {name: 'ButtonBase'})(MuiButtonBase);

const MenuItem = withStyles(({palette}: Theme) => createStyles({
  root: {
    // add !important flag for the ripple backgroundColor (ripple uses 'currentColor' internaly)
    color: `${palette.action.active} !important`,
  },
}), {name: 'MenuItem'})(MuiMenuItem);

type MenuItemProps =
  & {href: string}
  & ListItemTextProps;

function createMenuItem(href: string, options: ListItemTextProps): MenuItemProps {
  return {href, ...options};
}

export interface ToolbarProps {
  AvatarProps? : AvatarProps;
  appsMenuItems: MenuItemProps[];
  userMenuItems: MenuItemProps[];
}

export function ToolbarActions({AvatarProps, appsMenuItems, userMenuItems}: ToolbarProps) {
  const [userMenuAnchor, setUserMenuAnchor] = useState<null | HTMLElement>(null);
  const [userAppsAnchor, setAppsMenuAnchor] = useState<null | HTMLElement>(null);

  return (
    <>
      {AvatarProps && (
        <div className="d-flex flex-row align-items-center">
          <ButtonBase
            onClick={({currentTarget}) => setUserMenuAnchor(currentTarget)}
          >
            <strong className="mr-2">{AvatarProps.alt}</strong>
            <Avatar {...AvatarProps}>{AvatarProps.alt}</Avatar>
          </ButtonBase>

          <CustomVerticalDivider />

          <IconButton
            onClick={({currentTarget}) => setAppsMenuAnchor(currentTarget)}
          >
            <AppsIcon />
          </IconButton>

          <Menu
            anchorEl={userMenuAnchor}
            anchorOrigin={{
              vertical  : 'bottom',
              horizontal: 'right',
            }}
            disableAutoFocusItem
            getContentAnchorEl={null}
            open={Boolean(userMenuAnchor)}
            onClose={() => setUserMenuAnchor(null)}
          >
            {userMenuItems.map(({href, ...other}, key) => (
              <MenuItem
                component="a"
                {...{key, href}}
              >
                <ListItemText {...other} />
              </MenuItem>
            ))}
          </Menu>

          <Menu
            anchorEl={userAppsAnchor}
            anchorOrigin={{
              vertical  : 'bottom',
              horizontal: 'left',
            }}
            disableAutoFocusItem
            getContentAnchorEl={null}
            open={Boolean(userAppsAnchor)}
            onClose={() => setAppsMenuAnchor(null)}
          >
            {appsMenuItems.map(({href, ...other}, key) => (
              <MenuItem
                component="a"
                {...{key, href}}
              >
                <ListItemText {...other} />
              </MenuItem>
            ))}
          </Menu>
        </div>
      )}
    </>
  );
}

ToolbarActions.defaultProps = {
  userMenuItems: [
    createMenuItem(process.env.REACT_APP_ACCOUNT_CHANGE_PASSWORD_URL, {
      primary: 'Réinitialiser mon mot de passe',
    }),
    createMenuItem(process.env.REACT_APP_ACCOUNT_LOGOUT_URL, {
      primary: 'Se déconnecter',
    }),
  ],
};

const mapStateToProps = ({user}: RootState) => ({
  AvatarProps: !!user ? {
    alt: `${user.first_name} ${user.last_name}`,
    src: user.avatar || undefined,
  } : undefined,
  appsMenuItems: !!user ? user.applications
    .map(({description: secondary, name: primary, redirect_url}) => createMenuItem(redirect_url, {primary, secondary})) : [],
});

export default connect(mapStateToProps)(ToolbarActions);
