import {
  ActionType,
  createStandardAction,
} from "typesafe-actions";

import {
  USERS_LIST_INIT,
  USERS_LIST_SUCCESS,
  USERS_LIST_FAILURE,
  USERS_LIST_IAT,
  USERS_LIST_IAT_RESET,
} from '../constants';

import { UsersListResponse } from '../../api/services';

export const USERS_LIST_INIT_ACTION = createStandardAction(USERS_LIST_INIT)();
export type UsersListInitActionType = ActionType<typeof USERS_LIST_INIT_ACTION>;

export const USERS_LIST_SUCCESS_ACTION = createStandardAction(USERS_LIST_SUCCESS)<UsersListResponse>();
export type UsersListSuccessActionType = ActionType<typeof USERS_LIST_SUCCESS_ACTION>;

export const USERS_LIST_FAILURE_ACTION = createStandardAction(USERS_LIST_FAILURE)();
export type UsersListFailureActionType = ActionType<typeof USERS_LIST_FAILURE_ACTION>;

export const USERS_LIST_IAT_ACTION = createStandardAction(USERS_LIST_IAT)();
export type UsersListIatActionType = ActionType<typeof USERS_LIST_IAT_ACTION>;

export const USERS_LIST_IAT_RESET_ACTION = createStandardAction(USERS_LIST_IAT_RESET)();
export type UsersListIatResetActionType = ActionType<typeof USERS_LIST_IAT_RESET_ACTION>;

export type UsersListAction =
  | UsersListInitActionType
  | UsersListSuccessActionType
  | UsersListFailureActionType
  | UsersListIatActionType
  | UsersListIatResetActionType;

export default {
  USERS_LIST_INIT_ACTION,
  USERS_LIST_SUCCESS_ACTION,
  USERS_LIST_FAILURE_ACTION,
  USERS_LIST_IAT_ACTION,
  USERS_LIST_IAT_RESET_ACTION,
};
