import React, { useState } from 'react';

import { format, parseISO } from 'date-fns';

import {
  // Button,
  createStyles,
  ExpansionPanel as MuiExpansionPanel,
  // ExpansionPanelActions,
  ExpansionPanelDetails,
  ExpansionPanelSummary as MuiExpansionPanelSummary,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  withStyles,
} from '@material-ui/core';

import { ExpansionPanelProps } from '@material-ui/core/ExpansionPanel';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import pluralize from '../../pluralize';

import {
  Can,
  PageTitle,
  Avatar,
} from '..';

import { Service } from '../../api/services';
import { Link } from 'react-router-dom';

const ExpansionPanel = withStyles(({shape}: Theme) => createStyles({
  rounded: {
    '&:first-child > :first-child': {
      borderTopLeftRadius : shape.borderRadius,
      borderTopRightRadius: shape.borderRadius,
    },
    '&:last-child > :first-child': {
      borderBottomLeftRadius : shape.borderRadius,
      borderBottomRightRadius: shape.borderRadius,
    },
  },
  expanded: {
    '&:last-child > :first-child': {
      borderRadius: '0 !important',
    },
  },
}), {name: 'MuiExpansionPanel'})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles(createStyles({
  content: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '40px !important',
    '& > :last-child': {
      paddingRight: 0,
    },
  },
  expandIcon: {
    right: 'unset',
    left : 8,
  },
}), {name: 'ExpansionPanelSummary'})(MuiExpansionPanelSummary);

export interface ListServicesBaseProps {
  onDeleteClick: (service: Service) => void;
  onRenameClick: (service: Service) => void;
  service      : Service;
}

export type ListServicesProps =
  & ListServicesBaseProps
  & ExpansionPanelProps;

export function ListServices({
  onDeleteClick,
  onRenameClick,
  service,
  ...other
}: ListServicesProps) {
  const [menu, setMenu] = useState<any>(null);
  const {roles}         = service;

  return (
    <>
      <ExpansionPanel {...other}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon  />}
        >
          <PageTitle
            className="flex-1"
            hr={false}
            primary={service.name}
            secondary={pluralize('utilisateur actif', roles.length, true)}
            size="small"
          />

          <Can I="changeOrDelete" a="Service">
            {() => (
              <div>
                <IconButton
                  onClick={e => {
                    e.stopPropagation();
                    setMenu(e.currentTarget);
                  }}
                  type="button"
                >
                  <MoreVertIcon fontSize="small" />
                </IconButton>
              </div>
            )}
          </Can>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {roles.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nom</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Téléphone</TableCell>
                  <TableCell>Début de validité</TableCell>
                  <TableCell>Fin de validité</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {roles.map(role => {
                  const displayName = `${role.user.first_name} ${role.user.last_name}`;

                  return (
                    <TableRow key={role.id}>
                      <TableCell className="py-2">
                      <Link to={{
                        pathname: `/utilisateurs/${role.user.id}`,
                        state   : {avatar: role.user.avatar, first_name: role.user.first_name, last_name: role.user.last_name},
                      }}>
                        <div className="d-flex align-items-center justify-content-start">
                          <Avatar
                            alt={displayName}
                          >{displayName}</Avatar>
                          <strong className="ml-2">{displayName}</strong>
                        </div>
                      </Link>
                      </TableCell>
                      <TableCell className="py-2">
                        {role.user.email}
                      </TableCell>
                      <TableCell className="py-2">
                        {!!role.user.phone ? (
                          <>
                            {role.user.phone}
                          </>
                        ) : (
                          <>-</>
                        )}
                      </TableCell>
                      <TableCell className="py-2">
                        {!!role.period.lower ? (
                          <>
                            {format(parseISO(role.period.lower), 'dd/MM/yyyy')}
                          </>
                        ) : (
                          <>-</>
                        )}
                      </TableCell>
                      <TableCell className="py-2">
                        {!!role.period.upper ? (
                          <>
                            {format(parseISO(role.period.upper), 'dd/MM/yyyy')}
                          </>
                        ) : (
                          <>Illimité</>
                        )}
                      </TableCell>
                    </TableRow>
                )})}
              </TableBody>
            </Table>
          ) : (
            <span>Aucun utilisateur dans ce service.</span>
          )}
        </ExpansionPanelDetails>
        {/* <ExpansionPanelActions className="justify-content-start">
          <Button
            color="primary"
            variant="contained"
          >Ajouter un utilisateur</Button>
        </ExpansionPanelActions> */}
      </ExpansionPanel>

      <Menu
        anchorEl={menu}
        disableAutoFocusItem
        open={Boolean(menu)}
        onClose={() => setMenu(null)}
      >
        <Can I="change" a="Service">
          {() => (
            <MenuItem
              button
              onClick={() => {
                onRenameClick(service);
                setMenu(null);
              }}
            >Renommer</MenuItem>
          )}
        </Can>
        <Can I="delete" a="Service">
          {() => (
            <MenuItem
              button
              disabled={roles.length >= 1}
              onClick={() => {
                onDeleteClick(service);
                setMenu(null);
              }}
            >Supprimer</MenuItem>
          )}
        </Can>
      </Menu>
    </>
  );
}

export default ListServices;
