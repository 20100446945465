import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import {
  withFormik,
} from 'formik';

import { getHours, parseISO, addHours } from 'date-fns';

import { UserWithGroups, UsersUpdateValidityRequest } from '../../api';
import { Action, USERS_EDIT_VALIDITY_INIT_ACTION } from '../../store/actions';

import {
  UserValidityForm,
} from '../../components';

import {
  UserValidityFormValues,
  UserValidityFormValidate,
} from '../../components/Form/UserValidityForm';

import { SimpleDialogActionsProps } from '../../components/SimpleDialogActions';
import { startOfDay } from 'date-fns/esm';

export interface UsersEditDialogBaseProps {
  user      : UserWithGroups;
  updateUser: typeof USERS_EDIT_VALIDITY_INIT_ACTION;
}

export type UsersEditDialogProps =
  & UsersEditDialogBaseProps
  & Pick<SimpleDialogActionsProps, 'CancelButtonProps'>;

const mapDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
  updateUser: USERS_EDIT_VALIDITY_INIT_ACTION,
}, dispatch);

export default connect(null, mapDispatchToProps)(withFormik<UsersEditDialogProps, UserValidityFormValues>({
  validate        : UserValidityFormValidate,
  mapPropsToValues: ({user}) => {
    const {
      lower,
      upper,
    } = user.validation;

    const no_end = !upper;

    const start_day   = parseISO(lower);
    const start_hours = getHours(start_day);

    const end_day   = !!upper ? parseISO(upper) : undefined;
    const end_hours = end_day ? getHours(end_day) : undefined;

    return {
      start_day: startOfDay(start_day),
      start_hours,
      end_day: end_day ? startOfDay(end_day) : end_day,
      end_hours,
      no_end,
    };
  },
  handleSubmit: ({start_day, start_hours, end_day, end_hours, no_end}, {props, ...actions}) => {
    const {updateUser, user: previous} = props;

    let payload = {
      validation: {
        lower: addHours(start_day, start_hours).toISOString(),
        upper: null,
      },
    } as UsersUpdateValidityRequest;

    if (!no_end) {
      const validation = {
        ...payload.validation,
        upper: addHours(end_day as Date, end_hours as number).toISOString()
      };

      payload = {...payload, validation};
    }

    updateUser(payload, {actions, previous});
  },
})(UserValidityForm));
