import React, { useState, useEffect, useContext } from 'react';

import classNames from 'classnames';

import { connect } from 'react-redux';
import { parseISO, format, startOfDay, endOfDay, isWithinInterval, isAfter } from 'date-fns';
import { useSnackbar } from 'notistack';

import {
  Button,
  FormControlLabel,
  Switch,
  withStyles,
  createStyles,
  Table as MuiTable,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Dialog,
  Theme,
  WithStyles,
} from '@material-ui/core';

import { TableHeadProps } from '@material-ui/core/TableHead';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  ApplicationsForm,
  FullWidthContainer as MyFullWidthContainer,
  PageTitle,
  AddRoleForm,
  EditRoleForm,
  QuestionDialog,
  CanRoute,
  Can,
} from '../../components';

import { UserRoles, UserRole } from '../../api';
import {
  RootState,
  UsersEditActiveState,
  ApplicationsState,
  UserSingle,
  RolesDeleteState,
} from '../../store/reducers';
import { TableBodyProps } from '@material-ui/core/TableBody';
import { Dispatch, bindActionCreators } from 'redux';
import { Action, UNITES_OP_LIST_SERVICES_INIT_ACTION, ROLES_DELETE_INIT_ACTION } from '../../store/actions';

import { usePrevious } from '../../hooks';
import { AbilityContext } from '../../configureAbility';

const DATE_TIME_FORMAT = "dd/MM/yyyy \à HH:mm";

const RolesPageTitle = withStyles(({typography}) => createStyles({
  container: {
    height: typography.pxToRem(48),
  },
}), {name: 'RolesPageTitle'})(PageTitle);

const Table = withStyles(({palette}) => createStyles({
  root: {
    border: `1px solid ${palette.divider}`,
  },
}), {name: 'Table'})(MuiTable);

const FullWidthContainer = withStyles(({typography}) => createStyles({
  root: {
    fontSize: typography.pxToRem(13),
  },
}), {name: 'FullWidthContainer'})(({classes, ...props}: any) => <MyFullWidthContainer className={classNames(classes.root)} {...props} />);

const RolesTableHead = (props: TableHeadProps) => (
  <TableHead {...props}>
    <TableRow>
      <TableCell padding="dense">Unité opérationnelle</TableCell>
      <TableCell padding="dense">Service</TableCell>
      <TableCell padding="dense">Validité</TableCell>
      <TableCell align="right"></TableCell>
    </TableRow>
  </TableHead>
);

const styles = ({palette, typography}: Theme) => createStyles({
  form: {
    border   : `1px solid ${palette.divider}`,
    '&:not($formBox)': {
      borderTop: 'none !important',
    },
  },
  formBox: {},
});

function TableRowRole({
  onRemoveClick,
  role,
}: {
  onRemoveClick: (role: UserRole<Date>) => void;
  role: UserRole<Date>;
}) {
  const context   = useContext(AbilityContext);
  const canEdit   = context.can('change', 'Role');
  const canDelete = context.can('delete', 'Role');

  const [edit, setEdit] = useState(false);
  const previousRole    = usePrevious(role);

  const start        = format(role.period.lower, 'dd/MM/yyyy');
  const validityText = !!role.period.upper
    ? `Valable du <strong>${start}</strong> au <strong>${format(role.period.upper, 'dd/MM/yyyy')}</strong>`
    : `Valable à partir du <strong>${start}</strong>`;

    // TODO: force reflow
  useEffect(() => {
    if (
      previousRole
      && JSON.stringify(role) !== JSON.stringify(previousRole)
    ) {
      setEdit(false);
    }
  }, [role]);

  return (
    <TableRow key={role.id}>
      {canEdit && edit ? (
        <TableCell colSpan={4} className="px-4" padding="none" style={{paddingBottom: 7}}>
          <EditRoleForm
            CancelButtonProps={{onClick: () => setEdit(false)}}
            {...{role}}
          />
        </TableCell>
      ) : (
        <>
          <TableCell>{role.service.operational_unit.name}</TableCell>
          <TableCell>{role.service.name}</TableCell>
          <TableCell dangerouslySetInnerHTML={{__html: validityText}} />
          <TableCell align="right" padding="none">
            {canEdit && (
              <IconButton onClick={() => setEdit(true)}>
                <EditIcon fontSize="small" />
              </IconButton>
            )}
            {canDelete && (
              <IconButton onClick={() => onRemoveClick(role)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            )}
          </TableCell>
        </>
      )}
    </TableRow>
  );
}

const RolesTableBody = ({
  onRemoveClick,
  roles,
  ...other
}: TableBodyProps
  & {onRemoveClick: (role: UserRole<Date>) => void}
  & {roles: UserRoles<Date>}
) => (
  <TableBody {...other}>
    {roles.map(role => <TableRowRole key={role.id} {...{onRemoveClick, role}} />)}
  </TableBody>
);

export function PureDroitsAccesTab({
  applications,
  editActiveState,
  onChange,
  onEditClick,
  onMailingClick,
  user,
}: {
  applications   : ApplicationsState;
  editActiveState: UsersEditActiveState;
  onChange       : (checked: boolean) => void;
  onEditClick    : (value: boolean) => void;
  onMailingClick : () => void;
  user           : UserSingle;
}) {
  const {
    is_active,
    validation: {lower, upper},
  } = user;

  const {loading: isActivating} = editActiveState;

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const [isActive, setIsActive] = useState(is_active);
  const isActiveText            = isActive ? 'actif' : 'inactif';

  const validationStart = format(parseISO(lower), DATE_TIME_FORMAT);
  const validationEnd   = !!upper ? format(parseISO(upper), DATE_TIME_FORMAT) : null;

  const handleChange = (checked: boolean) => {
    closeSnackbar('USERS_EDIT_ACTIVE_FAILURE');
    setIsActive(checked);
    onChange(checked);
  };

  // fail to update active
  useEffect(() => {
    if (editActiveState.error) {
      // undo active state
      setIsActive(!isActive);
      enqueueSnackbar('Impossible de modifier le statut', {
        key             : 'USERS_EDIT_ACTIVE_FAILURE',
        persist         : false,
        preventDuplicate: true,
        variant         : 'error',
      });
    }
  }, [editActiveState.error]);

  // update is active if change
  useEffect(() => {
    setIsActive(is_active);
  }, [is_active]);

  return (
    <CanRoute
      I="view"
      a="User"
      exact
      render={() => (
        <FullWidthContainer>
          <div className="d-flex align-items-center justify-content-start">
            <h2 className="h1 flex-1">Droits d'accès</h2>

            <Can
              I="change"
              a="User"
              passThrough={true}
            >
              {(can: boolean) => can ? (
                <FormControlLabel
                  className="m-0"
                  disabled={isActivating}
                  label={`Utilisateur ${isActiveText}`}
                  labelPlacement="start"
                  control={(
                    <Switch
                      color="primary"
                      checked={isActive}
                      onChange={({target}) => handleChange(target.checked)}
                    />
                  )}
                />
              ) : (
                <span>Utilisateur {isActiveText}</span>
              )}
            </Can>
          </div>
          <hr/>
          {!!validationEnd ? (
            <p>Accès valable du <strong>{validationStart}</strong> au <strong>{validationEnd}</strong>.</p>
          ) : (
            <p>Accès valable à partir du <strong>{validationStart}</strong>.</p>
          )}
          <Can I="change" a="User">
            <div className="d-flex align-items-center mt-4">
              <Button
                color="primary"
                onClick={() => onEditClick(true)}
                type="button"
                variant="contained"
              >Modifier</Button>

              <Button
                className="ml-3"
                color="primary"
                onClick={() => onMailingClick()}
                type="button"
                variant="contained"
              >Renvoyer l'email d'activation</Button>
            </div>
          </Can>
          <Can
            I="change"
            a="User"
            passThrough={true}
          >
            {(can: boolean) => (
              <>
                {can && <hr className="my-4"/>}

                <div className="container-fluid p-0">
                <div className="row m-0">
                  <div className="col-6 p-0">
                    <ApplicationsForm {...{applications, user}} />
                  </div>
                </div>
              </div>
              </>
            )}
          </Can>
        </FullWidthContainer>
      )}
    />
  );
}

export function PureRolesTab({
  deleteRole,
  getUnitesOpWithServices,
  deleteState,
  user,
  classes,
}: {
  deleteRole             : typeof ROLES_DELETE_INIT_ACTION;
  getUnitesOpWithServices: typeof UNITES_OP_LIST_SERVICES_INIT_ACTION,
  deleteState            : RolesDeleteState;
  user                   : UserSingle;
} & WithStyles<typeof styles>) {
  const [removeRole, setRemoveRole] = useState<{
    data?: UserRole<Date>,
    open: boolean,
  }>({
    open: false,
  });

  const {loading: isDeleting, success: isDeleted} = deleteState;

  const roles = user.roles.map(({period: {lower, upper}, ...role}) => ({
    ...role,
    period: {
      lower: startOfDay(parseISO(lower)),
      upper  : !!upper ? endOfDay(parseISO(upper)): null,
    },
  }));

  const now          = Date.now();
  const currentRoles = roles.filter(({period: {lower: start, upper: end}}) => {
    if (!!end) {
      if (isAfter(start, now)) {
        return true;
      }

      return isWithinInterval(now, {start, end});
    }

    return true;
  });
  const currentRolesIds = currentRoles.reduce((pv, {id}) => [...pv, id], [] as number[]);
  const pastRoles = roles.filter(({id}) => !currentRolesIds.includes(id)).reverse();

  useEffect(() => {
    getUnitesOpWithServices();
  }, []);

  // deleted success
  useEffect(() => {
    if (isDeleted) {
      setRemoveRole({...removeRole, open: false});
    }
  }, [isDeleted]);

  return (
    <CanRoute
      I="view"
      a="Role"
      exact
      render={() => (
        <>
          <FullWidthContainer>
            <RolesPageTitle primary="Rôles" />

            {currentRoles.length >= 1 ? (
              <>
                <PageTitle
                  className="mt-4 mb-2"
                  hr={false}
                  primary="Rôles actifs"
                  size="small"
                />

                <Table>
                  <RolesTableHead />
                  <RolesTableBody
                    roles={currentRoles}
                    onRemoveClick={data => setRemoveRole({data, open: true})}
                  />
                </Table>
              </>
            ) : (
              <Can
                not
                I="add"
                a="Role"
                passThrough
              >
                {() => (
                  <span>Cet utilisateur ne dispose d'aucun rôle.</span>
                )}
              </Can>
            )}

            <Can I="add" a="Role">
              {() => (
                <div className={classNames('p-3', classes.form, {
                  [classes.formBox]: currentRoles.length === 0,
                  'mb-4'           : pastRoles.length === 0,
                })}>
                  <strong>Ajouter un rôle</strong>
                  <div className="d-flex align-items-center">
                    <AddRoleForm userId={user.id} />
                  </div>
                </div>
              )}
            </Can>

            {pastRoles.length >= 1 && (
              <>
                <PageTitle
                  className="mt-4 mb-2"
                  hr={false}
                  primary="Rôles passés"
                  size="small"
                />

                <Table className="mb-4">
                  <RolesTableHead />
                  <RolesTableBody
                    onRemoveClick={data => setRemoveRole({data, open: true})}
                    roles={pastRoles}
                  />
                </Table>
              </>
            )}
          </FullWidthContainer>

          <Dialog
            disableBackdropClick={isDeleting}
            disableEscapeKeyDown={isDeleting}
            fullWidth
            maxWidth="sm"
            open={!!removeRole.open}
            onClose={() => setRemoveRole({...removeRole, open: false})}
            onExited={() => setRemoveRole({open: false})}
          >
            {removeRole.data ? (
              <QuestionDialog
                CancelButtonProps={{onClick: () => setRemoveRole({...removeRole, open: false})}}
                ConfirmButtonProps={{
                  onClick: () => {
                    if (removeRole.data) {
                      deleteRole(removeRole.data.id, {userId: user.id});
                    }
                  }
                }}
                loading={isDeleting}
              >Voulez-vous supprimer ce rôle ?</QuestionDialog>
            ) : <></>}
          </Dialog>
        </>
      )}
    />
  );
}

const mapStateToDroitsAccesTabProps = (state: RootState) => ({
  applications   : [...state.applications],
  editActiveState: {...state.users.editActive},
});

const mapStateToRolesTabProps = (state: RootState) => ({
  deleteState: {...state.roles.remove},
});

const mapDispatchToRolesTabProps = (dispatch: Dispatch<Action>) => bindActionCreators({
  deleteRole             : ROLES_DELETE_INIT_ACTION,
  getUnitesOpWithServices: UNITES_OP_LIST_SERVICES_INIT_ACTION,
}, dispatch);

export const DroitsAccesTab = connect(mapStateToDroitsAccesTabProps)(PureDroitsAccesTab);
export const RolesTab       = connect(mapStateToRolesTabProps, mapDispatchToRolesTabProps)(
  withStyles(styles, {name: 'RolesTab'})(PureRolesTab),
);
