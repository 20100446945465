import React from 'react';
import classNames from 'classnames';

import {
  createStyles,
  FormControlLabel,
  WithStyles,
  withStyles,
} from '@material-ui/core';

import { FormControlLabelProps } from '@material-ui/core/FormControlLabel';

const LabelPickerStyles = createStyles({
  root: {
    alignItems: 'first baseline',
  },
  label: {
    minWidth: 100,
  },
  textleft: {
    textAlign: 'left',
  },
  textright: {
    textAlign: 'right',
  },
});

type LabelPickerProps =
  & FormControlLabelProps
  & WithStyles<typeof LabelPickerStyles>
  & {labelPosition: 'left' | 'right';};

const LabelPicker = ({
  classes,
  className,
  labelPosition,
  ...other
}: LabelPickerProps) => <FormControlLabel className={classNames(className, `text${labelPosition}`)} {...other} />;

LabelPicker.defaultProps = {
  labelPosition: 'right',
} as LabelPickerProps;

export const LabelPickerFormControlLabel = withStyles(
  LabelPickerStyles,
  {name: 'LabelPickerFromControlLabel'}
)(LabelPicker);

export const FirstMarginFormControlLabel = withStyles(createStyles({
  label: {
    // width      : 100,
    marginRight: '16px !important',
    whiteSpace : 'nowrap',
  },
}), {name: 'FirstMarginFormControlLabel'})(FormControlLabel);

export const MarginFormControlLabel = withStyles(createStyles({
  label: {
    marginLeft : '16px !important',
    marginRight: '16px !important',
    whiteSpace : 'nowrap',
  },
}), {name: 'MarginFormControlLabel'})(FormControlLabel);

export const SwitchFormControlLabel = withStyles(createStyles({
  root: {
    marginLeft: '12px !important',
  },
}), {name: 'SwitchFormControlLabel'})(FormControlLabel);
