import {
  ActionType,
  createStandardAction,
} from 'typesafe-actions';

import {
  UNITES_OP_SINGLE_INIT,
  UNITES_OP_SINGLE_SUCCESS,
  UNITES_OP_SINGLE_FAILURE,
  UNITES_OP_SINGLE_RESET,
} from '../constants';

import { UnitesOpReadResponse } from '../../api/services';

export const UNITES_OP_SINGLE_INIT_ACTION = createStandardAction(UNITES_OP_SINGLE_INIT)<number>();
export type UnitesOpSingleInitActionType = ActionType<typeof UNITES_OP_SINGLE_INIT_ACTION>;

export const UNITES_OP_SINGLE_SUCCESS_ACTION = createStandardAction(UNITES_OP_SINGLE_SUCCESS)<UnitesOpReadResponse>();
export type UnitesOpSingleSuccessActionType = ActionType<typeof UNITES_OP_SINGLE_SUCCESS_ACTION>;

export const UNITES_OP_SINGLE_FAILURE_ACTION = createStandardAction(UNITES_OP_SINGLE_FAILURE)();
export type UnitesOpSingleFailureActionType = ActionType<typeof UNITES_OP_SINGLE_FAILURE_ACTION>;

export const UNITES_OP_SINGLE_RESET_ACTION = createStandardAction(UNITES_OP_SINGLE_RESET)();
export type UnitesOpSingleResetActionType = ActionType<typeof UNITES_OP_SINGLE_RESET_ACTION>;

export type UnitesOpSingleAction =
  | UnitesOpSingleInitActionType
  | UnitesOpSingleSuccessActionType
  | UnitesOpSingleFailureActionType
  | UnitesOpSingleResetActionType;

export default {
  UNITES_OP_SINGLE_INIT_ACTION,
  UNITES_OP_SINGLE_SUCCESS_ACTION,
  UNITES_OP_SINGLE_FAILURE_ACTION,
  UNITES_OP_SINGLE_RESET_ACTION,
};
