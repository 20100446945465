import {
  bindActionCreators,
  Dispatch,
} from 'redux';

import { connect } from 'react-redux';

import { withFormik } from 'formik';

import {
  endOfDay,
  startOfDay,
} from 'date-fns';

import RoleForm from './RoleForm';

import {
  RoleFormValidate,
  RoleFormValues,
} from './RoleForm';

import {
  Action,
  ROLES_ADD_INIT_ACTION,
} from '../../store/actions';

import {
  RootState,
} from '../../store/reducers';
import { RolesCreateRequest } from '../../api';

// TODO: find the correct typing for formik, az,enjkabzeh vazgjevauzevk
type TODO_FORMIK_TYPING_DE_SES_MORTS = any;

export const AddRoleForm = withFormik<TODO_FORMIK_TYPING_DE_SES_MORTS, RoleFormValues>({
  validate: RoleFormValidate,
  mapPropsToValues: () => {
    const lower = startOfDay(Date.now());

    return {
      no_end    : true,
      service_id: -1,
      lower,
    };
  },
  handleSubmit: (values, {props, ...actions}) => {
    const {lower, upper, service_id} = values;
    const {addRole, userId: user_id} = props;

    let payload = {
      period: {
        lower: startOfDay(lower).toISOString(),
        upper: upper instanceof Date ? endOfDay(upper).toISOString(): null,
      },
      service_id,
      user_id,
    } as RolesCreateRequest;

    addRole(payload, {actions, values});
  },
})(RoleForm);

const mapStateToProps = (state: RootState) => ({
  unitesOp: {...state.unites_op.listServices},
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
  addRole: ROLES_ADD_INIT_ACTION,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddRoleForm);
