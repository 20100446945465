import { UserWithGroups } from '../../api/services';
import { UsersEditValidityAction } from '../actions';

import {
  USERS_EDIT_VALIDITY_INIT,
  USERS_EDIT_VALIDITY_SUCCESS,
  USERS_EDIT_VALIDITY_FAILURE,
  USERS_EDIT_VALIDITY_RESET,
} from '../constants';

export const getUsersEditValidityStateToProps = <T extends UsersEditValidityState>({
  diff,
  error,
  item,
  loading,
}: Pick<T, keyof UsersEditValidityState>) => ({diff, error, item, loading});

export interface UsersEditValidityState {
  diff   : boolean;
  error  : boolean;
  item   : null | UserWithGroups;
  loading: boolean;
}

export const initialUsersEditValidityState: UsersEditValidityState = {
  diff   : false,
  error  : false,
  item   : null,
  loading: false,
};

export default function (state: UsersEditValidityState = initialUsersEditValidityState, action: UsersEditValidityAction): UsersEditValidityState {
  switch (action.type) {
    case USERS_EDIT_VALIDITY_INIT:
      return {
        ...initialUsersEditValidityState,
        loading: true,
      };
    case USERS_EDIT_VALIDITY_SUCCESS:
      return {
        ...initialUsersEditValidityState,
        diff   : action.meta.diff,
        error  : false,
        item   : {...action.payload},
        loading: false,
      };
    case USERS_EDIT_VALIDITY_FAILURE:
      return {
        ...initialUsersEditValidityState,
        error: true,
      };
    case USERS_EDIT_VALIDITY_RESET:
      return {...initialUsersEditValidityState};
    default:
      return state;
  }
}
