import React, { useContext } from 'react';
import { RouteProps, Route } from 'react-router';

import { CanProps } from '@casl/react';

import { AbilityContext } from '../configureAbility';

export type CanRouteProps =
  & CanProps
  & RouteProps;

/**
 * `CanRoute` component to allow or restrict access to a Route, based on ability.
 */
export default function({
  location,
  children,
  path,
  exact,
  sensitive,
  strict,
  ...props
}: CanRouteProps) {
  function renderCannot() {
    return (
      <div>
        <h1>Permissions insuffisantes</h1>
        <br/>
        <p>Vous n'avez pas le droit d'effectuer cette action.</p>
      </div>
    );
  }

  function renderRoute (
    render    : CanRouteProps['render'],
    component?: CanRouteProps['component'],
  ) {
    return <Route  {...{
      location,
      component,
      render,
      children,
      path,
      exact,
      sensitive,
      strict,
    }} />;
  }

  const context = useContext(AbilityContext);

  //@ts-ignore
  const action  = props.do || props.I;
  //@ts-ignore
  const subject = props.on || props.a || props.an || props.of || props.this;

  const {not} = props;
  const can   = not === true ? context.cannot(action, subject) : context.can(action, subject);

  return can ? renderRoute(props.render, props.component) : renderRoute(renderCannot);
}
