import {
  ActionType,
  createStandardAction,
} from 'typesafe-actions';

import {
  ROLES_DELETE_INIT,
  ROLES_DELETE_SUCCESS,
  ROLES_DELETE_FAILURE,
  ROLES_DELETE_RESET,
} from '../constants';

export const ROLES_DELETE_INIT_ACTION = createStandardAction(ROLES_DELETE_INIT)<number, {
  userId: number;
}>();
export type RolesDeleteInitActionType = ActionType<typeof ROLES_DELETE_INIT_ACTION>;

export const ROLES_DELETE_SUCCESS_ACTION = createStandardAction(ROLES_DELETE_SUCCESS)();
export type RolesDeleteSuccessActionType  = ActionType<typeof ROLES_DELETE_SUCCESS_ACTION>;

export const ROLES_DELETE_FAILURE_ACTION = createStandardAction(ROLES_DELETE_FAILURE)();
export type RolesDeleteFailureActionType  = ActionType<typeof ROLES_DELETE_FAILURE_ACTION>;

export const ROLES_DELETE_RESET_ACTION = createStandardAction(ROLES_DELETE_RESET)();
export type RolesDeleteResetActionType  = ActionType<typeof ROLES_DELETE_RESET_ACTION>;

export type RolesDeleteAction =
  | RolesDeleteInitActionType
  | RolesDeleteSuccessActionType
  | RolesDeleteFailureActionType
  | RolesDeleteResetActionType;

export default {
  ROLES_DELETE_INIT_ACTION,
  ROLES_DELETE_SUCCESS_ACTION,
  ROLES_DELETE_FAILURE_ACTION,
  ROLES_DELETE_RESET_ACTION,
};
