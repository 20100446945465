import {
  ActionType,
  createStandardAction,
} from 'typesafe-actions';

import {
  USERS_DELETE_INIT,
  USERS_DELETE_SUCCESS,
  USERS_DELETE_FAILURE,
  USERS_DELETE_RESET,
} from '../constants';

export const USERS_DELETE_INIT_ACTION = createStandardAction(USERS_DELETE_INIT)<number>();
export type UsersDeleteInitActionType = ActionType<typeof USERS_DELETE_INIT_ACTION>;

export const USERS_DELETE_SUCCESS_ACTION = createStandardAction(USERS_DELETE_SUCCESS)();
export type UsersDeleteSuccessActionType  = ActionType<typeof USERS_DELETE_SUCCESS_ACTION>;

export const USERS_DELETE_FAILURE_ACTION = createStandardAction(USERS_DELETE_FAILURE)();
export type UsersDeleteFailureActionType  = ActionType<typeof USERS_DELETE_FAILURE_ACTION>;

export const USERS_DELETE_RESET_ACTION = createStandardAction(USERS_DELETE_RESET)();
export type UsersDeleteResetActionType  = ActionType<typeof USERS_DELETE_RESET_ACTION>;

export type UsersDeleteAction =
  | UsersDeleteInitActionType
  | UsersDeleteSuccessActionType
  | UsersDeleteFailureActionType
  | UsersDeleteResetActionType;

export default {
  USERS_DELETE_INIT_ACTION,
  USERS_DELETE_SUCCESS_ACTION,
  USERS_DELETE_FAILURE_ACTION,
  USERS_DELETE_RESET_ACTION,
};
