import React from 'react';

import classNames from 'classnames';

import {
  isBefore,
  isEqual,
  isValid,
} from 'date-fns';

import {
  Field,
  Form,
  FieldProps,
  FormikProps,
  getIn,
  WithFormikConfig,
} from 'formik';

import {
  createStyles,
  DialogContent,
  DialogTitle,
  Switch,
  withStyles,
} from '@material-ui/core';

import {
  FormikInlineDatePicker,
  FormikSelect,
  FormikStatus,
  FirstMarginFormControlLabel,
  MarginFormControlLabel,
  SimpleDialogActions,
  SwitchFormControlLabel,
} from '..';

import { SimpleDialogActionsProps } from '../SimpleDialogActions';

/**
 * Create an array of `length` items, with index as value.
 */
const fillHours = (length: number) => Array(length).fill(null).map((_, i) => i);

export const getUserValidityFormValues =  <T extends UserValidityFormValues>({
  start_day,
  start_hours,
  end_day,
  end_hours,
  no_end,
}: Pick<T, keyof UserValidityFormValues>) => ({start_day, start_hours, end_day, end_hours, no_end});

export const UserValidityFormValidate: WithFormikConfig<any, UserValidityFormValues>['validate'] = ({
  start_day,
  start_hours,
  end_day,
  end_hours,
  no_end
}) => {
  let errors = {};

  if (!start_day) { // pas date de fin
    errors = {...errors, start_day: 'Date de début requise'};
  } else if (!isValid(start_day)) { // date de début invalide
    errors = {...errors, start_day: 'Date de début invalide'};
  }

  // pas d'heure de début
  if (start_hours === undefined) {
    errors = {...errors, start_hours: 'Heure de début requise'};
  }

  // on demande une date/heure de fin
  if (!no_end) {
    if (!end_day) { // pas date de fin
      errors = {...errors, end_day: 'Date de fin requise'};
    } else if (!isValid(end_day)) { // date de fin invalide
      errors = {...errors, end_day: 'Date de fin invalide'};
    } else if (isBefore(end_day, start_day)) { // date de fin avant celle de début
      errors = {...errors, end_day: 'Pas avant la date de début'};
    }

    // pas d'heure de fin
    if (end_hours === undefined) {
      errors = {...errors, end_hours: 'Heure de fin requise'};
    } else if (
      end_day && isEqual(end_day, start_day)
      && end_hours <= start_hours
    ) {
      errors = {...errors, end_hours: `Pas avant l'heure de début`};
    }
  }

  return errors;
}

export interface UserValidityFormValues {
  start_day  : Date;
  start_hours: number;
  end_day    ?: Date;
  end_hours  ?: number;
  no_end   : boolean;
}

export type UserValidityFormProps =
  & SimpleDialogActionsProps
  & FormikProps<UserValidityFormValues>;

export function UserValidityForm({
  CancelButtonProps,
  isSubmitting,
  ...other
}: UserValidityFormProps) {
  const {values} = other;

  const startDay = getIn(values, 'start_day');
  const noLimit  = getIn(values, 'no_end');

  return (
    <>
      <Form noValidate>
        <DialogTitle>Validité d'accès</DialogTitle>
        <DialogContent className="pt-3">
          <div className="d-flex align-items-first-baseline">
            <FirstMarginFormControlLabel
              className="m-0"
              label="Date de début :"
              labelPlacement="start"
              control={<FormikInlineDatePicker
                className="ml-3"
                name="start_day"
              />}
            />

            <MarginFormControlLabel
              className="m-0"
              label="à"
              labelPlacement="start"
              control={<FormikSelect
                items={fillHours(24)}
                name="start_hours"
                renderItem={(value: number) => `${value.toString().padStart(2, '0')}:00`}
                style={{width: 75}}
              />}
            />
          </div>
          <div className="d-flex align-items-first-baseline mt-4">
            <FirstMarginFormControlLabel
              className="m-0"
              disabled={noLimit}
              label="Date de fin :"
              labelPlacement="start"
              control={<FormikInlineDatePicker
                className="ml-3"
                minDate={!noLimit ? startDay : undefined}
                name="end_day"
              />}
            />

            <MarginFormControlLabel
              className="m-0"
              disabled={noLimit}
              label="à"
              labelPlacement="start"
              htmlFor="end_hours"
              control={<FormikSelect
                id="end_hours"
                items={fillHours(24)}
                name="end_hours"
                renderItem={(value: number) => `${value.toString().padStart(2, '0')}:00`}
                style={{width: 75}}
              />}
            />
            <Field
              name="no_end"
              render={({field}: FieldProps<boolean>) => {
                const {name, value: checked, onChange} = field;

                return (
                  <SwitchFormControlLabel
                    className="m-0"
                    control={<Switch color="primary" />}
                    label="Illimité"
                    {...{name, checked, onChange}}
                  />
                );
              }}
            />
          </div>
        </DialogContent>

        <SimpleDialogActions {...{CancelButtonProps, isSubmitting}} />

        <FormikStatus />
      </Form>
    </>
  )
}

export default UserValidityForm;
