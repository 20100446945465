import { useEffect } from 'react';

import { connect } from 'formik';
import { useSnackbar } from 'notistack';

export default connect(({formik}) => {
  const {status}          = formik;
  const {enqueueSnackbar} = useSnackbar();

  // show a snackbar form has a status
  useEffect(() => {
    if (status) {
      const {message, ...options} = status;

      enqueueSnackbar(message, {
        persist         : false,
        preventDuplicate: true,
        ...options,
      });
    }
  }, [status]);

  return null;
});
