import { Role } from '../../api/services';
import { RolesEditAction } from '../actions';

import {
  ROLES_EDIT_INIT,
  ROLES_EDIT_SUCCESS,
  ROLES_EDIT_FAILURE,
  ROLES_EDIT_RESET,
} from '../constants';

export const getRolesEditStateToProps = <T extends RolesEditState>({
  // diff,
  error,
  item,
  loading,
}: Pick<T, keyof RolesEditState>) => ({error, item, loading});

export interface RolesEditState {
  // diff   : boolean;
  error  : boolean;
  item   : null | Role;
  loading: boolean;
}

export const initialRolesEditState: RolesEditState = {
  // diff   : false,
  error  : false,
  item   : null,
  loading: false,
};

export default function (state: RolesEditState = initialRolesEditState, action: RolesEditAction): RolesEditState {
  switch (action.type) {
    case ROLES_EDIT_INIT:
      return {
        ...initialRolesEditState,
        loading: true,
      };
    case ROLES_EDIT_SUCCESS:
      return {
        // diff   : action.meta.diff,
        error  : false,
        item   : {...action.payload},
        loading: false,
      };
    case ROLES_EDIT_FAILURE:
      return {
        ...initialRolesEditState,
        error: true,
      };
    case ROLES_EDIT_RESET:
      return {...initialRolesEditState};
    default:
      return state;
  }
}
