import { Epic, ofType } from 'redux-observable';

import { ReplaySubject } from 'rxjs';
import { catchError, concatMap, switchMap, tap } from 'rxjs/operators';

import Api from '../../configureApi';

import {
  Action,
  UsersMailInitActionType,
  USERS_MAIL_SUCCESS_ACTION,
  USERS_MAIL_RESET_ACTION,
  USERS_MAIL_FAILURE_ACTION,
} from '../actions';

import { USERS_MAIL_INIT } from '../constants';

export const usersMailEpic: Epic<UsersMailInitActionType, any> = action$ => action$
  .pipe(
    ofType(USERS_MAIL_INIT),
    switchMap(({payload: userId}: UsersMailInitActionType) => {
      const stack$      = new ReplaySubject<Action>();

      return Api.Services.sendActivationMail(userId).pipe(
        tap(() => {
          stack$.next(USERS_MAIL_SUCCESS_ACTION());
          stack$.next(USERS_MAIL_RESET_ACTION());
        }, () => {
          stack$.next(USERS_MAIL_FAILURE_ACTION());
          stack$.next(USERS_MAIL_RESET_ACTION());
          stack$.complete();
        }),
        concatMap(() => stack$),
        catchError(() => stack$),
      );
    }),
  );

export default [
  usersMailEpic,
];
