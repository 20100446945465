export const SELF_PERMISSIONS_LIST = '/iam_permissions/';

export const ROLES_CREATE = '/role/';
export const ROLES_UPDATE = (roleId: number) => `/role/${roleId}/`;

export const SERVICES_LIST   = '/service/';
export const SERVICES_CREATE = '/service/';
export const SERVICES_UPDATE = (serviceId: number) => `/service/${serviceId}/`;
export const SERVICES_DELETE = (serviceId: number) => `/service/${serviceId}/`;

export const USERS_LIST           = '/iam_users/get_users_list_with_roles/';
export const USERS_CREATE         = '/iam_users/';
export const USERS_READ           = (userId: number) => `/iam_users/${userId}/`;
export const USERS_UPDATE         = (userId: number) => `/iam_users/${userId}/`;
export const USERS_DELETE         = (userId: number) => `/iam_users/${userId}/`;
export const USERS_ACTIVATE_EMAIL = (userId: number) => `/iam_users/${userId}/send_activation_mail/`;

export const USERS_ROLES_READ = (userId: number) => `/iam_users/${userId}/get_user_roles/`;

export const UNITES_OP_LIST   = '/operational_unit/';
export const UNITES_OP_CREATE = '/operational_unit/';
export const UNITES_OP_READ   = (uniteOpId: number) => `/operational_unit/${uniteOpId}/`;
export const UNITES_OP_UPDATE = (uniteOpId: number) => `/operational_unit/${uniteOpId}/`;
