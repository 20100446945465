import React, { PropsWithChildren } from 'react';

import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
} from '@material-ui/core';

import { ButtonProps } from '@material-ui/core/Button';

import {
  CancelButton,
} from '..';

export interface QuestionDialogBaseProps {
  CancelButtonProps ?: ButtonProps;
  ConfirmButtonProps?: ButtonProps;
  loading            : boolean;
}

export type QuestionDialogProps =
  & PropsWithChildren<QuestionDialogBaseProps>;

export function QuestionDialog({
  CancelButtonProps,
  ConfirmButtonProps,
  children,
  loading,
}: QuestionDialogProps) {
  return (
    <>
      <DialogContent className="text-center">
        {children}
      </DialogContent>
      <DialogActions>
        {loading ? (
          <CircularProgress size={32} />
        ) : (
          <>
            <CancelButton
              color="inherit"
              type="button"
              variant="text"
              {...CancelButtonProps}
            >Non</CancelButton>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              {...ConfirmButtonProps}
            >Oui</Button>
          </>
        )}
      </DialogActions>
    </>
  );
}

export default QuestionDialog;
