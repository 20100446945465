import React from 'react';

import classNames from 'classnames';

import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core';

const styles = ({palette}: Theme) => createStyles({
  root: {
    width          : 1,
    backgroundColor: palette.divider,
  },
});

type ComponentProps =
  & {className?: string}
  & WithStyles<typeof styles>;

export default withStyles(styles, {name: 'VerticalDivider'})
  (({classes, className}: ComponentProps) => (<div className={classNames(classes.root, className)}></div>));
