import { ServicesAddAction } from '../actions';

import {
  SERVICES_ADD_INIT,
  SERVICES_ADD_SUCCESS,
  SERVICES_ADD_FAILURE,
  SERVICES_ADD_RESET,
} from '../constants';

import { Service } from '../../api/services';

export const getServicesAddStateToProps = <T extends ServicesAddState>({
  error,
  item,
  loading,
}: Pick<T, keyof ServicesAddState>) => ({error, item, loading});

export interface ServicesAddState {
  error  : boolean;
  item   : null | Service;
  loading: boolean;
}

export const initialServicesAddState: ServicesAddState = {
  error  : false,
  item   : null,
  loading: false,
};

export default function (state: ServicesAddState = initialServicesAddState, action: ServicesAddAction): ServicesAddState {
  switch (action.type) {
    case SERVICES_ADD_INIT:
      return {
        ...initialServicesAddState,
        loading: true,
      };
    case SERVICES_ADD_SUCCESS:
      return {
        error  : false,
        item   : {...action.payload},
        loading: false,
      };
    case SERVICES_ADD_FAILURE:
      return {
        ...initialServicesAddState,
        error: true,
      };
    case SERVICES_ADD_RESET:
      return {...initialServicesAddState};
    default:
      return state;
  }
}
