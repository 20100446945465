import {
  ActionType,
  createStandardAction,
} from 'typesafe-actions';

import {
  USERS_MAIL_INIT,
  USERS_MAIL_SUCCESS,
  USERS_MAIL_FAILURE,
  USERS_MAIL_RESET,
} from '../constants';

export const USERS_MAIL_INIT_ACTION = createStandardAction(USERS_MAIL_INIT)<number>();
export type UsersMailInitActionType = ActionType<typeof USERS_MAIL_INIT_ACTION>;

export const USERS_MAIL_SUCCESS_ACTION = createStandardAction(USERS_MAIL_SUCCESS)();
export type UsersMailSuccessActionType  = ActionType<typeof USERS_MAIL_SUCCESS_ACTION>;

export const USERS_MAIL_FAILURE_ACTION = createStandardAction(USERS_MAIL_FAILURE)();
export type UsersMailFailureActionType  = ActionType<typeof USERS_MAIL_FAILURE_ACTION>;

export const USERS_MAIL_RESET_ACTION = createStandardAction(USERS_MAIL_RESET)();
export type UsersMailResetActionType  = ActionType<typeof USERS_MAIL_RESET_ACTION>;

export type UsersMailAction =
  | UsersMailInitActionType
  | UsersMailSuccessActionType
  | UsersMailFailureActionType
  | UsersMailResetActionType;

export default {
  USERS_MAIL_INIT_ACTION,
  USERS_MAIL_SUCCESS_ACTION,
  USERS_MAIL_FAILURE_ACTION,
  USERS_MAIL_RESET_ACTION,
};
