import { UniteOpDetails } from '../../api/services';
import { UnitesOpEditAction } from '../actions';

import {
  UNITES_OP_EDIT_INIT,
  UNITES_OP_EDIT_SUCCESS,
  UNITES_OP_EDIT_FAILURE,
  UNITES_OP_EDIT_RESET,
} from '../constants';

export const getUnitesOpEditStateToProps = <T extends UnitesOpEditState>({
  diff,
  error,
  item,
  loading,
}: Pick<T, keyof UnitesOpEditState>) => ({diff, error, item, loading});

export interface UnitesOpEditState {
  diff   : boolean;
  error  : boolean;
  item   : null | UniteOpDetails;
  loading: boolean;
}

export const initialUnitesOpEditState: UnitesOpEditState = {
  diff   : false,
  error  : false,
  item   : null,
  loading: false,
};

export default function (state: UnitesOpEditState = initialUnitesOpEditState, action: UnitesOpEditAction): UnitesOpEditState {
  switch (action.type) {
    case UNITES_OP_EDIT_INIT:
      return {
        ...initialUnitesOpEditState,
        loading: true,
      };
    case UNITES_OP_EDIT_SUCCESS:
      return {
        diff   : action.meta.diff,
        error  : false,
        item   : {...action.payload},
        loading: false,
      };
    case UNITES_OP_EDIT_FAILURE:
      return {
        ...initialUnitesOpEditState,
        error: true,
      };
    case UNITES_OP_EDIT_RESET:
      return {...initialUnitesOpEditState};
    default:
      return state;
  }
}
