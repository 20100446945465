import { Epic, ofType } from 'redux-observable';

import { ReplaySubject } from 'rxjs';
import { catchError, concatMap, switchMap, tap } from 'rxjs/operators';

import Api from '../../configureApi';

import {
  Action,
  UnitesOpDeleteInitActionType,
  UNITES_OP_DELETE_SUCCESS_ACTION,
  UNITES_OP_DELETE_RESET_ACTION,
  UNITES_OP_DELETE_FAILURE_ACTION,
  UNITES_OP_LIST_IAT_RESET_ACTION,
} from '../actions';

import { UNITES_OP_DELETE_INIT } from '../constants';

export const unitesOpDeleteEpic: Epic<UnitesOpDeleteInitActionType, any> = action$ => action$
  .pipe(
    ofType(UNITES_OP_DELETE_INIT),
    switchMap(({payload: uniteOpId}: UnitesOpDeleteInitActionType) => {
      const stack$ = new ReplaySubject<Action>();

      return Api.Services.deleteUniteOp(uniteOpId).pipe(
        tap(() => {
          stack$.next(UNITES_OP_DELETE_SUCCESS_ACTION());
          stack$.next(UNITES_OP_LIST_IAT_RESET_ACTION());
          stack$.next(UNITES_OP_DELETE_RESET_ACTION());
          stack$.complete();
        }, () => {
          stack$.next(UNITES_OP_DELETE_FAILURE_ACTION());
          stack$.next(UNITES_OP_DELETE_RESET_ACTION());
          stack$.complete();
        }),
        concatMap(() => stack$),
        catchError(() => stack$),
      );
    }),
  );

export default [
  unitesOpDeleteEpic,
];
