import React, { useState, useEffect } from 'react';

import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { RouteComponentProps } from 'react-router';
import { useSnackbar } from 'notistack';

import classNames from 'classnames';

import {
  Button,
  createStyles,
  Dialog,
  Tab,
  Tabs,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core';

import { ButtonProps } from '@material-ui/core/Button';

import { UsersEditDialog, UsersEditValidityDialog } from '.';
import { DroitsAccesTab, RolesTab } from './UsersSingleTabs';

import { User, UserWithGroups } from '../../api';

import {
  FullWidthContainer,
  PageError,
  TextLoader,
  Avatar,
  PageTitle,
  QuestionDialog,
  Can,
} from '../../components';

import {
  Action,
  USERS_SINGLE_INIT_ACTION,
  USERS_SINGLE_RESET_ACTION,
  USERS_EDIT_ACTIVE_INIT_ACTION,
  USERS_MAIL_INIT_ACTION,
} from '../../store/actions';

import {
  RootState,
  UsersSingleState,
  UsersEditState,
  UsersEditValidityState,
  UsersMailState,
} from '../../store/reducers';

import { WithPropsFromState } from '../../shared/types';

const userInfoStyles = ({palette, typography}: Theme) => createStyles({
  root: {
    position: 'relative',
    // position: 'sticky',
    // top     : 60,
    // left    : 0,
  },
  container: {
    maxWidth: `calc(100% - 104px - ${typography.pxToRem(24)})`,
  },
  divider: {
    content        : '""',
    position       : 'absolute',
    top            : 76,
    right          : 0,
    left           : 0,
    transform      : 'translateY(50%)',
    display        : 'block',
    height         : 1,
    backgroundColor: palette.divider,
  },
  info: {
    lineHeight: 1.5,
  },
});

const BigAvatar = withStyles(({typography}) => createStyles({
  root: {
    width   : 104,
    height  : 104,
    fontSize: typography.pxToRem(30),
  },
}), {name: 'BigAvatar'})(Avatar);

const UserInfo = withStyles(userInfoStyles, {name: 'UserInfo'})(({
  avatar,
  ButtonProps,
  classes,
  displayName,
  email,
  loading,
  phone,
}: {ButtonProps?: ButtonProps, displayName: string, loading: boolean} & Partial<UserWithGroups> & WithStyles<typeof userInfoStyles>) => (
  <FullWidthContainer className={classes.root}>
    <div className={classes.divider}></div>
    <div
      className="d-flex align-items-start justify-content-start"
    >
      <BigAvatar
        alt={displayName}
        src={avatar || undefined}
      >{displayName}</BigAvatar>

      <div className={classNames(classes.container, "flex-1 ml-4 pt-2 pb-3")}>
        <div className="pb-3">
          <PageTitle
            hr={false}
            primary={displayName}
          />
        </div>

        {!loading ? (
          <>
            <div className={classNames(classes.info, 'pt-3')}>
              <span>{email}</span>
              <br/>
              <span>{phone ? phone : 'Aucun numéro de téléphone'}</span>
            </div>

            <Can I="change" a="User">
              <div className="pt-4">
                <Button
                  color="primary"
                  type="button"
                  variant="contained"
                  {...ButtonProps}
                >Modifier</Button>
              </div>
            </Can>
          </>
        ) : (
          <div className="pt-3">
            <TextLoader />
          </div>
        )}
      </div>
    </div>
  </FullWidthContainer>
));

export interface UsersSinglePageBaseProps {
  clean             : typeof USERS_SINGLE_RESET_ACTION;
  editState         : UsersEditState;
  editValidityState : UsersEditValidityState;
  getUser           : typeof USERS_SINGLE_INIT_ACTION;
  mailState         : UsersMailState;
  sendActivationMail: typeof USERS_MAIL_INIT_ACTION;
  setActiveUser     : typeof USERS_EDIT_ACTIVE_INIT_ACTION;
}

export type ComponentLocationState =
  & Pick<User, 'avatar' | 'first_name' | 'last_name'>;

export type UsersSinglePageProps =
  & UsersSinglePageBaseProps
  & RouteComponentProps<{userId: string}, {}, undefined | ComponentLocationState>
  & WithPropsFromState<UsersSingleState>;

export function UsersSinglePage({
  clean,
  editState,
  editValidityState,
  getUser,
  mailState,
  setActiveUser,
  sendActivationMail,
  history,
  location,
  match,
  ...other
}: UsersSinglePageProps) {
  let displayName: string;

  const {pathname, state}      = location;
  const {error, item, loading} = other;

  const userId = parseInt(match.params.userId, 10);

  const [tabIndex, setTabIndex] = useState(0);

  const [editDialog, setEditDialog]          = useState(false);
  const {item: editItem, loading: isEditing} = editState;

  const [editValidityDialog, setEditValidityDialog]          = useState(false);
  const {item: editValidityItem, loading: isEditingValidity} = editValidityState;

  const [mailing, setMailing] = useState(false);
  const {error: failMailing, loading: isMailing, success: hasMailed}  = mailState;

  const {enqueueSnackbar} = useSnackbar();

  const handleActiveChange = (checked: boolean) => {
    setActiveUser(checked, {refresh: false, userId});
  };

  // get user detail on mount
  useEffect(() => {
    getUser(userId);
  }, []);

  // update location state
  useEffect(() => {
    if (!!item) {
      const {avatar, first_name, last_name} = item;

      history.replace(pathname, {
        avatar,
        first_name,
        last_name,
      } as ComponentLocationState);
    }
  }, [item]);

  // close edit dialog
  useEffect(() => {
    if (!!editItem) {
      setEditDialog(false);

      if (editState.diff) {
        enqueueSnackbar('Utilisateur modifié avec succès', {
          key             : 'USERS_EDIT_SUCCESS',
          persist         : false,
          preventDuplicate: true,
          variant         : 'success',
        });
      }
    }
  }, [editItem]);

  // droit d'accès modifié
  useEffect(() => {
    if (!!editValidityItem) {
      setEditValidityDialog(false);
    }
  }, [editValidityItem]);

  // mail
  useEffect(() => {
    if (failMailing) {
      enqueueSnackbar(`Impossible de renvoyer l'email d'activation`, {
        key             : 'USERS_MAIL_FAILURE',
        persist         : false,
        preventDuplicate: true,
        variant         : 'error',
      });
    }

    if (hasMailed) {
      enqueueSnackbar(`Email d'activation renvoyé aves succès`, {
        key             : 'USERS_MAIL_SUCCESS',
        persist         : false,
        preventDuplicate: true,
        variant         : 'success',
      });

      setMailing(false);
    }
  }, [mailState]);

  // clean page state on unmount
  useEffect(() => () => {
    clean();
  }, []);

  // show loader
  if (loading) {
    // we can provide a placeholder from location state
    if (!!state) {
      displayName = `${state.first_name} ${state.last_name}`;

      return (
        <>
          <div className="container-fluid">
            <div className="row">
              <div className="col-4">
                <UserInfo loading={true} {...{displayName}} {...state} />
              </div>
              <div className="col-8">
                <TextLoader />
              </div>
            </div>
          </div>
        </>
      );
    }

    return <TextLoader />;
  }

  // show error
  if (error || !item) {
    return <PageError onClick={() => getUser(userId)} />;
  }

  displayName = `${item.first_name} ${item.last_name}`;

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-4">
            <UserInfo
              ButtonProps={{
                onClick: () => setEditDialog(true),
              }}
              loading={false}
              {...{displayName}}
              {...item}
            />
          </div>
          <div className="col-8">
            <Tabs
              className="mb-4"
              indicatorColor="primary"
              value={tabIndex}
              onChange={(e, index) => setTabIndex(index)}
            >
              <Tab label={`Droits d'accès`} />
              <Tab label="Rôles" />
            </Tabs>

            {tabIndex === 0 && (
              <DroitsAccesTab
                onChange={handleActiveChange}
                onEditClick={setEditValidityDialog}
                onMailingClick={() => setMailing(true)}
                user={item}
              />
            )}

            {tabIndex === 1 && (
              <RolesTab user={item} />
            )}
          </div>
        </div>
      </div>

      <Dialog
        disableBackdropClick={isEditing}
        disableEscapeKeyDown={isEditing}
        fullWidth
        maxWidth="sm"
        onClose={() => setEditDialog(false)}
        open={editDialog}
      >
        <UsersEditDialog
          CancelButtonProps={{onClick: () => setEditDialog(false)}}
          title="Informations personnelles"
          refresh={false}
          user={item}
        />
      </Dialog>

      <Dialog
        disableBackdropClick={isEditingValidity}
        disableEscapeKeyDown={isEditingValidity}
        maxWidth="lg"
        onClose={() => setEditValidityDialog(false)}
        open={editValidityDialog}
      >
        <UsersEditValidityDialog
          CancelButtonProps={{onClick: () => setEditValidityDialog(false)}}
          user={item}
        />
      </Dialog>

      <Dialog
        disableBackdropClick={isMailing}
        disableEscapeKeyDown={isMailing}
        maxWidth="sm"
        onClose={() => setMailing(false)}
        open={mailing}
      >
        <QuestionDialog
          CancelButtonProps={{onClick: () => setMailing(false)}}
          ConfirmButtonProps={{onClick: () => sendActivationMail(userId)}}
          loading={isMailing}
        >Voulez-vous renvoyer l'email d'activation à cet utilisateur ?</QuestionDialog>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  ...state.users.single,
  editState        : {...state.users.edit},
  editValidityState: {...state.users.editValidity},
  mailState        : {...state.users.mail},
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
  clean             : USERS_SINGLE_RESET_ACTION,
  getUser           : USERS_SINGLE_INIT_ACTION,
  sendActivationMail: USERS_MAIL_INIT_ACTION,
  setActiveUser     : USERS_EDIT_ACTIVE_INIT_ACTION,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UsersSinglePage);
