import React from 'react';

import {
  RouteComponentProps,
  Switch,
} from 'react-router';

import { CanRoute } from '../components';

import {
  UsersListPage,
  UsersSinglePage,
} from '../pages';

export default function ({match}: RouteComponentProps) {
  return (
    <Switch>
      <CanRoute
        I="view"
        a="Role"
        exact
        path={match.url}
        component={UsersListPage}
      />

      <CanRoute
        I="view"
        a="UsersSingle"
        exact
        path={`${match.url}/:userId`}
        render={props => {
          // contains uniteOpId param as an integer
          if (
            'userId' in props.match.params
            && (new RegExp(/^\d+$/)).test(props.match.params.userId)
          ) {
            return <UsersSinglePage {...props} />
          }

          return "Not Found.";
        }}
      />
    </Switch>
  );
}
