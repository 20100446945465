import React from 'react';

import {
  createStyles,
  WithStyles,
  withStyles
} from '@material-ui/core';

const styles = createStyles({
  root: {
    width: 84,
  },
  img: {
    width: '100%',
  },
});

export type ToolbarLogoProps =
  & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
  & WithStyles<typeof styles>;

export function ToolbarLogo({classes, ...other}: ToolbarLogoProps) {
  return (
    <div className={classes.root}>
      <img
        className={classes.img}
        {...other}
      />
    </div>
  );
};

export default withStyles(styles, {name: 'ToolbarLogo'})(ToolbarLogo);
