import React from 'react';

// import ContentLoader from 'react-content-loader';

import {
  CircularProgress,
} from '@material-ui/core';

// const uniteOpItemLoaderStyles = createStyles({
//   root: {
//     display: 'block',
//   },
//   loader: {
//     width: 288,
//     flex: '0 0 288',
//   }
// });

export interface TextLoaderProps {
  primary?: string;
}

// export const UniteOpItemLoader = withStyles(uniteOpItemLoaderStyles, {name: 'UniteOpItemLoader'})(({classes}: WithStyles<typeof uniteOpItemLoaderStyles>) => {
//   return (
//     <div className={classes.root}>
//       <ContentLoader
//         className={classes.loader}
//         height={62}
//         width={288}
//         speed={2}
//         primaryColor="#f3f3f3"
//         secondaryColor="#ecebeb"
//       >
//         <circle cx="36" cy="31" r="20" />
//         <rect x="72" y="11" rx="4" ry="4" width="200" height="14" />
//         <rect x="72" y="38" rx="4" ry="4" width="100" height="10" />
//       </ContentLoader>
//     </div>
//   );
// });

export default (({primary}: TextLoaderProps) => (
  <div className="d-flex flex-row align-items-center py-2">
    <CircularProgress size={20} />
    <span className="ml-3">{primary || 'Chargement en cours...'}</span>
  </div>
));
