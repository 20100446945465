import React from 'react';

import classNames from 'classnames';

import {
  SnackbarProvider as NotistackProvider,
  SnackbarProviderProps as NotistackProviderProps
} from 'notistack';

import {
  createStyles,
  withStyles,
  WithStyles,
} from '@material-ui/core';

const styles = createStyles({
  root: {
    // translate snackbar to avoid overlap AppHeader
    // transform: 'translateY(48px)',
  },
});

export type SnackbarProviderProps =
  & NotistackProviderProps
  & WithStyles<typeof styles>;

export const SnackbarProvider = withStyles(styles, {name: 'SnackbarProvider'})(({classes, className, ...other}: SnackbarProviderProps)  => (
  <NotistackProvider
    className={classNames(classes.root, className)}
    {...other}
  />
));

SnackbarProvider.defaultProps = {
  anchorOrigin: {
    vertical  : 'bottom',
    horizontal: 'center',
  },
  hideIconVariant: true,
  maxSnack       : 3,
};

export default SnackbarProvider;
