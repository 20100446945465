import React from 'react';

import { Link } from 'react-router-dom';

import classNames from 'classnames';

import {
  ListItem as MuiListItem,
  ListItemIcon as MuiListItemIcon,
  ListItemText as MuiListItemText,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from '@material-ui/core';

import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { ListItemProps } from '@material-ui/core/ListItem';

/**
 * `MenuItem` styles.
 * Provide active state for `ListItem` and `ListItemIcon`.
 */
const styles = ({palette, typography}: Theme) => createStyles({
  active: {},
  icon: {
    fontSize: typography.pxToRem(40),
    '&$active': {
      color: palette.primary.main,
    },
  },
  list: {
    '&$active': {
      paddingLeft: 0,
      borderLeft: `4px solid ${palette.primary.main}`,
    },
  },
});

/**
 * Override `MuiListItem` styles.
 */
const ListItem = withStyles(({palette}: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 'var(--spacer-3) 0',
    paddingLeft: 4,
    fontWeight: 500,
    textAlign: 'center',
    // add !important flag for the ripple backgroundColor (ripple uses 'currentColor' internaly)
    color: `${palette.action.active} !important`,
  },
}), {name: 'ListItem'})(MuiListItem);

/**
 * Override `MuiListItemIcon` styles.
 */
const ListItemIcon = withStyles(createStyles({
  root: {
    margin: 0,
  },
}), {name: 'ListItemIcon'})(MuiListItemIcon);

/**
 * Override `MuiListItemText` styles.
 */
const ListItemText = withStyles(createStyles({
  root: {
    padding: 0,
  },
  primary: {
    lineHeight: 1.25,
    fontFamily: 'acumin-pro-condensed, sans-serif',
  },
}), {name: 'ListItemText'})(MuiListItemText);

export interface MenuItemBaseProps {
  /**
   * Should be active state or not?
   */
  active : boolean;
  /**
   * Icon to display.
   */
  icon   : React.ComponentType<SvgIconProps>;
  /**
   * Text to display.
   */
  primary: string;
  /**
   * Path to the URL.
   */
  to     : string;
}

export type MenuItemProps =
  & MenuItemBaseProps
  & ListItemProps
  & WithStyles<typeof styles>;

/**
 * Inspired from: https://github.com/mui-org/material-ui/issues/9106#issuecomment-409882433
 */
export function MenuItem ({
  active,
  classes,
  icon: Icon,
  primary,
  to,
  ...other
}: MenuItemProps) {
  return (
    <ListItem
      button
      className={classNames(
        classes.list,
        {[classes.active]: active}
      )}
      component={props => (<Link {...{to}} {...props} />)}
      {...other}
    >
      <ListItemIcon>
        <Icon
          className={classNames(
            classes.icon,
            {[classes.active]: active}
          )}
        />
      </ListItemIcon>
      <ListItemText {...{primary}} />
    </ListItem>
  );
}

MenuItem.defaultProps = {
  active: false,
};

export default withStyles(styles, {name: 'MenuItem'})(MenuItem);
