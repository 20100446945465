import {
  Button,
  createStyles,
  Theme,
  withStyles,
} from '@material-ui/core';

export default withStyles(({palette}: Theme) => createStyles({
  root: {
    color: palette.action.disabled,
  },
}), {name: 'CancelButton'})(Button);
