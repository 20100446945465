import React from 'react';
import { render } from 'react-dom';

import { forkJoin, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import App from './App';
import Api from './configureApi';

import {
  RootState,
  UserState,
  initialRootState,
} from './store/reducers';

import '@ms-digital/react-design-system/dist/ms-digital.min.css';
import './assets/scss/index.scss';

/**
 * Must Have data before rendering React.
 *
 * 1. Get details about current logged in user.
 * 2. Get applications the user is allowed to access.
 * 3. Get permissions the user is allowed on IAM.
 * 4. Get applications.
 */
const initialState$: Observable<RootState> = forkJoin(
  Api.Iam.getSelfDetails(),          // 1
  Api.Iam.getSelfApplications(),     // 2
  Api.Services.getSelfPermissions(), // 3
  Api.Iam.getApplications(),         // 4
).pipe(
  map(([
    selfDetails,
    selfApplications,
    {iam_access, permissions},
    applications,
  ]) => {
    if (iam_access === false) {
      throw 'NOT AUTHORIZED';
    }

    // merge state with default user state
    const user: UserState = {
      ...selfDetails,
      applications: selfApplications,
      permissions : permissions.filter(p => p.startsWith('services.')),
    };

    // final initial state
    return {
      ...initialRootState(),
      applications,
      user,
    };
  }),
  take(1),
);

/**
 * DOM Node where to render the app.
 */
const rootNode = document.getElementById('root') as HTMLDivElement;

// render the app once initial state is ready
initialState$
  .subscribe({
    next: initialState => render(
      <App  {...{initialState}} />,
      rootNode
    ),
    error: () => {
      window.location.href = process.env.REACT_APP_ACCOUNT_LOGIN_URL;
    },
  });
