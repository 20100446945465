import {
  ActionType,
  createStandardAction,
} from 'typesafe-actions';

import { FormikActions } from 'formik';

import {
  USERS_EDIT_INIT,
  USERS_EDIT_SUCCESS,
  USERS_EDIT_FAILURE,
  USERS_EDIT_RESET,
  USERS_EDIT_VALIDITY_INIT,
  USERS_EDIT_VALIDITY_SUCCESS,
  USERS_EDIT_VALIDITY_FAILURE,
  USERS_EDIT_VALIDITY_RESET,
  USERS_EDIT_ACTIVE_INIT,
  USERS_EDIT_ACTIVE_SUCCESS,
  USERS_EDIT_ACTIVE_FAILURE,
  USERS_EDIT_ACTIVE_RESET,
  // USERS_EDIT_ACTIVE_CANCEL,
} from '../constants';

import {
  UserWithGroups,
  UsersUpdateRequest,
  UsersUpdateValidityRequest,
  UsersUpdateResponse,
} from '../../api/services';

import { UserFormValues } from '../../components/Form/UserForm';
import { UserValidityFormValues } from '../../components/Form/UserValidityForm';

// edit user info

export const USERS_EDIT_INIT_ACTION = createStandardAction(USERS_EDIT_INIT)<UsersUpdateRequest, {
  actions : FormikActions<UserFormValues>,
  previous: UserWithGroups,
  refresh : boolean,
}>();
export type UsersEditInitActionType = ActionType<typeof USERS_EDIT_INIT_ACTION>;

export const USERS_EDIT_SUCCESS_ACTION = createStandardAction(USERS_EDIT_SUCCESS)<UsersUpdateResponse, {
  diff: boolean;
}>();
export type UsersEditSuccessActionType = ActionType<typeof USERS_EDIT_SUCCESS_ACTION>;

export const USERS_EDIT_FAILURE_ACTION = createStandardAction(USERS_EDIT_FAILURE)();
export type UsersEditFailureActionType = ActionType<typeof USERS_EDIT_FAILURE_ACTION>;

export const USERS_EDIT_RESET_ACTION = createStandardAction(USERS_EDIT_RESET)();
export type UsersEditResetActionType = ActionType<typeof USERS_EDIT_RESET_ACTION>;

export type UsersEditAction =
  | UsersEditInitActionType
  | UsersEditSuccessActionType
  | UsersEditFailureActionType
  | UsersEditResetActionType;

// edit validity

export const USERS_EDIT_VALIDITY_INIT_ACTION = createStandardAction(USERS_EDIT_VALIDITY_INIT)<UsersUpdateValidityRequest, {
  actions : FormikActions<UserValidityFormValues>,
  previous: UserWithGroups,
}>();
export type UsersEditValidityInitActionType = ActionType<typeof USERS_EDIT_VALIDITY_INIT_ACTION>;

export const USERS_EDIT_VALIDITY_SUCCESS_ACTION = createStandardAction(USERS_EDIT_VALIDITY_SUCCESS)<UsersUpdateResponse, {
  diff: boolean;
}>();
export type UsersEditValiditySuccessActionType = ActionType<typeof USERS_EDIT_VALIDITY_SUCCESS_ACTION>;

export const USERS_EDIT_VALIDITY_FAILURE_ACTION = createStandardAction(USERS_EDIT_VALIDITY_FAILURE)();
export type UsersEditValidityFailureActionType  = ActionType<typeof USERS_EDIT_VALIDITY_FAILURE_ACTION>;

export const USERS_EDIT_VALIDITY_RESET_ACTION = createStandardAction(USERS_EDIT_VALIDITY_RESET)();
export type UsersEditValidityResetActionType  = ActionType<typeof USERS_EDIT_VALIDITY_RESET_ACTION>;

export type UsersEditValidityAction =
  | UsersEditValidityInitActionType
  | UsersEditValiditySuccessActionType
  | UsersEditValidityFailureActionType
  | UsersEditValidityResetActionType;

// edit active

export const USERS_EDIT_ACTIVE_INIT_ACTION = createStandardAction(USERS_EDIT_ACTIVE_INIT)<boolean, {
  refresh: boolean,
  userId : number,
}>();
export type UsersEditActiveInitActionType = ActionType<typeof USERS_EDIT_ACTIVE_INIT_ACTION>;

export const USERS_EDIT_ACTIVE_SUCCESS_ACTION = createStandardAction(USERS_EDIT_ACTIVE_SUCCESS)<UsersUpdateResponse>();
export type UsersEditActiveSuccessActionType = ActionType<typeof USERS_EDIT_ACTIVE_SUCCESS_ACTION>;

export const USERS_EDIT_ACTIVE_FAILURE_ACTION = createStandardAction(USERS_EDIT_ACTIVE_FAILURE)();
export type UsersEditActiveFailureActionType  = ActionType<typeof USERS_EDIT_ACTIVE_FAILURE_ACTION>;

export const USERS_EDIT_ACTIVE_RESET_ACTION = createStandardAction(USERS_EDIT_ACTIVE_RESET)();
export type UsersEditActiveResetActionType  = ActionType<typeof USERS_EDIT_ACTIVE_RESET_ACTION>;

// export const USERS_EDIT_ACTIVE_CANCEL_ACTION = createStandardAction(USERS_EDIT_ACTIVE_CANCEL)();
// export type UsersEditActiveCancelActionType  = ActionType<typeof USERS_EDIT_ACTIVE_CANCEL_ACTION>;

export type UsersEditActiveAction =
  | UsersEditActiveInitActionType
  | UsersEditActiveSuccessActionType
  | UsersEditActiveFailureActionType
  | UsersEditActiveResetActionType;
  // | UsersEditActiveCancelActionType;

export default {
  USERS_EDIT_INIT_ACTION,
  USERS_EDIT_SUCCESS_ACTION,
  USERS_EDIT_FAILURE_ACTION,
  USERS_EDIT_RESET_ACTION,
  USERS_EDIT_VALIDITY_INIT_ACTION,
  USERS_EDIT_VALIDITY_SUCCESS_ACTION,
  USERS_EDIT_VALIDITY_FAILURE_ACTION,
  USERS_EDIT_VALIDITY_RESET_ACTION,
  USERS_EDIT_ACTIVE_INIT_ACTION,
  USERS_EDIT_ACTIVE_SUCCESS_ACTION,
  USERS_EDIT_ACTIVE_FAILURE_ACTION,
  USERS_EDIT_ACTIVE_RESET_ACTION,
  // USERS_EDIT_ACTIVE_CANCEL_ACTION,
};
