import { UsersWithRoles } from '../../api/services';
import { UsersListAction } from '../actions/users-list-action';

import {
  USERS_LIST_INIT,
  USERS_LIST_SUCCESS,
  USERS_LIST_FAILURE,
  USERS_LIST_IAT,
  USERS_LIST_IAT_RESET,
} from '../constants';

export const getUsersListStateToProps = <T extends UsersListState>({
  error,
  iat,
  items,
  loading,
}: Pick<T, keyof UsersListState>) => ({error, iat, items, loading});

export interface UsersListState {
  error  : boolean;
  iat    : number;
  items  : UsersWithRoles;
  loading: boolean;
}

export const initialUsersListState: UsersListState = {
  error  : false,
  iat    : 0,
  items  : [],
  loading: true,
};

export default function (state: UsersListState = initialUsersListState, action: UsersListAction): UsersListState {
  switch (action.type) {
    case USERS_LIST_INIT:
      return {
        ...state,
        error  : false,
        loading: true,
      };
    case USERS_LIST_SUCCESS:
      return {
        ...state,
        error  : false,
        iat    : 0,
        items  : [...action.payload],
        loading: false,
      };
    case USERS_LIST_FAILURE:
      return {
        ...state,
        error  : true,
        loading: false,
      };
    case USERS_LIST_IAT:
      return {
        ...state,
        iat: (new Date()).getTime(),
      };
    case USERS_LIST_IAT_RESET:
      return {
        ...state,
        iat: 0,
      };
    default:
      return state;
  }
}
