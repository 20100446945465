import { UnitesOpWithServices } from '../../api/services';
import { UnitesOpListServicesAction } from '../actions/unites_op-list-action';

import {
  UNITES_OP_LIST_SERVICES_INIT,
  UNITES_OP_LIST_SERVICES_SUCCESS,
  UNITES_OP_LIST_SERVICES_FAILURE,
  UNITES_OP_LIST_IAT_RESET,
} from '../constants';

export const getUnitesOpListServicesStateToProps = <T extends UnitesOpListServicesState>({
  error,
  items,
  loading,
}: Pick<T, keyof UnitesOpListServicesState>) => ({error, items, loading});

export interface UnitesOpListServicesState {
  error  : boolean;
  items  : UnitesOpWithServices;
  loading: boolean;
}

export const initialUnitesOpListServicesState: UnitesOpListServicesState = {
  error  : false,
  items  : [],
  loading: true,
};

export default function (state: UnitesOpListServicesState = initialUnitesOpListServicesState, action: UnitesOpListServicesAction): UnitesOpListServicesState {
  switch (action.type) {
    case UNITES_OP_LIST_SERVICES_INIT:
      return {
        ...state,
        error  : false,
        loading: true,
      };
    case UNITES_OP_LIST_SERVICES_SUCCESS:
      return {
        ...state,
        error: false,
        items: [...action.payload]
          .map(({services, ...item}) => ({
            ...item,
            services: services.sort((a, b) => a.name.localeCompare(b.name)),
          }))
          .sort((a, b) => a.name.localeCompare(b.name)),
        loading: false,
      };
    case UNITES_OP_LIST_SERVICES_FAILURE:
      return {
        ...state,
        error  : true,
        loading: false,
      };
    // case UNITES_OP_LIST_IAT_RESET:
    //   return {...initialUnitesOpListServicesState};
    default:
      return state;
  }
}
