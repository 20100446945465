import { from } from 'rxjs';

import api from '../api';

import {
  SELF_PERMISSIONS_LIST,
  USERS_LIST,
  USERS_CREATE,
  USERS_READ,
  USERS_UPDATE,
  USERS_DELETE,
  UNITES_OP_LIST,
  UNITES_OP_READ,
  UNITES_OP_CREATE,
  UNITES_OP_UPDATE,
  SERVICES_LIST,
  SERVICES_CREATE,
  SERVICES_UPDATE,
  SERVICES_DELETE,
  USERS_ROLES_READ,
  ROLES_CREATE,
  ROLES_UPDATE,
  USERS_ACTIVATE_EMAIL,
} from './endpoints';

import {
  UsersCreateRequest,
  UsersUpdateRequest,
  UsersUpdateValidityRequest,
  UniteOpCreateRequest,
  UniteOpUpdateRequest,
  ServicesCreateRequest,
  ServicesUpdateRequest,
  UsersUpdateActiveRequest,
  RolesCreateRequest,
  RolesUpdateRequest,
  UsersUpdateGroupsRequest,
} from './requests';

import {
  SelfPermissionsListResponse,
  UsersListResponse,
  UsersCreateResponse,
  UsersReadResponse,
  UsersUpdateResponse,
  UnitesOpListResponse,
  UnitesOpReadResponse,
  UniteOpCreateResponse,
  UniteOpUpdateResponse,
  ServicesListResponse,
  ServicesCreateResponse,
  ServicesUpdateResponse,
  UsersRolesReadResponse,
  RolesCreateResponse,
  RolesUpdateResponse,
} from './responses';

export default class extends api {
  sendActivationMail(userId: number) {
    const endpoint = USERS_ACTIVATE_EMAIL(userId);

    return from(this.post(endpoint));
  }

  /**
   * Get permissions for the current user.
   */
  getSelfPermissions() {
    return from(this.get<SelfPermissionsListResponse>(SELF_PERMISSIONS_LIST));
  }

  createRole(data: RolesCreateRequest) {
    const body = JSON.stringify(data);

    return from(this.post<RolesCreateResponse>(ROLES_CREATE, {body}));
  }

  updateRole(roleId: number, data: RolesUpdateRequest) {
    const endpoint = ROLES_UPDATE(roleId);
    const options: RequestInit = {body: JSON.stringify(data)};

    return from(this.patch<RolesUpdateResponse>(endpoint, options));
  }

  deleteRole(roleId: number) {
    const endpoint = ROLES_UPDATE(roleId);

    return from(this.delete(endpoint));
  }

  getUsers() {
    return from(this.get<UsersListResponse>(USERS_LIST));
  }

  createUser(data: UsersCreateRequest) {
    const body = JSON.stringify(data);

    return from(this.post<UsersCreateResponse>(USERS_CREATE, {body}));
  }

  getUser(userId: number) {
    const endpoint = USERS_READ(userId);

    return from(this.get<UsersReadResponse>(endpoint));
  }

  getUserRoles(userId: number) {
    const endpoint = USERS_ROLES_READ(userId);

    return from(this.get<UsersRolesReadResponse>(endpoint));
  }

  updateUser(
    userId: number,
    data: UsersUpdateRequest | UsersUpdateValidityRequest | UsersUpdateActiveRequest | UsersUpdateGroupsRequest
  ) {
    const endpoint = USERS_UPDATE(userId);
    const options: RequestInit = {body: JSON.stringify(data)};

    return from(this.patch<UsersUpdateResponse>(endpoint, options));
  }

  deleteUser(userId: number) {
    const endpoint = USERS_DELETE(userId);

    return from(this.delete(endpoint));
  }

  getUnitesOp() {
    return from(this.get<UnitesOpListResponse>(UNITES_OP_LIST));
  }

  getUniteOp(uniteOpId: number) {
    const endpoint = UNITES_OP_READ(uniteOpId);

    return from(this.get<UnitesOpReadResponse>(endpoint));
  }

  createUniteOp(data: UniteOpCreateRequest) {
    const body = JSON.stringify(data);

    return from(this.post<UniteOpCreateResponse>(UNITES_OP_CREATE, {body}));
  }

  updateUniteOp(uniteOpId: number, data: UniteOpUpdateRequest) {
    const endpoint = UNITES_OP_UPDATE(uniteOpId);
    const body     = JSON.stringify(data);

    return from(this.patch<UniteOpUpdateResponse>(endpoint, {body}));
  }

  deleteUniteOp(uniteOpId: number) {
    const endpoint = UNITES_OP_UPDATE(uniteOpId);

    return from(this.delete(endpoint));
  }

  getServices() {
    return from(this.get<ServicesListResponse>(SERVICES_LIST));
  }

  createService(operational_unit: number, data: ServicesCreateRequest) {
    const body = JSON.stringify({
      ...data,
      operational_unit,
    } as ServicesCreateRequest);

    return from(this.post<ServicesCreateResponse>(SERVICES_CREATE, {body}));
  }

  updateService(serviceId: number, data: ServicesUpdateRequest) {
    const endpoint = SERVICES_UPDATE(serviceId);
    const body     = JSON.stringify(data);

    return from(this.patch<ServicesUpdateResponse>(endpoint, {body}));
  }

  deleteService(uniteOpId: number) {
    const endpoint = SERVICES_DELETE(uniteOpId);

    return from(this.delete(endpoint));
  }
}
