import {
  ActionType,
  createStandardAction,
} from 'typesafe-actions';

import { FormikActions } from 'formik';

import {
  ROLES_EDIT_INIT,
  ROLES_EDIT_SUCCESS,
  ROLES_EDIT_FAILURE,
  ROLES_EDIT_RESET,
} from '../constants';

import {
  UserRole,
  RolesUpdateRequest,
  RolesUpdateResponse,
} from '../../api/services';

import { RoleFormValues } from '../../components/Form/RoleForm';

export const ROLES_EDIT_INIT_ACTION = createStandardAction(ROLES_EDIT_INIT)<RolesUpdateRequest, {
  actions : FormikActions<RoleFormValues>,
  previous: UserRole,
}>();
export type RolesEditInitActionType = ActionType<typeof ROLES_EDIT_INIT_ACTION>;

export const ROLES_EDIT_SUCCESS_ACTION = createStandardAction(ROLES_EDIT_SUCCESS)<RolesUpdateResponse, {
  diff: boolean;
}>();
export type RolesEditSuccessActionType  = ActionType<typeof ROLES_EDIT_SUCCESS_ACTION>;

export const ROLES_EDIT_FAILURE_ACTION = createStandardAction(ROLES_EDIT_FAILURE)();
export type RolesEditFailureActionType  = ActionType<typeof ROLES_EDIT_FAILURE_ACTION>;

export const ROLES_EDIT_RESET_ACTION = createStandardAction(ROLES_EDIT_RESET)();
export type RolesEditResetActionType  = ActionType<typeof ROLES_EDIT_RESET_ACTION>;

export type RolesEditAction =
  | RolesEditInitActionType
  | RolesEditSuccessActionType
  | RolesEditFailureActionType
  | RolesEditResetActionType;

export default {
  ROLES_EDIT_INIT_ACTION,
  ROLES_EDIT_SUCCESS_ACTION,
  ROLES_EDIT_FAILURE_ACTION,
  ROLES_EDIT_RESET_ACTION,
};
