import { Epic, ofType } from 'redux-observable';

import { ReplaySubject, of } from 'rxjs';
import { catchError, concatMap, switchMap, tap, map } from 'rxjs/operators';

import Api from '../../configureApi';

import {
  Action,
  UsersEditInitActionType,
  USERS_EDIT_SUCCESS_ACTION,
  USERS_EDIT_RESET_ACTION,
  USERS_EDIT_FAILURE_ACTION,
  USERS_SINGLE_SUCCESS_ACTION,
  USERS_LIST_IAT_RESET_ACTION,
  UsersEditValidityInitActionType,
  USERS_EDIT_VALIDITY_SUCCESS_ACTION,
  USERS_EDIT_VALIDITY_FAILURE_ACTION,
  USERS_EDIT_VALIDITY_RESET_ACTION,
  USERS_LIST_SUCCESS_ACTION,
  USERS_LIST_IAT_ACTION,
  USERS_LIST_FAILURE_ACTION,
  UsersEditActiveInitActionType,
  USERS_EDIT_ACTIVE_SUCCESS_ACTION,
  USERS_EDIT_ACTIVE_RESET_ACTION,
  USERS_EDIT_ACTIVE_FAILURE_ACTION,
} from '../actions';

import {
  USERS_EDIT_INIT,
  USERS_EDIT_VALIDITY_INIT,
  USERS_EDIT_ACTIVE_INIT,
} from '../constants';
import { catchResponse } from '../../shared/helpers';

export const usersEditEpic: Epic<UsersEditInitActionType, any> = action$ => action$
  .pipe(
    ofType(USERS_EDIT_INIT),
    switchMap(({meta, payload, type}: UsersEditInitActionType) => {
      const stack$ = new ReplaySubject<Action>();

      const {id: userId}       = meta.previous;
      const {actions, refresh} = meta;

      // is form has been modified from previous item ?
      const next = {
        ...meta.previous,
        ...payload,
      };

      // strict compare previous with next
      if (JSON.stringify(meta.previous) === JSON.stringify(next)) {
        return of(
          USERS_EDIT_SUCCESS_ACTION(meta.previous, {diff: false}),
          USERS_EDIT_RESET_ACTION(),
        );
      }

      const updateUser$ = Api.Services.updateUser(userId, payload).pipe(
        catchError((response: Response) => catchResponse(response, {actions, type})),
        concatMap(item => Api.Services.getUserRoles(userId).pipe(
          map(roles => ({...item, roles})),
        )),
        tap(({roles, ...item}) => {
          stack$.next(USERS_EDIT_SUCCESS_ACTION(item, {diff: true}));
          stack$.next(USERS_SINGLE_SUCCESS_ACTION({...item, roles}));

          if (!refresh) {
            stack$.next(USERS_LIST_IAT_RESET_ACTION());
            stack$.next(USERS_EDIT_RESET_ACTION());
            stack$.complete();
          }
        }, () => {
          stack$.next(USERS_EDIT_FAILURE_ACTION());
          stack$.next(USERS_EDIT_RESET_ACTION());
          stack$.complete();
        }),
      );

      if (refresh) {
        return updateUser$
          .pipe(
            concatMap(() => Api.Services.getUsers().pipe(
              tap(items => {
                stack$.next(USERS_LIST_SUCCESS_ACTION(items));
                stack$.next(USERS_LIST_IAT_ACTION());
                stack$.next(USERS_EDIT_RESET_ACTION());
                stack$.complete();
              }, () => {
                stack$.next(USERS_LIST_FAILURE_ACTION());
                stack$.next(USERS_LIST_IAT_RESET_ACTION());
                stack$.complete();
              }),
            )),
            concatMap(() => stack$),
            catchError(() => {
              const {setSubmitting} = actions;
              setSubmitting(false);
              return stack$;
            }),
          );
      }

      return updateUser$
        .pipe(
          concatMap(() => stack$),
          catchError(() => {
            const {setSubmitting} = actions;
            setSubmitting(false);
            return stack$;
          }),
        );
    }),
  );

export const usersEditValidityEpic: Epic<UsersEditValidityInitActionType, any> = action$ => action$
  .pipe(
    ofType(USERS_EDIT_VALIDITY_INIT),
    switchMap(({meta, payload, type}: UsersEditValidityInitActionType) => {
      const stack$ = new ReplaySubject<Action>();

      const {actions, previous: user} = meta;
      const {id: userId}     = user;

      return Api.Services.updateUser(userId, payload).pipe(
        catchError((response: Response) => catchResponse(response, {actions, type})),
        concatMap(item => Api.Services.getUserRoles(userId).pipe(
          map(roles => ({...item, roles})),
        )),
        tap(({roles, ...item}) => {
          stack$.next(USERS_EDIT_VALIDITY_SUCCESS_ACTION(item, {diff: true}));
          stack$.next(USERS_SINGLE_SUCCESS_ACTION({...item, roles}));
          stack$.next(USERS_LIST_IAT_RESET_ACTION());
          stack$.next(USERS_EDIT_VALIDITY_RESET_ACTION());
          stack$.complete();
        }, () => {
          stack$.next(USERS_EDIT_VALIDITY_FAILURE_ACTION());
          stack$.next(USERS_EDIT_VALIDITY_RESET_ACTION());
          stack$.complete();
        }),
        concatMap(() => stack$),
        catchError(() => {
          const {setSubmitting} = actions;
          setSubmitting(false);
          return stack$;
        }),
      );
    }),
  );

export const usersEditActiveEpic: Epic<UsersEditActiveInitActionType, any> = action$ => action$
  .pipe(
    ofType(USERS_EDIT_ACTIVE_INIT),
    switchMap(({meta, payload: is_active}: UsersEditActiveInitActionType) => {
      const stack$            = new ReplaySubject<Action>();
      const {refresh, userId} = meta;

      const updateUser$ = Api.Services.updateUser(userId, {is_active}).pipe(
        concatMap(item => Api.Services.getUserRoles(userId).pipe(
          map(roles => ({...item, roles})),
        )),
        tap(({roles, ...item}) => {
          stack$.next(USERS_EDIT_ACTIVE_SUCCESS_ACTION(item));
          stack$.next(USERS_SINGLE_SUCCESS_ACTION({...item, roles}));

          if (!refresh) {
            stack$.next(USERS_LIST_IAT_RESET_ACTION());
            stack$.next(USERS_EDIT_ACTIVE_RESET_ACTION());
            stack$.complete();
          }
        }, () => {
          stack$.next(USERS_EDIT_ACTIVE_FAILURE_ACTION());
          stack$.next(USERS_EDIT_ACTIVE_RESET_ACTION());
          stack$.complete();
        }),
      );

      if (refresh) {
        return updateUser$
          .pipe(
            concatMap(() => Api.Services.getUsers().pipe(
              tap(items => {
                stack$.next(USERS_LIST_SUCCESS_ACTION(items));
                stack$.next(USERS_LIST_IAT_ACTION());
                stack$.next(USERS_EDIT_ACTIVE_RESET_ACTION());
                stack$.complete();
              }, () => {
                stack$.next(USERS_LIST_FAILURE_ACTION());
                stack$.next(USERS_LIST_IAT_RESET_ACTION());
                stack$.complete();
              }),
            )),
            concatMap(() => stack$),
            catchError(() => stack$),
          );
      }

      return updateUser$
        .pipe(
          concatMap(() => stack$),
          catchError(() => stack$),
        );
    }),
  );

export default [
  usersEditEpic,
  usersEditValidityEpic,
  usersEditActiveEpic,
];
