import { Epic, ofType } from 'redux-observable';

import { of, forkJoin } from 'rxjs';
import { concatMap, switchMap, catchError, withLatestFrom, map } from 'rxjs/operators';

import Api from '../../configureApi';

import {
  UnitesOpListAction,
  UNITES_OP_LIST_SUCCESS_ACTION,
  UNITES_OP_LIST_FAILURE_ACTION,
  UNITES_OP_LIST_IAT_ACTION,
  UnitesOpListServicesAction,
  UNITES_OP_LIST_SERVICES_SUCCESS_ACTION,
  UNITES_OP_LIST_SERVICES_FAILURE_ACTION,
} from '../actions/unites_op-list-action';

import { UNITES_OP_LIST_INIT, UNITES_OP_LIST_SERVICES_INIT } from '../constants';
import { RootState } from '../reducers';
import { Action } from '../actions';
import { UnitesOpWithServices, UniteOpWithServices } from '../../api';

export const unitesOpListEpic: Epic<UnitesOpListAction, UnitesOpListAction, RootState> = (action$, state$) => action$
  .pipe(
    ofType(UNITES_OP_LIST_INIT),
    withLatestFrom(state$),
    switchMap(([, state]) => {
      const {iat, items} = state.unites_op.list;

      if (iat !== 0) {
        return of(UNITES_OP_LIST_SUCCESS_ACTION(items));
      }

      return Api.Services.getUnitesOp()
        .pipe(
          concatMap(items => of(
            UNITES_OP_LIST_SUCCESS_ACTION(items),
            UNITES_OP_LIST_IAT_ACTION(),
          )),
          catchError(() => of(
            UNITES_OP_LIST_FAILURE_ACTION(),
          ))
        );
    }),
  );


export const unitesOpListServicesEpic: Epic<UnitesOpListServicesAction, any, RootState> = action$ => action$
  .pipe(
    ofType(UNITES_OP_LIST_SERVICES_INIT),
    switchMap(() => {
      return forkJoin(
        Api.Services.getUnitesOp(),
        Api.Services.getServices(),
      ).pipe(
        map(([unites_op, services]) => {
          const unitesOps: UnitesOpWithServices = [];

          unites_op.map(({id, name}) => {
            // get services that belongs to the region
            const servicesInUo = services
              .filter(({operational_unit}) => operational_unit === id)
              .map(({id, name}) => ({id, name}));
            // create a unite op object
            const uniteOp: UniteOpWithServices = {id, name, services: servicesInUo};

            // add zone to the list
            unitesOps.push(uniteOp);
          });

          return UNITES_OP_LIST_SERVICES_SUCCESS_ACTION(unitesOps);
        }),
        catchError(() => of(
          UNITES_OP_LIST_SERVICES_FAILURE_ACTION(),
        )),
      )
    }),
  );

export default [
  unitesOpListEpic,
  unitesOpListServicesEpic,
];
