import { combineReducers } from 'redux';

import add, { initialUnitesOpAddState, UnitesOpAddState } from './unites_op-add-reducer';
import remove, { initialUnitesOpDeleteState, UnitesOpDeleteState } from './unites_op-delete-reducer';
import edit, { initialUnitesOpEditState, UnitesOpEditState } from './unites_op-edit-reducer';
import list, { initialUnitesOpListState, UnitesOpListState } from './unites_op-list-reducer';
import listServices, { initialUnitesOpListServicesState, UnitesOpListServicesState } from './unites_op-list_services-reducer';
import single, { initialUnitesOpSingleState, UnitesOpSingleState } from './unites_op-single-reducer';

/**
 * To delete an Unité Opérationnelle,
 * use the key `remove` because `delete` is a reserved word.
 */
export type UnitesOpState =
  & {add: UnitesOpAddState}
  & {remove: UnitesOpDeleteState}
  & {edit: UnitesOpEditState}
  & {list: UnitesOpListState}
  & {listServices: UnitesOpListServicesState}
  & {single: UnitesOpSingleState};

export const initialUnitesOpState: UnitesOpState = {
  add         : {...initialUnitesOpAddState},
  remove      : {...initialUnitesOpDeleteState},
  edit        : {...initialUnitesOpEditState},
  list        : {...initialUnitesOpListState},
  listServices: {...initialUnitesOpListServicesState},
  single      : {...initialUnitesOpSingleState},
};

export default combineReducers<UnitesOpState>({
  add,
  remove,
  edit,
  list,
  listServices,
  single,
});
