import { combineReducers } from 'redux';

import add, { initialServicesAddState, ServicesAddState } from './services-add-reducer';
import remove, { initialServicesDeleteState, ServicesDeleteState } from './services-delete-reducer';
import edit, { initialServicesEditState, ServicesEditState } from './services-edit-reducer';
// import list, { initialServicesListState, ServicesListState } from './unites_op-list-reducer';
// import single, { initialServicesSingleState, ServicesSingleState } from './unites_op-single-reducer';

export type ServicesState =
  & {add: ServicesAddState}
  & {remove: ServicesDeleteState}
  & {edit: ServicesEditState};
  // & {list: ServicesListState}
  // & {single: ServicesSingleState};

export const initialServicesState: ServicesState = {
  add   : {...initialServicesAddState},
  remove: {...initialServicesDeleteState},
  edit  : {...initialServicesEditState},
  // list  : {...initialServicesListState},
  // single: {...initialServicesSingleState},
};

export default combineReducers<ServicesState>({
  add,
  remove,
  edit,
  // list,
  // single,
});
