import { UnitesOpDeleteAction } from '../actions';

import {
  UNITES_OP_DELETE_INIT,
  UNITES_OP_DELETE_SUCCESS,
  UNITES_OP_DELETE_FAILURE,
  UNITES_OP_DELETE_RESET,
} from '../constants';

export const getUnitesOpDeleteStateToProps = <T extends UnitesOpDeleteState>({
  error,
  loading,
  success,
}: Pick<T, keyof UnitesOpDeleteState>) => ({error, loading, success});

export interface UnitesOpDeleteState {
  error  : boolean;
  loading: boolean;
  success: boolean;
}

export const initialUnitesOpDeleteState: UnitesOpDeleteState = {
  error  : false,
  loading: false,
  success: false,
};

export default function (state: UnitesOpDeleteState = initialUnitesOpDeleteState, action: UnitesOpDeleteAction): UnitesOpDeleteState {
  switch (action.type) {
    case UNITES_OP_DELETE_INIT:
      return {
        ...initialUnitesOpDeleteState,
        loading: true,
      };
    case UNITES_OP_DELETE_SUCCESS:
      return {
        error  : false,
        loading: false,
        success: true,
      };
    case UNITES_OP_DELETE_FAILURE:
      return {
        ...initialUnitesOpDeleteState,
        error: true,
      };
    case UNITES_OP_DELETE_RESET:
      return {...initialUnitesOpDeleteState};
    default:
      return state;
  }
}
