import {
  FormikActions,
  FormikValues,
} from 'formik';

export function sortBoolean(a: boolean, b: boolean): number {
  return a === b ? 0 : a ? -1 : 1;
}

/**
 * Catch a `Response`.
 *
 * @param {Response} response The response to catch
 * @param {FormikActions} actions Formik actions to be used
 */
export function catchResponse<T = FormikValues>(
  response: Response,
  options: {
    actions: FormikActions<T>,
    type   : string,
  },
) {
  const {actions, type} = options;

  const {
    setErrors,
    setStatus,
    setSubmitting,
  } = actions;

  const statusKey = `STATUS_${type}`;

  // fetch failed (network issue)
  // if (response instanceof TypeError) {
  //   setStatus({
  //     key    : statusKey,
  //     message: 'Un problème réseau est survenue',
  //     variant: 'error',
  //   });

  //   setSubmitting(false);

  //   throw response;
  // }

  // fetch success but it is a bad HTTP response code
  if (response.status === 400) {
    return response.json<{[key: string]: string[]}>()
      .then(errors => {
        if ('__all__' in errors) {
          setStatus({
            key    : statusKey,
            message: errors['__all__'].join('; '),
            variant: 'error',
          });
        } else if ('non_field_errors' in errors) {
          setStatus({
            key    : statusKey,
            message: errors['non_field_errors'].join('; '),
            variant: 'error',
          });
        } else {
          const fieldsErrors = Object.keys(errors)
            .map(key => ({[key]: errors[key as string].join('; ')}))
            .reduce((pv, cv) => ({...pv, ...cv}), {} as T);

          setErrors(fieldsErrors);
        }

        // setSubmitting(false);
        setStatus();

        throw response;
      });
  }

  // default if common error
  setStatus({
    key    : statusKey,
    message: 'Une erreur est survenue',
    variant: 'error',
  });

  // setSubmitting(false);
  setStatus();

  throw response;
}
