import React, { useEffect } from 'react';

import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { useSnackbar } from 'notistack';

import { QuestionDialog } from '../../components';
import { QuestionDialogProps } from '../../components/Dialog/QuestionDialog';

import { UNITES_OP_DELETE_INIT_ACTION, Action } from '../../store/actions';
import { RootState, UnitesOpDeleteState } from '../../store/reducers';

import { WithPropsFromState } from '../../shared/types';

export interface UnitesOpDeleteDialogBaseProps {
  deleteUniteOp: typeof UNITES_OP_DELETE_INIT_ACTION;
  id           : number;
}

export type UnitesOpDeleteDialogProps =
  & UnitesOpDeleteDialogBaseProps
  & QuestionDialogProps
  & WithPropsFromState<UnitesOpDeleteState>;

export function UnitesOpDeleteDialog({
  deleteUniteOp,
  error,
  id,
  success,
  ...other
}: UnitesOpDeleteDialogProps) {
  const ConfirmButtonProps: QuestionDialogProps['ConfirmButtonProps'] = {
    onClick: () => deleteUniteOp(id),
  };

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  useEffect(() => {
    if (error) {
      enqueueSnackbar(`Impossible de supprimer l'unité opérationnelle`, {
        key             : 'UNITES_OP_DELETE_FAILURE',
        preventDuplicate: true,
        variant         : 'error',
      });
    }
  }, [error]);

  useEffect(() => () => {
    closeSnackbar('UNITES_OP_DELETE_FAILURE');
  }, []);

  return (
    <QuestionDialog
      {...{ConfirmButtonProps}}
      {...other}
    >
      <p>Voulez-vous supprimer cette unité opérationnelle ?</p>
    </QuestionDialog>
  );
}

const mapStateToProps = (state: RootState) => ({
  ...state.unites_op.remove,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
  deleteUniteOp: UNITES_OP_DELETE_INIT_ACTION,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UnitesOpDeleteDialog);
