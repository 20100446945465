import React, { useEffect, useState } from 'react';

import { RouteComponentProps, Redirect } from 'react-router';

import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import {
  Dialog,
  Fab,
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';

import { WithPropsFromState } from '../../shared/types';

import { Action, UNITES_OP_LIST_INIT_ACTION } from '../../store/actions';

import {
  getUnitesOpListStateToProps,
  RootState,
  UnitesOpAddState,
  UnitesOpListState,
} from '../../store/reducers';

import {
  Can,
  CenterContainer,
  ListUnitesOp,
  PageError,
  PageTitle,
} from '../../components';

import { UnitesOpAddDialog } from '.';
import { LocationDescriptor } from 'history';
import { UniteOp } from '../../api/services';

export interface UnitesOpListPageBaseProps
  extends WithPropsFromState<UnitesOpListState> {
    addState   : UnitesOpAddState;
    getUnitesOp: typeof UNITES_OP_LIST_INIT_ACTION;
  }

export type UnitesOpListPageProps =
  & UnitesOpListPageBaseProps
  & RouteComponentProps;

export function UnitesOpListPage({
  addState,
  getUnitesOp,
  match,
  ...other
}: UnitesOpListPageProps) {
  // const {enqueueSnackbar} = useSnackbar();

  const [redirect, setRedirect]           = useState<null | LocationDescriptor<UniteOp>>(null);
  const [openAddDialog, setOpenAddDialog] = useState(false);

  const {error, loading}          = other;
  const {item, loading: isAdding} = addState;

  useEffect(() => {
    getUnitesOp();
  }, []);

  // an item in addState means all went good when creating a new U.O
  // so show good ui stuff and redirect to the single U.O page
  useEffect(() => {
    if (!!item) {
      setOpenAddDialog(false);
      // enqueueSnackbar(`Unité opérationnelle ${item.name} créée avec succès`, {variant: 'success', persist: false});
      setRedirect({
        pathname: `${match.url}/${item.id}`,
        state   : {...item, services: 0, users: 0},
      });
    }
  }, [item]);

  // redirect to the single U.O if redirect exists
  if (!!redirect) {
    return <Redirect
      push
      to={redirect}
    />;
  }

  // an error occurred
  if (!loading && error) {
    return <PageError onClick={() => getUnitesOp()} />;
  }

  return (
    <>
      <div className="d-flex align-items-start justify-content-center">
        <CenterContainer>
          <PageTitle
            primary="Unités opérationnelles"
            secondary="Liste des unités opérationnelles"
          />

          <ListUnitesOp
            to={item => ({
              pathname: `${match.url}/${item.id}`,
              state: {...item},
            })}
            {...getUnitesOpListStateToProps(other)}
          />
        </CenterContainer>

        <Can I="add" an="OU">
          {() => (
            <div className="mt-6 ml-4">
              <Fab color="primary" aria-label="Add" onClick={() => setOpenAddDialog(true)}>
                <AddIcon />
              </Fab>
            </div>
          )}
        </Can>
      </div>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={openAddDialog}
        disableBackdropClick={isAdding}
        disableEscapeKeyDown={isAdding}
        onClose={() => setOpenAddDialog(false)}
      >
        <UnitesOpAddDialog
          CancelButtonProps={{onClick: () => setOpenAddDialog(false)}}
          title="Ajouter une unité opérationnelle"
        />
      </Dialog>
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  ...state.unites_op.list,
  addState: {...state.unites_op.add},
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
  getUnitesOp: UNITES_OP_LIST_INIT_ACTION,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UnitesOpListPage);
