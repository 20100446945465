import { Epic, ofType } from 'redux-observable';

import { ReplaySubject, of } from 'rxjs';
import { catchError, concatMap, switchMap, tap } from 'rxjs/operators';

import Api from '../../configureApi';

import {
  Action,
  UnitesOpEditInitActionType,
  UNITES_OP_EDIT_SUCCESS_ACTION,
  UNITES_OP_EDIT_RESET_ACTION,
  UNITES_OP_EDIT_FAILURE_ACTION,
  UNITES_OP_SINGLE_SUCCESS_ACTION,
  UNITES_OP_LIST_IAT_RESET_ACTION,
} from '../actions';

import { UNITES_OP_EDIT_INIT } from '../constants';
import { catchResponse } from '../../shared/helpers';

export const unitesOpEditEpic: Epic<UnitesOpEditInitActionType, any> = action$ => action$
  .pipe(
    ofType(UNITES_OP_EDIT_INIT),
    switchMap(({meta, payload, type}: UnitesOpEditInitActionType) => {
      const stack$      = new ReplaySubject<Action>();

      const {actions, previous} = meta;
      const {id: uniteOpId}     = previous;

      // name is the same, no change
      if ('name' in payload && payload.name === previous.name) {
        return of(
          UNITES_OP_EDIT_SUCCESS_ACTION(previous, {diff: false}),
          UNITES_OP_EDIT_RESET_ACTION(),
        );
      }

      return Api.Services.updateUniteOp(uniteOpId, payload).pipe(
        catchError((response: Response) => catchResponse(response, {actions, type})),
        tap(item => {
          stack$.next(UNITES_OP_EDIT_SUCCESS_ACTION(item, {diff: true}));
          stack$.next(UNITES_OP_SINGLE_SUCCESS_ACTION(item));
          stack$.next(UNITES_OP_LIST_IAT_RESET_ACTION());
          stack$.next(UNITES_OP_EDIT_RESET_ACTION());
          stack$.complete();
        }, () => {
          stack$.next(UNITES_OP_EDIT_FAILURE_ACTION());
          stack$.next(UNITES_OP_EDIT_RESET_ACTION());
          stack$.complete();
        }),
        concatMap(() => stack$),
        catchError(() => {
          const {setSubmitting} = actions;
          setSubmitting(false);
          return stack$;
        }),
      );
    }),
  );

export default [
  unitesOpEditEpic,
];
