import { Epic, ofType } from 'redux-observable';

import { ReplaySubject, forkJoin } from 'rxjs';
import { catchError, concatMap, switchMap, tap } from 'rxjs/operators';

import Api from '../../configureApi';

import {
  Action,
  RolesDeleteInitActionType,
  ROLES_DELETE_SUCCESS_ACTION,
  ROLES_DELETE_RESET_ACTION,
  ROLES_DELETE_FAILURE_ACTION,
  USERS_SINGLE_SUCCESS_ACTION,
  USERS_LIST_IAT_RESET_ACTION,
  USERS_SINGLE_FAILURE_ACTION,
} from '../actions';

import { ROLES_DELETE_INIT } from '../constants';

export const rolesDeleteEpic: Epic<RolesDeleteInitActionType, any> = action$ => action$
  .pipe(
    ofType(ROLES_DELETE_INIT),
    switchMap(({meta, payload: roleId}: RolesDeleteInitActionType) => {
      const stack$ = new ReplaySubject<Action>();
      const {userId} = meta;

      return Api.Services.deleteRole(roleId).pipe(
        tap(() => {
          stack$.next(ROLES_DELETE_SUCCESS_ACTION());
          stack$.next(USERS_LIST_IAT_RESET_ACTION());
          stack$.next(ROLES_DELETE_RESET_ACTION());
        }, () => {
          stack$.next(ROLES_DELETE_FAILURE_ACTION());
          stack$.next(ROLES_DELETE_RESET_ACTION());
          stack$.complete();
        }),
        concatMap(() => forkJoin(
          Api.Services.getUser(userId),
          Api.Services.getUserRoles(userId),
        ).pipe(
          tap(([user, roles]) => {
            stack$.next(USERS_SINGLE_SUCCESS_ACTION({...user, roles}));
            stack$.complete();
          }, () => {
            stack$.next(USERS_SINGLE_FAILURE_ACTION());
            stack$.next(USERS_LIST_IAT_RESET_ACTION());
            stack$.complete();
          }),
        )),
        concatMap(() => stack$),
        catchError(() => stack$),
      );
    }),
  );

export default [
  rolesDeleteEpic,
];
