import { UserWithGroups } from '../../api/services';
import { UsersEditActiveAction } from '../actions';

import {
  USERS_EDIT_ACTIVE_INIT,
  USERS_EDIT_ACTIVE_SUCCESS,
  USERS_EDIT_ACTIVE_FAILURE,
  USERS_EDIT_ACTIVE_RESET,
} from '../constants';

export const getUsersEditActiveStateToProps = <T extends UsersEditActiveState>({
  error,
  item,
  loading,
}: Pick<T, keyof UsersEditActiveState>) => ({error, item, loading});

export interface UsersEditActiveState {
  error  : boolean;
  item   : null | UserWithGroups;
  loading: boolean;
}

export const initialUsersEditActiveState: UsersEditActiveState = {
  error  : false,
  item   : null,
  loading: false,
};

export default function (state: UsersEditActiveState = initialUsersEditActiveState, action: UsersEditActiveAction): UsersEditActiveState {
  switch (action.type) {
    case USERS_EDIT_ACTIVE_INIT:
      return {
        ...initialUsersEditActiveState,
        loading: true,
      };
    case USERS_EDIT_ACTIVE_SUCCESS:
      return {
        ...initialUsersEditActiveState,
        error  : false,
        item   : {...action.payload},
        loading: false,
      };
    case USERS_EDIT_ACTIVE_FAILURE:
      return {
        ...initialUsersEditActiveState,
        error: true,
      };
    case USERS_EDIT_ACTIVE_RESET:
      return {...initialUsersEditActiveState};
    default:
      return state;
  }
}
