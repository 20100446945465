import React, { useEffect, useState } from 'react';

import { RouteComponentProps } from 'react-router';

import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { useSnackbar } from 'notistack';

import {
  createStyles,
  Dialog,
  DialogContent,
  Fab as MuiFab,
  TablePagination,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core';

import { TablePaginationProps } from '@material-ui/core/TablePagination';

import AddIcon from '@material-ui/icons/Add';

import {
  FullWidthContainer,
  PageError,
  PageTitle,
  StickyColumn,
  TableUsersList,
  TextLoader,
  QuestionDialog,
  Can,
} from '../../components';

import { UsersAddDialog, UsersEditDialog } from '.';

import { TableUsersListProps } from '../../components/Table/TableUsersList';

import { USERS_LIST_INIT_ACTION, Action, USERS_SINGLE_INIT_ACTION, USERS_SINGLE_RESET_ACTION, USERS_EDIT_ACTIVE_INIT_ACTION, USERS_MAIL_INIT_ACTION, USERS_DELETE_INIT_ACTION } from '../../store/actions';
import {
  getUsersListStateToProps,
  RootState,
  UsersAddState,
  UsersListState,
  UsersEditState,
  UsersSingleState,
  UsersEditActiveState,
  UsersMailState,
  UsersDeleteState,
} from '../../store/reducers';

import { WithPropsFromState } from '../../shared/types';
import { UserWithRoles } from '../../api';

const Fab = withStyles(createStyles({
  root: {
    position: 'sticky',
    top     : 128,
    left    : 0,
  },
}), {name: 'Fab'})(MuiFab);

const FixedBottomTablePagination = withStyles(({palette}: Theme) => createStyles({
  root: {
    position       : 'fixed',
    right          : 0,
    bottom         : 0,
    left           : 104,
    padding        : '0 var(--spacer-4) !important',
    backgroundColor: palette.common.white,
    borderTop      : `1px solid ${palette.divider}`,
  },
}), {name: 'FixedBottomTablePagination'})(TablePagination);

const styles = createStyles({
  root: {
    marginBottom: 56,
  },
  tableWrapper: {
    overflow   : 'auto',
    marginRight: 88,
  },
});

export interface UsersListPageBaseProps {
  addState          : UsersAddState;
  cleanSingle       : typeof USERS_SINGLE_RESET_ACTION;
  deleteState       : UsersDeleteState;
  deleteUser        : typeof USERS_DELETE_INIT_ACTION;
  editState         : UsersEditState;
  editActiveState   : UsersEditActiveState;
  getUsers          : typeof USERS_LIST_INIT_ACTION;
  getUser           : typeof USERS_SINGLE_INIT_ACTION;
  mailState         : UsersMailState;
  setActiveUser     : typeof USERS_EDIT_ACTIVE_INIT_ACTION;
  sendActivationMail: typeof USERS_MAIL_INIT_ACTION;
  singleState       : UsersSingleState;
}

export type UsersListPageProps =
  & UsersListPageBaseProps
  & RouteComponentProps
  & WithPropsFromState<UsersListState>
  & WithStyles<typeof styles>;

export function UsersListPage({
  addState,
  classes,
  cleanSingle,
  deleteState,
  deleteUser,
  editState,
  editActiveState,
  getUsers,
  getUser,
  mailState,
  match,
  setActiveUser,
  sendActivationMail,
  singleState,
  ...other
}: UsersListPageProps) {
  // page state
  const {error, items, loading} = other;

  // pagination state
  const [page, setPage]               = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  // add state
  const {item: addedItem, loading: isAdding} = addState;
  const [addDialog, setAddDialog]            = useState(false);

  // edit active state
  const {loading: isActivating}         = editActiveState;
  const [activeDialog, setActiveDialog] = useState<{
    open : boolean;
    data?: UserWithRoles;
  }>({
    open: false,
  });

  // edit state
  const {loading: isEditing}        = editState;
  const [editDialog, setEditDialog] = useState<null | number>(null);

  // delete state
  const {
    error: failDelete,
    loading: isDeleting,
    success: hadDeleted,
  } = deleteState;
  const [removeDialog, setRemoveDialog] = useState<null | number>(null);

  // mail state
  const [mailing, setMailing] = useState<{
    open  : boolean;
    userId?: number;
  }>({
    open: false,
  });
  const {
    error: failMailing,
    loading: isMailing,
    success: hasMailed,
  }  = mailState;

  // snackbars
  const {enqueueSnackbar} = useSnackbar();

  // pagination page change
  const handleChangePage: TablePaginationProps['onChangePage'] = (e, pageIndex) => {
    setPage(pageIndex);
  };

  // pagination rows change
  const handleChangeRowsPerPage: TablePaginationProps['onChangeRowsPerPage'] = ({target}) => {
    setRowsPerPage(parseInt(target.value, 10));
  };

  // callback on user kebab "Afficher" click
  const handleKebabUser: TableUsersListProps['toUser'] = ({id, avatar, first_name, last_name}) => ({
    pathname: `${match.url}/${id}`,
    state   : {avatar, first_name, last_name},
  });

  // set active/inactive local state for switch
  const handleActiveClick: TableUsersListProps['onActiveClick'] = data => {
    setActiveDialog({
      open: true,
      data,
    });
  };

  // we want to edit a user
  const handleEditClick: TableUsersListProps['onEditClick'] = ({id: userId}) => {
    setEditDialog(userId);
  };

  // we want to delete a user
  const handleRemoveClick: TableUsersListProps['onRemoveClick'] = ({id: userId}) => {
    setRemoveDialog(userId);
  };

  // fetch users on mount
  useEffect(() => {
    getUsers();
  }, []);

  // a user has been added
  useEffect(() => {
    if (!!addedItem) {
      setAddDialog(false);
      setMailing({open: true, userId: addedItem.id});

      enqueueSnackbar('Utilisateur ajouté avec succès', {
        key             : 'USERS_LIST_ADD_SUCCESS',
        persist         : false,
        preventDuplicate: true,
        variant         : 'success',
      });
    }
  }, [addedItem]);

  // mail
  useEffect(() => {
    if (failMailing) {
      enqueueSnackbar(`Impossible d'envoyer l'email d'activation`, {
        key             : 'USERS_MAIL_FAILURE',
        persist         : false,
        preventDuplicate: true,
        variant         : 'error',
      });
    }

    if (hasMailed) {
      enqueueSnackbar(`Email d'activation envoyé aves succès`, {
        key             : 'USERS_MAIL_SUCCESS',
        persist         : false,
        preventDuplicate: true,
        variant         : 'success',
      });

      setMailing({...mailing, open: false});
    }
  }, [mailState]);

  // edit item success
  useEffect(() => {
    if (!editState.error && !!editState.item) {
      setEditDialog(null);

      enqueueSnackbar(`Utilisateur modifié avec succès`, {
        key             : 'USERS_EDIT_SUCCESS',
        persist         : false,
        preventDuplicate: true,
        variant         : 'success',
      });
    }
  }, [editState]);

  // active item success
  useEffect(() => {
    if (editActiveState.error) {
      enqueueSnackbar(`Impossible de modifier le statut de l'utilisateur`, {
        key             : 'USERS_EDIT_ACTIVE_FAILURE',
        persist         : false,
        preventDuplicate: true,
        variant         : 'error',
      });
    }

    if (!editActiveState.error && !!editActiveState.item) {
      setActiveDialog({...activeDialog, open: false});

      enqueueSnackbar(`Statut de l'utilisateur modifié avec succès`, {
        key             : 'USERS_EDIT_ACTIVE_SUCCESS',
        persist         : false,
        preventDuplicate: true,
        variant         : 'success',
      });
    }
  }, [editActiveState]);

  // deleted user
  useEffect(() => {
    if (failDelete) {
      enqueueSnackbar(`Impossible de supprimer l'utilisateur`, {
        key             : 'USERS_DELETE_FAILURE',
        persist         : false,
        preventDuplicate: true,
        variant         : 'error',
      });
    }

    if (hadDeleted) {
      setRemoveDialog(null);

      enqueueSnackbar('Utilisateur supprimé avec succès', {
        key             : 'USERS_DELETE_SUCCESS',
        persist         : false,
        preventDuplicate: true,
        variant         : 'success',
      });
    }
  }, [deleteState]);

  // error while getting items
  if (!loading && error) {
    return <PageError onClick={() => getUsers()} />
  }

  return (
    <div className={classes.root}>
      <FullWidthContainer>
        <PageTitle
          primary="Utilisateurs"
          secondary="Liste des utilisateurs"
        />

        <Can I="add" a="User">
          {() => (
            <StickyColumn right={24}>
              <Fab
                color="primary"
                onClick={() => setAddDialog(true)}
                size="large"
                type="button"
              >
                <AddIcon />
              </Fab>
            </StickyColumn>
          )}
        </Can>

        <div className={classes.tableWrapper}>
          <TableUsersList
            onActiveClick={handleActiveClick}
            onEditClick={handleEditClick}
            onRemoveClick={handleRemoveClick}
            toUser={handleKebabUser}
            {...{page, rowsPerPage}}
            {...getUsersListStateToProps(other)}
          />
        </div>
      </FullWidthContainer>

      <FixedBottomTablePagination
        labelRowsPerPage="Lignes par page"
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="footer"
        count={items.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

      <Dialog
        disableBackdropClick={isAdding}
        disableEscapeKeyDown={isAdding}
        fullWidth
        maxWidth="sm"
        onClose={() => setAddDialog(false)}
        open={addDialog}
      >
        <UsersAddDialog
          CancelButtonProps={{
            onClick: () => setAddDialog(false),
          }}
          title="Ajouter un utilisateur"
          withValidity={true}
        />
      </Dialog>

      <Dialog
        disableBackdropClick={isEditing}
        disableEscapeKeyDown={isEditing}
        fullWidth
        maxWidth="sm"
        onClose={() => {
          setEditDialog(null);
        }}
        onEntering={() => {
          if (editDialog) {
            getUser(editDialog);
          }
        }}
        onExited={cleanSingle}
        open={editDialog !== null}
      >
        {singleState.error && (
          <>
            <DialogContent>
              <PageError onClick={() => getUser(editDialog as number)} />
            </DialogContent>
          </>
        )}

        {singleState.loading && (
          <DialogContent>
            <TextLoader primary="Récupération des informations de l'utilisteur..." />
          </DialogContent>
        )}

        {singleState.item && (
          <UsersEditDialog
            CancelButtonProps={{onClick: () => setEditDialog(null)}}
            title="Informations personnelles"
            refresh={true}
            user={singleState.item}
          />
        )}
      </Dialog>

      <Dialog
        disableBackdropClick={isActivating}
        disableEscapeKeyDown={isActivating}
        fullWidth
        maxWidth="sm"
        onClose={() => setActiveDialog({...activeDialog, open: false})}
        onExited={() => setActiveDialog({open: false})}
        open={activeDialog.open}
      >
        {activeDialog.data ? (
          <QuestionDialog
            CancelButtonProps={{
              onClick: () => setActiveDialog({...activeDialog, open: false}),
            }}
            ConfirmButtonProps={{
              onClick: () => {
                if (activeDialog.data) {
                  setActiveUser(!activeDialog.data.is_active, {refresh: true, userId: activeDialog.data.id});
                }
              },
            }}
            loading={isActivating}
          >Voulez-vous {activeDialog.data.is_active ? 'désactiver' : 'activer'} cet utilisateur ?</QuestionDialog>
        ) : <></>}
      </Dialog>

      <Dialog
        disableBackdropClick={isMailing}
        disableEscapeKeyDown={isMailing}
        maxWidth="sm"
        onClose={() => setMailing({...mailing, open: false})}
        onExited={() => setMailing({open: false})}
        open={mailing.open}
      >
        <QuestionDialog
          CancelButtonProps={{onClick: () => setMailing({...mailing, open: false})}}
          ConfirmButtonProps={{onClick: () => {
            if (mailing.userId) {
              sendActivationMail(mailing.userId);
            }
          }}}
          loading={isMailing}
        >Voulez-vous envoyer l'email d'activation à cet utilisateur ?</QuestionDialog>
      </Dialog>

      <Dialog
        disableBackdropClick={isDeleting}
        disableEscapeKeyDown={isDeleting}
        fullWidth
        maxWidth="sm"
        onClose={() => setRemoveDialog(null)}
        onExited={() => setRemoveDialog(null)}
        open={removeDialog !== null}
      >
          <QuestionDialog
            CancelButtonProps={{
              onClick: () => setRemoveDialog(null),
            }}
            ConfirmButtonProps={{
              onClick: () => {
                if (removeDialog !== null) {
                  deleteUser(removeDialog);
                }
              },
            }}
            loading={isDeleting}
          >Voulez-vous supprimer cet utilisateur ?</QuestionDialog>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  ...state.users.list,
  addState       : {...state.users.add},
  deleteState    : {...state.users.remove},
  editState      : {...state.users.edit},
  editActiveState: {...state.users.editActive},
  mailState      : {...state.users.mail},
  singleState    : {...state.users.single},
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
  cleanSingle       : USERS_SINGLE_RESET_ACTION,
  deleteUser        : USERS_DELETE_INIT_ACTION,
  getUsers          : USERS_LIST_INIT_ACTION,
  getUser           : USERS_SINGLE_INIT_ACTION,
  setActiveUser     : USERS_EDIT_ACTIVE_INIT_ACTION,
  sendActivationMail: USERS_MAIL_INIT_ACTION,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles, {name: 'UsersListPage'})(UsersListPage)
);
