import { Epic, ofType } from 'redux-observable';

import { ReplaySubject, forkJoin } from 'rxjs';
import { catchError, concatMap, switchMap, tap } from 'rxjs/operators';

import Api from '../../configureApi';

import {
  Action,
  UsersSingleInitActionType,
  USERS_SINGLE_SUCCESS_ACTION,
  USERS_SINGLE_FAILURE_ACTION,
  USERS_LIST_IAT_RESET_ACTION,
} from '../actions';

import { USERS_SINGLE_INIT } from '../constants';

export const usersSingleEpic: Epic<UsersSingleInitActionType, any> = action$ => action$
  .pipe(
    ofType(USERS_SINGLE_INIT),
    switchMap(({payload: userId}) => {
      const stack$ = new ReplaySubject<Action>();

      return forkJoin(
        Api.Services.getUser(userId),
        Api.Services.getUserRoles(userId),
      ).pipe(
        tap(([user, roles]) => {
          stack$.next(USERS_SINGLE_SUCCESS_ACTION({...user, roles}));
          stack$.complete();
        }, (response: Response) => {
          stack$.next(USERS_SINGLE_FAILURE_ACTION());

          if (response.status === 404) {
            stack$.next(USERS_LIST_IAT_RESET_ACTION());
          }

          stack$.complete();
        }),
        concatMap(() => stack$),
        catchError(() => stack$),
      );
    }),
  );

export default [
  usersSingleEpic,
];
