import { Epic, ofType } from 'redux-observable';

import { ReplaySubject } from 'rxjs';
import { catchError, concatMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import Api from '../../configureApi';

import {
  Action,
  UsersDeleteInitActionType,
  USERS_DELETE_SUCCESS_ACTION,
  USERS_DELETE_RESET_ACTION,
  USERS_DELETE_FAILURE_ACTION,
  USERS_LIST_SUCCESS_ACTION,
} from '../actions';

import { USERS_DELETE_INIT } from '../constants';
import { RootState } from '../reducers';

export const usersDeleteEpic: Epic<UsersDeleteInitActionType, any, RootState> = (action$, state$) => action$
  .pipe(
    ofType(USERS_DELETE_INIT),
    withLatestFrom(state$),
    switchMap(([{payload: userId}, state]) => {
      const stack$ = new ReplaySubject<Action>();

      return Api.Services.deleteUser(userId).pipe(
        tap(() => {
          stack$.next(USERS_DELETE_SUCCESS_ACTION());

          const usersWithoutDeletedUser = state.users.list.items
            .filter(({id}) => id !== userId);

          stack$.next(USERS_LIST_SUCCESS_ACTION(usersWithoutDeletedUser));
          stack$.next(USERS_DELETE_RESET_ACTION());
        }, () => {
          stack$.next(USERS_DELETE_FAILURE_ACTION());
          stack$.next(USERS_DELETE_RESET_ACTION());
          stack$.complete();
        }),
        concatMap(() => stack$),
        catchError(() => stack$),
      );
    }),
  );

export default [
  usersDeleteEpic,
];
