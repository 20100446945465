import React, { useState, useContext } from 'react';

import { Link } from 'react-router-dom';
import { LocationDescriptor } from 'history';
import { format, formatDistance, parseISO } from 'date-fns';

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Menu,
  MenuItem as MuiMenuItem,
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core';

import { TableProps } from '@material-ui/core/Table';
import { TableCellProps } from '@material-ui/core/TableCell';

import MoreVertIcon from '@material-ui/icons/MoreVert';

import {
  Avatar,
  TextLoader,
} from '..';

import { UsersListState } from '../../store/reducers';
import { WithPropsFromState } from '../../shared/types';
import { UserWithRoles } from '../../api/services';
import { AbilityContext } from '../../configureAbility';
import { Ability } from '@casl/ability';

const now = Date.now();

export const CanAfficher = (context: Ability) => (
  context.can('view', 'User')
  || context.can('add', 'User')
  || context.can('change', 'User')
  || context.can('view', 'Role')
  || context.can('add', 'Role')
  || context.can('change', 'Role')
  || context.can('delete', 'Role')
);

export const CanModifier = (context: Ability) => (
  context.can('change', 'User')
  || context.can('delete', 'User')
);

export const CanActiver = (context: Ability) => (
  context.can('change', 'User')
);

const StickyLeftTableCell = withStyles(createStyles({
  root: {
    position       : 'sticky',
    top            : 0,
    left           : 0,
    backgroundColor: '#fff',
  },
}), {name: 'StickyLeftTableCell'})(TableCell);

const StickyRightTableCell = withStyles(createStyles({
  root: {
    position       : 'sticky',
    top            : 0,
    right          : 0,
    backgroundColor: '#fff',
  },
}), {name: 'StickyRightTableCell'})(TableCell);

const MenuItem = withStyles(({typography}: Theme) => createStyles({
  root: {
    fontSize: typography.pxToRem(13),
  },
}), {name: 'MenuItem'})(MuiMenuItem);

export type TableCellActionsProps =
  & {
    onActiveClick: (user: UserWithRoles) => void;
    onEditClick  : (user: UserWithRoles) => void;
    onRemoveClick: (user: UserWithRoles) => void;
    user         : UserWithRoles;
    toUser       : (user: UserWithRoles) => LocationDescriptor;
  }
  & TableCellProps;

const TableCellActions = ({
  onActiveClick,
  onEditClick,
  onRemoveClick,
  toUser,
  user,
  ...props
}: TableCellActionsProps) => {
  const context = useContext(AbilityContext);

  const [menu, setMenu]  = useState<null | HTMLElement>(null);
  const activeActionText = user.is_active ? 'Désactiver' : 'Activer';

  const canAfficher = CanAfficher(context);
  const canModifier = CanModifier(context);
  const canActiver  = CanActiver(context);

  return (
    <>
      <StickyRightTableCell {...props}>
        <IconButton onClick={({currentTarget}) => setMenu(currentTarget)}>
          <MoreVertIcon fontSize="small" />
        </IconButton>
      </StickyRightTableCell>

      <Menu
        anchorEl={menu}
        disableAutoFocusItem
        onClose={() => setMenu(null)}
        open={Boolean(menu)}
      >
        {canAfficher && (
          <MenuItem
            button
            component={props => <Link to={toUser(user)} {...props} />}
          >Afficher</MenuItem>
        )}
        {canModifier && (
          <MenuItem
            button
            onClick={() => {
              setMenu(null);
              onEditClick(user);
            }}
          >Modifier les informations personnelles</MenuItem>
        )}
        {canActiver && (
          <MenuItem
            button
            onClick={() => {
              setMenu(null);
              onActiveClick(user);
            }}
          >{activeActionText}</MenuItem>
        )}
        {canModifier && (
          <MenuItem
            button
            onClick={() => {
              setMenu(null);
              onRemoveClick(user);
            }}
          >Supprimer</MenuItem>
        )}
      </Menu>
    </>
  );
};

export interface TableUsersListBaseProps {
  page       : number;
  rowsPerPage: number;
}

export type TableUsersListProps =
  & TableUsersListBaseProps
  & TableProps
  & Pick<TableCellActionsProps, 'toUser' | 'onActiveClick' | 'onEditClick' | 'onRemoveClick'>
  & WithPropsFromState<UsersListState>;

export function TableUsersList({
  classes,
  error,
  iat,
  items,
  loading,
  page,
  onActiveClick,
  onEditClick,
  onRemoveClick,
  rowsPerPage,
  toUser,
  ...other
}: TableUsersListProps) {
  const context     = useContext(AbilityContext);

  const canAfficher = CanAfficher(context);
  const canModifier = CanModifier(context);
  const canActiver  = CanActiver(context);

  const canAMA = canAfficher || canModifier || canActiver;

  if (loading) {
    return <TextLoader />
  }

  if (items.length === 0) {
    return <span>Aucun utilisateur.</span>
  }

  return (
    <>
      <Table {...other}>
        <TableHead>
          <TableRow>
            <StickyLeftTableCell>Nom</StickyLeftTableCell>
            <TableCell>Email</TableCell>
            <TableCell>Téléphone</TableCell>
            <TableCell>Début de validité</TableCell>
            <TableCell>Fin de validité</TableCell>
            <TableCell>Dernière connexion</TableCell>
            <TableCell align="center">Rôles</TableCell>
            <TableCell align="center">Statut</TableCell>
            {canAMA && <StickyRightTableCell />}
          </TableRow>
        </TableHead>
        <TableBody>
          {items
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(user => {
              const displayName = `${user.first_name} ${user.last_name}`;

              const UserAvatar = () => (
                <div className="d-flex align-items-center">
                  <Avatar
                    alt={displayName}
                    src={user.avatar || undefined}
                  >{displayName}</Avatar>
                  <strong className="pl-2">{displayName}</strong>
                </div>
              );

              return (
                <TableRow key={user.id}>
                  <StickyLeftTableCell className="py-2">
                    {canAfficher ? (
                      <Link to={toUser(user)}>
                        <UserAvatar />
                      </Link>
                    ) : (
                      <UserAvatar />
                    )}
                  </StickyLeftTableCell>
                  <TableCell className="py-2">{user.email}</TableCell>
                  <TableCell className="py-2">{user.phone ? (
                    <>{user.phone}</>
                  ): (
                    <>-</>
                  )}</TableCell>
                  <TableCell className="py-2">{user.validation.lower ? (
                    <>{format(parseISO(user.validation.lower), 'dd/MM/yyyy \à HH:mm')}</>
                  ) : (
                    <>-</>
                  )}</TableCell>
                  <TableCell className="py-2">{user.validation.upper ? (
                    <>{format(parseISO(user.validation.upper), 'dd/MM/yyyy \à HH:mm')}</>
                  ) : (
                    <>Illimité</>
                  )}</TableCell>
                  <TableCell className="py-2">{user.last_login ? (
                    <>{formatDistance(parseISO(user.last_login), now, {includeSeconds: true})}</>
                  ) : (
                    <>Aucune connexion</>
                  )}</TableCell>
                  <TableCell className="py-2" align="center">{user.roles}</TableCell>
                  <TableCell className="py-2" align="center">{user.is_active ? 'Actif' : 'Inactif'}</TableCell>
                  {canAMA && (
                    <TableCellActions
                      align="right"
                      padding="none"
                      {...{onActiveClick, onEditClick, onRemoveClick, toUser, user}}
                    />
                  )}
                </TableRow>
              );
            })
          }
        </TableBody>
      </Table>
    </>
  );
}

export default TableUsersList;
