import { UniteOpDetails } from '../../api/services';
import { UnitesOpAddAction } from '../actions';

import {
  UNITES_OP_ADD_INIT,
  UNITES_OP_ADD_SUCCESS,
  UNITES_OP_ADD_FAILURE,
  UNITES_OP_ADD_RESET,
} from '../constants';

export const getUnitesOpAddStateToProps = <T extends UnitesOpAddState>({
  error,
  item,
  loading,
}: Pick<T, keyof UnitesOpAddState>) => ({error, item, loading});

export interface UnitesOpAddState {
  error  : boolean;
  item   : null | UniteOpDetails;
  loading: boolean;
}

export const initialUnitesOpAddState: UnitesOpAddState = {
  error  : false,
  item   : null,
  loading: false,
};

export default function (state: UnitesOpAddState = initialUnitesOpAddState, action: UnitesOpAddAction): UnitesOpAddState {
  switch (action.type) {
    case UNITES_OP_ADD_INIT:
      return {
        ...initialUnitesOpAddState,
        loading: true,
      };
    case UNITES_OP_ADD_SUCCESS:
      return {
        error  : false,
        item   : {...action.payload},
        loading: false,
      };
    case UNITES_OP_ADD_FAILURE:
      return {
        ...initialUnitesOpAddState,
        error: true,
      };
    case UNITES_OP_ADD_RESET:
      return {...initialUnitesOpAddState};
    default:
      return state;
  }
}
