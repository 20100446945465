import {
  ActionType,
  createStandardAction,
} from 'typesafe-actions';

import { FormikActions } from 'formik';

import {
  USERS_ADD_GROUPS_INIT,
  USERS_ADD_GROUPS_SUCCESS,
  USERS_ADD_GROUPS_FAILURE,
  USERS_ADD_GROUPS_RESET,
} from '../constants';

import { UsersUpdateResponse } from '../../api';
import { ApplicationsFormValues } from '../../components/Form/ApplicationsForm';
import { UserSingle } from '../reducers';

export const USERS_ADD_GROUPS_INIT_ACTION = createStandardAction(USERS_ADD_GROUPS_INIT)<number[], {
  actions: FormikActions<ApplicationsFormValues>,
  values : ApplicationsFormValues,
  user   : UserSingle,
}>();
export type UsersAddGroupsInitActionType = ActionType<typeof USERS_ADD_GROUPS_INIT_ACTION>;

export const USERS_ADD_GROUPS_SUCCESS_ACTION = createStandardAction(USERS_ADD_GROUPS_SUCCESS)<UsersUpdateResponse>();
export type UsersAddGroupsSuccessActionType  = ActionType<typeof USERS_ADD_GROUPS_SUCCESS_ACTION>;

export const USERS_ADD_GROUPS_FAILURE_ACTION = createStandardAction(USERS_ADD_GROUPS_FAILURE)();
export type UsersAddGroupsFailureActionType  = ActionType<typeof USERS_ADD_GROUPS_FAILURE_ACTION>;

export const USERS_ADD_GROUPS_RESET_ACTION = createStandardAction(USERS_ADD_GROUPS_RESET)();
export type UsersAddGroupsResetActionType  = ActionType<typeof USERS_ADD_GROUPS_RESET_ACTION>;

export type UsersAddGroupsAction =
  | UsersAddGroupsInitActionType
  | UsersAddGroupsSuccessActionType
  | UsersAddGroupsFailureActionType
  | UsersAddGroupsResetActionType;

export default {
  USERS_ADD_GROUPS_INIT_ACTION,
  USERS_ADD_GROUPS_SUCCESS_ACTION,
  USERS_ADD_GROUPS_FAILURE_ACTION,
  USERS_ADD_GROUPS_RESET_ACTION,
};
