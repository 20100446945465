import { UserWithGroups } from '../../api/services';
import { UsersEditAction } from '../actions';

import {
  USERS_EDIT_INIT,
  USERS_EDIT_SUCCESS,
  USERS_EDIT_FAILURE,
  USERS_EDIT_RESET,
} from '../constants';

export const getUsersEditStateToProps = <T extends UsersEditState>({
  diff,
  error,
  item,
  loading,
}: Pick<T, keyof UsersEditState>) => ({diff, error, item, loading});

export interface UsersEditState {
  diff   : boolean;
  error  : boolean;
  item   : null | UserWithGroups;
  loading: boolean;
}

export const initialUsersEditState: UsersEditState = {
  diff   : false,
  error  : false,
  item   : null,
  loading: false,
};

export default function (state: UsersEditState = initialUsersEditState, action: UsersEditAction): UsersEditState {
  switch (action.type) {
    case USERS_EDIT_INIT:
      return {
        ...initialUsersEditState,
        loading: true,
      };
    case USERS_EDIT_SUCCESS:
      return {
        ...initialUsersEditState,
        diff   : action.meta.diff,
        error  : false,
        item   : {...action.payload},
        loading: false,
      };
    case USERS_EDIT_FAILURE:
      return {
        ...initialUsersEditState,
        error: true,
      };
    case USERS_EDIT_RESET:
      return {...initialUsersEditState};
    default:
      return state;
  }
}
