import { SelfDetailsListResponse } from '../../api/iam';

import {
  Applications,
} from '../../api/iam/models';

import {
  Permissions,
} from '../../api/services/models';

export const initialUserState: UserState = null;

export type UserState =
  | null
  | (SelfDetailsListResponse & {
    applications: Applications;
    permissions : Permissions;
  });

export default function (state: UserState = initialUserState) {
  return state;
}
