import { Epic, ofType } from 'redux-observable';

import { ReplaySubject } from 'rxjs';
import { catchError, concatMap, switchMap, tap } from 'rxjs/operators';

import Api from '../../configureApi';

import {
  Action,
  UnitesOpSingleInitActionType,
  UNITES_OP_SINGLE_SUCCESS_ACTION,
  UNITES_OP_SINGLE_FAILURE_ACTION,
} from '../actions';

import { UNITES_OP_SINGLE_INIT } from '../constants';

export const unitesOpSingleEpic: Epic<UnitesOpSingleInitActionType, any> = action$ => action$
  .pipe(
    ofType(UNITES_OP_SINGLE_INIT),
    switchMap(({payload: uniteOpId}) => {
      const stack$ = new ReplaySubject<Action>();

      return Api.Services.getUniteOp(uniteOpId).pipe(
        tap(item => {
          stack$.next(UNITES_OP_SINGLE_SUCCESS_ACTION(item));
          stack$.complete();
          // stack$.next(UNITES_OP_SINGLE_RESET_ACTION());
        }, () => {
          stack$.next(UNITES_OP_SINGLE_FAILURE_ACTION());
          stack$.complete();
          // stack$.next(UNITES_OP_SINGLE_RESET_ACTION());
        }),
        concatMap(() => stack$),
        catchError(() => stack$),
      );
    }),
  );

export default [
  unitesOpSingleEpic,
];
